import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { PaymentsService } from 'src/app/modules/payments/services/payments.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { FormFieldErrorComponent } from '../../../../../shared/components/form-field-error/form-field-error.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../../shared/directives/form-validator.directive';
@UntilDestroy()
@Component({
    selector: 'app-payment-confirmation',
    templateUrl: './payment-confirmation.component.html',
    styleUrls: ['./payment-confirmation.component.css'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatSelectModule, MatOptionModule, FormFieldErrorComponent, AsyncPipe, LowerCasePipe, TranslateModule]
})
export class PaymentConfirmationComponent extends BaseModalComponent {
    public form: FormGroup;
    public totalQuantity = 0;

    constructor(public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _paymentService: PaymentsService,
        private _formBuilder: FormBuilder,
        public settingsService: SettingsService,
    ) {
        super(dialogRef, _paymentService);
        this.totalQuantity = this.data.productsToPay.map((product) => product.quantity).reduce((acc, current) => acc + current, 0);
        this.form = this._formBuilder.group({ type: null });

        this._paymentService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close())
    }

    confirmProducts() {
        this._saveAndClose = this.form.valid ? true : false;
        this._paymentService.addPayment(this.data.productsToPayIds, this.form.controls.type.value);
    }
}
