
<app-user-account-fields (updateUserForm)="updateUserForm($event)" [data]="data" ></app-user-account-fields>

<div mat-dialog-actions class="mb-0">
    <div class="w-100 modal-footer border-0 p-0 pt-4">
        <button type="button" class="btn btn-secondary pull-right" (click)="dialogRef.close()">{{ 'Close' | translate
            }}</button>
        <button type="button" class="btn btn-primary pull-right mx-3" (click)="saveAndExit()">{{ 'SaveAndClose' |
            translate }}</button>
        <button class="btn btn-primary" (click)="save()">{{ 'Save' | translate }}</button>
    </div>
</div>

