import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';


@Injectable({
    providedIn: 'root'
})

export class EnumValuesService extends BaseService {

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    getEnumValues(enumName: string): Observable<any> {
        return this.apiRequestService.submitGetRequest(apiEndpoints.enumValues + `/${enumName}`);
    }
}
