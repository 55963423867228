import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { validateAllFormFields } from 'src/app/shared/utils/form.utils';
import { filter } from 'rxjs';
import { AssetModel } from '../../models/assets.model';
import { AssetsService } from '../../services/assets.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { DropdownWithSearchComponent } from '../../../../shared/components/dropdown-with-search/dropdown-with-search.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { ContentLanguageDropdownComponent } from '../../../../shared/components/content-language-dropdown/content-language-dropdown.component';
import { NgIf } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-assets-modal',
    templateUrl: './assets-modal.component.html',
    styleUrls: ['./assets-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, ContentLanguageDropdownComponent, PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DropdownWithSearchComponent, DialogFooterButtonsComponent, TranslateModule]
})
export class AssetsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public selectedAsset: AssetModel;
    public form: FormGroup;
    public assetTypes;

    constructor(
        public dialogRef: MatDialogRef<AssetsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AssetModel,
        private _formBuilder: FormBuilder,
        private _assetsService: AssetsService) {
        super(dialogRef, _assetsService);
        this.data?.id && this._assetsService.getAsset(this.data.id);
        this._assetsService.selectedItem$.pipe(filter(data => !!data)).subscribe(data => {
            this.selectedAsset = data;
            this.form?.patchValue(data)
        })
        this._assetsService.getAssetTypes();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.form = this._formBuilder.group({
            id: this.selectedAsset?.id || '',
            name: this.selectedAsset?.name || '',
            asset_type_id: this.selectedAsset?.asset_type_id || 0
        });
        this._assetsService.assetTypesList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.assetTypes = data);
        this._assetsService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
    }

    changeLang() {
        this.data.id && this._assetsService.getAsset(this.data.id);
    }

    ngOnDestroy(): void { }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        if (!this.selectedAsset) {
            this._assetsService.createAsset(this.form.value);
            return;
        }
        this._assetsService.updateAsset(this.form.value);
    }

}
