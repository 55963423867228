import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { SettingModel } from '../../models/settings.model';
import { SettingsService } from '../../services/settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';

@Component({
    selector: 'app-settings-modal',
    templateUrl: './settings-modal.component.html',
    styleUrls: ['./settings-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, TranslateModule]
})
export class SettingsModalComponent extends BaseModalComponent implements OnInit {

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SettingModel,
    private _settingsService: SettingsService,
    private _fb: FormBuilder) {
    super(dialogRef, _settingsService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.form = this._fb.group({
      value: this.data?.value || '',
    });
  }

  saveAndExit() {
    if (this.form.valid) {
      this.onSubmit();
      this.onNoClick();
    } else {
      this.form.markAllAsTouched();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this._settingsService.updateSetting({
      id: this.data.id,
      ...this.form.value
    });
  }

}
