<div class="user-mobile-wrapper" *ngIf="!this.qrScanInterface && isMobile()">

    <span class="material-icons mr-2">person</span>
    <span>{{ currentUser?.first_name }} {{ currentUser?.last_name }}</span>
    <span class="badge badge-primary px-2 ml-2">{{ currentUser?.roles[0] }}</span>


    <ul class="navbar-nav ml-2">
        <app-nav-notifications>
        </app-nav-notifications>
    </ul>
</div>

<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button mat-raised-button (click)="minimizeSidebar()"
                    class="btn btn-just-icon btn-white btn-fab btn-round">
                    <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </button>
            </div>
        </div>
        <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation" *ngIf="!this.qrScanInterface">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <span class="small">{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
                    <span class="badge badge-primary px-2 mx-2">{{ currentUser.roles[0] }}</span>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" href="javascript:void(0)" id="language-dropdown" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">language</i>
                        <p>
                            <span class="d-lg-none d-md-block">Limba</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="language-dropdown">
                        <a class="dropdown-item" href="javascript:void(0)"
                            *ngFor="let language of translateService.getLangs()" (click)="switchLang(language)">{{
                            language | uppercase }}</a>
                    </div>
                </li>
                <app-nav-notifications [notifications]="notifications"
                    (navigateTo)="navigateTo($event)">
                </app-nav-notifications>
                <li class="nav-item dropdown" [appRestrictPermissions]="PermissionsEnum.OWNERS_READ">
                    <a class="nav-link" href="javascript:void(0)" id="charts-dropdown" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">trending_up</i>
                        <p>
                            <span class="d-lg-none d-md-block">{{ "Reports" | translate }}</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="charts-dropdown">
                        <div>
                            <a class="dropdown-item" href="/#/admin/sessions-report"
                                [appRestrictPermissions]="PermissionsEnum.VIEW_SESSIONS_LIST">
                                <i class="material-icons mr-3">show_chart</i>{{ "SessionsReport" | translate}}
                            </a>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" href="#p" id="right-dropdown" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="material-icons">more_vert</i>
                        <p>
                            <span class="d-md-block d-lg-none">{{ "NavbarMenu" | translate }}</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="right-dropdown">
                        <div class="dropdown-menu-section">
                            <a class="dropdown-item" href="/#/admin/brand-profile"
                                [appRestrictPermissions]="PermissionsEnum.BRAND_PROFILE_READ"><i
                                    class="material-icons mr-3">business</i> {{ 'BrandData' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/brand-invoice-profile"
                                [appRestrictPermissions]="PermissionsEnum.BRAND_INVOICE_PROFILE_READ"><i
                                    class="material-icons mr-3">note</i> {{ 'BrandInvoiceProfile' | translate }}</a>
                        </div>
                        <div class="dropdown-menu-section custom-border">
                            <a class="dropdown-item" href="/#/admin/products"
                            [appRestrictPermissions]="PermissionsEnum.VIEW_PRODUCTS_LIST"><i
                            class="material-icons mr-3">menu_book</i> {{ 'Products' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/assets"
                            [appRestrictPermissions]="PermissionsEnum.VIEW_ASSETS_LIST"><i
                            class="material-icons mr-3">qr_code</i> {{ 'Assets' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/allergens"
                                [appRestrictPermissions]="PermissionsEnum.VIEW_ALLERGENS_LIST"><i
                                class="material-icons mr-3">disabled_visible</i> {{ 'Allergens' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/categories"
                                [appRestrictPermissions]="PermissionsEnum.VIEW_CATEGORIES_LIST"><i
                                class="material-icons mr-3">category</i> {{ 'Categories' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/types"
                                [appRestrictPermissions]="PermissionsEnum.VIEW_TYPES_LIST"><i
                                class="material-icons mr-3">view_compact_alt</i> {{ 'Types' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/measurement-units"
                                [appRestrictPermissions]="PermissionsEnum.VIEW_MEASUREMENT_UNITS_LIST"><i
                                class="material-icons mr-3">scale</i> {{ 'MeasurementUnits' | translate }}</a>
                        </div>
                        <div class="dropdown-menu-section">
                            <a class="dropdown-item" href="/#/admin/services"
                                [appRestrictPermissions]="PermissionsEnum.SERVICES_READ"><i
                                    class="material-icons mr-3">cleaning_services</i> {{ 'Services' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/settings"
                                [appRestrictPermissions]="PermissionsEnum.SETTINGS_READ"><i
                                    class="material-icons mr-3">settings</i> {{ 'Settings' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/accounts"
                                [appRestrictPermissions]="PermissionsEnum.USERS_READ"><i
                                    class="material-icons mr-3">group</i> {{ 'Accounts' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/roles"
                                [appRestrictPermissions]="PermissionsEnum.VIEW_ROLES_LIST"><i
                                    class="material-icons mr-3">task</i> {{ 'Roles' | translate }}</a>
                            <a class="dropdown-item" href="/#/admin/expense-categories"
                                [appRestrictPermissions]="PermissionsEnum.VIEW_EXPENSE_CATEGORIES_LIST"><i
                                    class="material-icons mr-3">list</i> {{ 'ExpenseCategories' | translate }}</a>
                        </div>
                        <a class="dropdown-item" (click)="logout()"><i class="material-icons mr-3">logout</i> {{
                            'Logout' | translate}}</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>