import { AfterContentInit, ContentChild, Directive, OnDestroy } from "@angular/core";
import { FormControlName } from "@angular/forms";
import { MatEndDate, MatStartDate } from "@angular/material/datepicker";
import { Subject, distinctUntilChanged, takeUntil } from "rxjs";

@Directive({
    selector: '[updateDateRangeValueDirective]',
    standalone: true
})
export class UpdateDateRangeValueDirective
    implements AfterContentInit, OnDestroy {

    @ContentChild(MatStartDate, { read: FormControlName }) startDateControlName: FormControlName;
    @ContentChild(MatEndDate, { read: FormControlName }) endDateControlName: FormControlName;
    private readonly destroyed$ = new Subject<void>();
    ngAfterContentInit(): void {
        this.startDateControlName.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe(() => setTimeout(() => this.endDateControlName.control.updateValueAndValidity()));

        this.endDateControlName.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe(() => setTimeout(() => this.startDateControlName.control.updateValueAndValidity()));
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}