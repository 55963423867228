import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { HttpParams } from '@angular/common/http';
import { getLang } from 'src/app/shared/utils/utils';
import { OrderModel } from '../models/order.model';

@Injectable({
    providedIn: 'root'
})

export class OrdersService extends BaseService {
    itemsList$: BehaviorSubject<OrderModel[]> = new BehaviorSubject(undefined);
    orderTypesList$: BehaviorSubject<OrderModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<OrderModel> = new Subject();
    orderProductsChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    orderClosed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    protected getRequestOptions() {
        return { params: new HttpParams().set("locale", getLang()) };
    }

    confirmOrderProducts(order: any) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(`${apiEndpoints.orders}/${order.id}/confirm`, { order_products_ids: order.order_products_ids }).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.orderProductsChanged$.next(true)
            },
            error: error => this.reportError('Error on create order', error)
        });
    }

    getOrders(filter?: any, paginationSettings?: any) {
        this.appSettingsService.isLoading.next(true);

        this.getResorce(apiEndpoints.orders + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get orders list', error)
        });
    }

    getOrder(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.orders}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get order', error)
        });
    };

    updateOrder(order: any, modalRef?: any) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.orders + '/' + order.id, { is_open: order.is_open }).subscribe({
            next: () => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(undefined);
                this.orderProductsChanged$.next(true);
                if (!order.is_open) this.orderClosed$.next(true);
                modalRef && modalRef.close()
            },
            error: error => this.reportError('Error on update order', error)
        });
    }

    deleteOrder(order) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitDeleteRequest(apiEndpoints.orders + '/' + order.id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this.getOrders();
                this.orderClosed$.next(true)
            },
            error: error => this.reportError('Error on delete order', error)
        });
    }
}
