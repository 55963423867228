import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { SettingsModalComponent } from 'src/app/modules/settings/components/settings-modal/settings-modal.component';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { SettingModel } from './models/settings.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SettingsService } from './services/settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { UpperCasePipe } from '@angular/common';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';

@UntilDestroy()
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css'],
    standalone: true,
    imports: [RegularTableComponent, UpperCasePipe, TranslateModule]
})
export class SettingsComponent implements OnInit {

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true
        },
        {
            name: 'Value',
            fieldNames: ['value'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        }
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.SETTINGS_ADD_AND_EDIT
        }
    ];

    public settings: SettingModel[];
    public PermissionsEnum = PermissionsEnum;

    constructor(
        public settingsService: SettingsService,
        private _dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        this.settingsService.getSettings();
        this.settingsService.itemsList$.pipe(untilDestroyed(this)).subscribe(settings => this.settings = settings);
    }

    tableActionClicked(e: any) {
        this.openEditModal(e.entry);
    }

    addEntry() {
        this.openEditModal(null);
    }

    private openEditModal(entry: SettingModel | null) {
        this._dialogService.openModal(entry, SettingsModalComponent, DimensionsEnum.AUTO);
    }
}
