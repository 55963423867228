<app-preloader></app-preloader>
<div *ngIf="selectedAsset?.customer_request?.id || customerReq; else noOrder">
    <button mat-mini-fab class class="btn-call bg-grey text-white pt-2" (click)="callWaiter.emit()">
        <div class="cart-icon"><i class="material-icons">notifications_active</i></div>
    </button>
</div>

<ng-template #noOrder>
    <button mat-mini-fab class="btn-call pt-2" (click)="callWaiter.emit()">
        <div class="cart-icon"><i class="material-icons">notifications_none</i></div>
    </button>
</ng-template>