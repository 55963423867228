import { CategoryModel } from "../../categories/models/category.model";
import { MeasurementUnitModel } from "../../measurement-unit/models/measurement-unit.model";
import { TypeModel } from "../../types/models/type.model";

export class ProductModel {
    id: number;
    name: string;
    type: TypeModel;
    ingredients: string;
    is_available: boolean;
    is_active: boolean;
    category: CategoryModel;
    amount: string;
    measurement_unit: MeasurementUnitModel;
    price: string;
    servings: number;
    calories: string;
    proteins: string;
    carbohydrates: string;
    fats: string;
    product_allergens?: any[];
    image: any;
    type_id: number;
    category_id: number;
}
