import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, TranslateModule]
})
export class DateRangePickerComponent implements OnInit {
    public startCtrlName: string;
    public endCtrlName: string;

    @Input() form: FormGroup;

    constructor() { }

    ngOnInit(): void {
        this.startCtrlName = Object.keys(this.form.controls).find(key => key.includes('start'));
        this.endCtrlName = Object.keys(this.form.controls).find(key => key.includes('end'));

        this.form.valueChanges.subscribe(() => {
            this.form.controls[this.startCtrlName].patchValue(moment(this.form.controls[this.startCtrlName].value).format('YYYY-MM-DD'), { emitEvent: false }),
                this.form.controls[this.endCtrlName].patchValue(moment(this.form.controls[this.endCtrlName].value).format('YYYY-MM-DD'), { emitEvent: false })
        });
    }
}
