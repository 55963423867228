import { PermissionCategoryModel, PermissionsModel } from './../models/permissions.model';
import { apiEndpoints, ApiRequestService } from './api-request.service';
import { Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    permission = signal<PermissionsModel>(undefined);
    permissionsList = signal<string[]>(undefined);
    permissionsDetailedList = signal<PermissionCategoryModel[]>(undefined);

    constructor(private _apiRequestService: ApiRequestService) { }

    getPermission(name) {
        this._apiRequestService.submitGetRequest(apiEndpoints.permission + '/' + name).subscribe({
            next: apiResponse => this.permission.set(apiResponse.data),
            error: error => this.reportError('Error on get permission', error)
        });
    }

    getPermissions() {
        this._apiRequestService.submitGetRequest(apiEndpoints.permissions).subscribe({
            next: apiResponse => this.permissionsList.set(apiResponse.data),
            error: error => this.reportError('Error on get permissions', error)
        });
    }

    getPermissionsDetailed() {
        this._apiRequestService.submitGetRequest(apiEndpoints.permissionsDetailed).subscribe({
            next: apiResponse => this.permissionsDetailedList.set(apiResponse.data),
            error: error => this.reportError('Error on get permissions detailed', error)
        });
    }

    reportError(message: string, response: object) {
        console.error(message);
        console.log(response);
    }
}
