import { Injectable } from '@angular/core';
import { apiUrl } from './api-request.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() { }

    setValue(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    getValue(key: string): string {
        return localStorage.getItem(key);
    }

    deleteValue(key: string) {
        localStorage.removeItem(key);
    }
}

export const LS_KEY_LANG = 'lang';
export const AUTH_TOKEN = 'token';
export const CURRENT_USER = 'current_user' + '_' + new URL(apiUrl).host;
export const PREV_DENTITION_SELECTION = 'prev-dentition-selection';
