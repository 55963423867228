import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';


import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { AssetsComponent } from './assets.component';
import { AssetsModalComponent } from './components/assets-modal/assets-modal.component';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';



@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    AssetsComponent,
    AssetsModalComponent
],
})
export class AssetsModule { }
