import { Component, Input } from '@angular/core';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe, LowerCasePipe } from '@angular/common';

@Component({
    selector: 'app-order-statuses',
    templateUrl: './order-statuses.component.html',
    styleUrls: ['./order-statuses.component.css'],
    standalone: true,
    imports: [NgIf, AsyncPipe, LowerCasePipe, TranslateModule]
})
export class OrderStatusesComponent {

    @Input() products;
    @Input() confirmedProductIds;
    @Input() unconfirmedProducts;
    @Input() toConfirmQuantity;
    @Input() unpaidProducts;
    @Input() productsToPayIds;
    @Input() toPayQuantity;
    constructor(public settingsService: SettingsService) { }
}
