import { Injectable } from '@angular/core';
import { UserProfileService } from './user-profile.service';

@Injectable({
    providedIn: 'root'
})
export class CleanupService {

    constructor(
        private _userProfileService: UserProfileService,
    ) { }

    resetAllSubjects() {
        this._userProfileService.currentUser$.next(undefined);
    }
}
