import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { BrandInvoiceProfileModel } from '../../models/brand-invoice-profile.model';
import { BrandInvoiceProfileService } from '../../services/brand-invoice-profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceSeriesTabComponent } from './invoice-series-tab/invoice-series-tab.component';
import { InvoiceProfileInfoTabComponent } from './invoice-profile-info-tab/invoice-profile-info-tab.component';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-brand-invoice-profile-modal',
    templateUrl: './brand-invoice-profile-modal.component.html',
    styleUrls: ['./brand-invoice-profile-modal.component.css'],
    standalone: true,
    imports: [NgIf, MatDialogModule, PreloaderComponent, InvoiceProfileInfoTabComponent, InvoiceSeriesTabComponent, TranslateModule]
})

export class BrandInvoiceProfileModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

    public isLoading: boolean = false;
    public form: FormGroup;
    public currentInvoiceProfile: BrandInvoiceProfileModel;

    constructor(
        public dialogRef: MatDialogRef<BrandInvoiceProfileModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BrandInvoiceProfileModel,
        private _brandInvoiceProfileService: BrandInvoiceProfileService) {
        super(dialogRef, _brandInvoiceProfileService);
        this.currentInvoiceProfile = data;
    }


    ngOnInit(): void {
        super.ngOnInit();
        this._brandInvoiceProfileService.isLoading$.pipe(untilDestroyed(this)).subscribe(isLoading => this.isLoading = isLoading);
        this._brandInvoiceProfileService.selectedItem$.pipe(filter(profile => !!profile), untilDestroyed(this)).subscribe(profile => this.currentInvoiceProfile = profile)
    }

    ngOnDestroy() {
        this._brandInvoiceProfileService.selectedItem$.next(undefined);
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this.closeModal();
        } else {
            this.form.markAllAsTouched();
        }
    }

    onSubmit() {
        if (!this.data) {
            this._brandInvoiceProfileService.createBrandInvoiceProfile(this.form.value);
        } else {
            this._brandInvoiceProfileService.updateBrandInvoiceProfile({
                id: this.data.id,
                ...this.form.value
            });
        }
    }

    saveAndClose(event: boolean) {
        this._saveAndClose = event;
    }
    closeModal() {
        this.dialogRef.close();
    }
}
