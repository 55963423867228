import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { AssetModel } from '../models/assets.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { getLang } from 'src/app/shared/utils/utils';

@Injectable({
    providedIn: 'root'
})

export class AssetsService extends BaseService {
    itemsList$: BehaviorSubject<AssetModel[]> = new BehaviorSubject(undefined);
    itemsListNotPaginated$: BehaviorSubject<AssetModel[]> = new BehaviorSubject(undefined);
    assetTypesList$: BehaviorSubject<AssetModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<AssetModel> = new Subject();

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    protected getRequestOptions(paginate_response?: any) {
        let params = paginate_response ? { params: new HttpParams().set("locale", getLang()).append('paginate_response', paginate_response['paginate_response']) } : { params: new HttpParams().set("locale", getLang()) }
        return params;
    }

    createAsset(asset: AssetModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.createResorce(apiEndpoints.assets, asset).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create asset', error)
        });
    }

    getChainedResourceList(payload: any) {
        this.getChainedList(payload)
    }

    getAsset(id: string) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.assets}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get asset', error)
        });
    };
    getAsset$(id: string) {
        return this.getResorce(`${apiEndpoints.assets}/${id}`);
    };

    getAssets(filter?: string, paginationSettings?: string) {
        this.appSettingsService.isLoading.next(true);
        this.getResorce(apiEndpoints.assets + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get assets list', error)
        });
    }
    getAssetsNotPaginated(filter?: string) {
        this.appSettingsService.isLoading.next(true);
        this.getResorce(apiEndpoints.assets + (filter ? `${filter}` : ''), { paginate_response: false }).subscribe({
            next: apiResponse => {
                this.itemsListNotPaginated$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get assets list', error)
        });
    }
    getAssetsNotPaginated$(filter?: string) {
        return this.getResorce(apiEndpoints.assets + (filter ? `${filter}` : ''), { paginate_response: false })
    }

    getAssets$(): Observable<any> {
        return this.getResorce(apiEndpoints.assets + '/dropdown-values');
    }

    getAssetTypes() {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitGetRequest(apiEndpoints.assetTypes).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.assetTypesList$.next(apiResponse.data)
            },
            error: error => this.reportError('Error on get asset types', error)
        });
    }
    getAssetTypes$() {
        return this.getResorce(apiEndpoints.assetTypes);
    }

    updateAsset(asset: AssetModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.assets + '/' + asset.id, asset).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data)
            },
            error: error => this.reportError('Error on update asset', error)
        });
    }

    deleteAsset(asset) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitDeleteRequest(apiEndpoints.assets + '/' + asset.id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this.getAssets();
            },
            error: error => this.reportError('Error on delete asset', error)
        });
    }

    getAllQRCodes(): void {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitGetRequest(apiEndpoints.assets + '-qr-pdf', { observe: 'response', responseType: 'blob' }).subscribe({
            next: (apiResponse) => {
                this.downloadFile(apiResponse.body, this.getFileNameFromHeaders(apiResponse.headers))
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get all assets QR', error)
        });
    }
    getQRCode(assetId): void {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitGetRequest(apiEndpoints.assets + '/' + assetId + '/qr-pdf', { observe: 'response', responseType: 'blob' }).subscribe({
            next: (apiResponse) => {
                this.downloadFile(apiResponse.body, this.getFileNameFromHeaders(apiResponse.headers))
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get asset`s QR', error)
        });
    }

    getFileNameFromHeaders(headers: HttpHeaders): string {
        let name = headers.get('Content-Disposition')?.split(';')[1].split('=')[1].replace(/\"/g, '')
        return name ? name : 'Import Format';
    }

    downloadFile(file: any, fileName: string) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }
}
