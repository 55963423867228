import { Injectable } from '@angular/core';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CURRENT_USER } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class SetupService {

    constructor(private _settingsService: SettingsService) { }

    public initliaze(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._settingsService.verifyTokenStatus().subscribe({
                next: (response) => {
                    if (response.status !== 'valid') {
                        localStorage.removeItem('token')
                        localStorage.removeItem(CURRENT_USER)
                    }
                    resolve(true);
                },
                error: () => { reject() }
            });
        })
    }
}
