
import { RolesModel } from './../models/roles.model';
import { Observable } from 'rxjs';
import { Injectable, signal } from '@angular/core';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';

@Injectable({
    providedIn: 'root'
})

export class RolesService extends BaseService {

    rolesList = signal<RolesModel[]>(undefined);
    selectedRole = signal<RolesModel>(undefined);

    constructor(
        protected _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    createRole(name) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPostRequest(apiEndpoints.roles, name).subscribe({
            next: apiResponse => {
                this.onSaveSuccess$.next(true);
                this.selectedRole.set(apiResponse.data);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create role', error)
        });
    }

    getRole(role: RolesModel) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.roles + '/' + role.id).subscribe({
            next: apiResponse => {
                this.selectedRole.set(apiResponse.data);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get roles', error)
        });
    }

    getRolesOptions$(): Observable<any> {
        return this._apiRequestService.submitGetRequest(apiEndpoints.roles + '/dropdown-values');
    }

    getRoles() {
        this.appSettingsService.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.roles).subscribe({
            next: apiResponse => {
                this.rolesList.set(apiResponse.data);
                this.appSettingsService.isLoading.next(false);
            },
            error: error => this.reportError('Error on get roles', error)
        });
    }

    getRoles$(): Observable<any> {
        return this._apiRequestService.submitGetRequest(apiEndpoints.roles);
    }

    updateRole(role: RolesModel) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPutRequest(apiEndpoints.roles + '/' + role.id, role).subscribe({
            next: () => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on update role', error)
        });
    }

    deleteRole(role: RolesModel) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitDeleteRequest(apiEndpoints.roles + '/' + role.id).subscribe({
            next: () => this._appSettingsSerice.isLoading.next(false),
            error: error => this.reportError('Error on delete role', error)
        });
    }
}
