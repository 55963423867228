import { CustomerRequestModel } from "../../customer-requests/models/customer-request.model";
import { OrderModel } from "../../orders/models/order.model";

export class AssetModel {
    id: string;
    name: string;
    asset_type_id: string | number;
    order: OrderModel;
    customer_request: CustomerRequestModel;
}
