import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import * as moment from "moment";

@Directive({
    selector: '[appCreatedAt]',
    standalone: true
})

export class FormatCreatedAtDirective implements AfterViewInit {

    @Input() appCreatedAt: any;
    @Input() fieldValue: string;

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        if (this.appCreatedAt.fieldNames[0] === 'created_at' && this.appCreatedAt.type === 'created-at') {
            this.elementRef.nativeElement.classList.add('d-block', 'text-center')
            this.elementRef.nativeElement.innerHTML = `<div class="">${moment(this.fieldValue).format('HH:mm')}</div>
            <div class="small">${moment(this.fieldValue).format('YYYY-MM-DD')}</div>`;
        }
    }
}