import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { BuildDetailsService } from './shared/services/build-details.service';
import { VerifyCCustomerIdService } from './shared/services/customer-id-check.service';
import { SetupService } from './shared/services/setup.service';

registerLocaleData(localeRo)

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: { direction: Hammer.DIRECTION_ALL },
    };
}

export function SetupApp(setup: SetupService) {
    return () => setup.initliaze();
}
export function VerifyCustomerId(setup: VerifyCCustomerIdService) {
    return () => setup.initliaze();
}



export function fetchBuildDetails(buildDetailsService: BuildDetailsService) {
    return () => buildDetailsService.getBuildDetails();
}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

