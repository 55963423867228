<app-preloader></app-preloader>

<app-mobile-table *ngIf="isMobile; else bigScreen"
                  [withIndex]="pagination?.from"
                  [headerRow]="headerRow"
                  [tableData]="tableData"
                  [rowPossibleActions]="rowPossibleActions"
                  [localeLanguageDropdown]="localeLanguageDropdown"
                  [service]="service"
                  [langChanged]="langChanged"
                  (changeLang)="changeLang.emit()"
                  (actionType)="tableActionClicked($event)">
</app-mobile-table>

<ng-template #bigScreen>
    <app-tablet-table *ngIf="isTablet; else desktop"
        [withIndex]="pagination?.from"
        [headerRow]="headerRow"
        [tableData]="tableData"
        [rowPossibleActions]="rowPossibleActions"
        [localeLanguageDropdown]="localeLanguageDropdown"
        [service]="service"
        [langChanged]="langChanged"
        (changeLang)="changeLang.emit()"
        (actionType)="tableActionClicked($event)">
    </app-tablet-table>

    <ng-template #desktop>
        <div class="card">
            <div class="card-body">
                <div class="material-datatables">
                    <div *ngIf="service.getRequestOptions() && localeLanguageDropdown" class="row justify-content-end">
                        <div class="col-2">
                            <app-content-language-dropdown (changeLang)="changeLang.emit()" [events]="langChanged.asObservable()"></app-content-language-dropdown>
                        </div>
                    </div>
                    <div class="">
                        <table class="table table-striped table-no-bordered table-hover">
                            <thead class="text-dark">
                                <tr>
                                    <th *ngIf="!hideIndex"
                                        class="{{ hideIndex ? '' : 'all' }} w-30px text-center">
                                        {{ '#' | translate }}
                                    </th>
                                    <th *ngFor="let th of headerRow"
                                        class="{{th.cssClasses}} table-header-field">
                                        {{ th.name | translate }}
                                    </th>
                                    <th class="actions text-right all w-50px"
                                        *ngIf="rowPossibleActions">
                                        {{ "Actions" | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="tableData?.dataRows?.length">
                                    <tr *ngFor="let row of tableData?.dataRows; index as i"
                                        class="edit"
                                        (click)="path ? goToLink(path, row.id) : ''"
                                        [class.clickable]="path">
                                        <td *ngIf="!hideIndex"
                                            class="text-center"
                                            (click)="rowPossibleActions ? openDialogFromField(i, rowPossibleActions, row?.actions) : ''">
                                            {{ pagination?.from ? (pagination?.from + i) : (i + 1) }}
                                        </td>
                                        <td *ngFor="let cell of headerRow"
                                            class="table-{{cell.type}}-parent"
                                            (click)="rowPossibleActions ? openDialogFromField(i, rowPossibleActions, row?.actions) : ''">
                                            <app-table-cell class="d-block {{cell.cssClasses}}"
                                                            [cell]="cell"
                                                            [row]="row"
                                                            [userStatuses]="userStatuses()">
                                            </app-table-cell>
                                        </td>
                                        <td class="text-right" *ngIf="rowPossibleActions">
                                            <app-dropdown-actions [rowAvailableActions]="row?.actions"
                                                                    data-cy="dropdown-actions"
                                                                    [rowPossibleActions]="rowPossibleActions"
                                                                    [rowIndex]="i"
                                                                    [row]="row"
                                                                    (actionType)="tableActionClicked($event)">
                                            </app-dropdown-actions>
                                        </td>
                                    </tr>
                                    <tr *ngIf="totals"
                                        class="total-row font-weight-bold">
                                        <td>{{ "Total" | translate | uppercase }}</td>
                                        <td *ngFor="let cell of headerRow"
                                            class="text-right">
                                            <span class="text-nowrap"
                                                  *ngIf="cell.total"
                                                  [datatablesCalculateTotal]="cell"
                                                  [list]="totals">
                                                {{ totals[cell.total] }}
                                            </span>
                                        </td>
                                        <td *ngIf="rowPossibleActions"></td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <ng-container *ngIf="!tableData?.dataRows?.length">
                    <div *ngIf="appSettingsService.isLoading | async"
                         class="d-flex justify-content-center my-3 w-100">{{ "LoadingResults" | translate }}</div>
                    <div *ngIf="!appSettingsService.isLoading | async"
                         class="d-flex justify-content-center my-3 w-100"><span data-cy="no-results">{{ "NoResults" | translate }}</span></div>
                </ng-container>
            </div>
        </div>
    </ng-template>
</ng-template>

<div class="d-flex align-items-center justify-content-between pagination-fields"
     *ngIf="pagination"
     [class.mobile-pagination]="isMobile || isTablet"
     [class.disabled-input]="!pagination?.current_page">
    <div class="d-flex align-items-center justify-content-around">
        <div class="d-flex align-items-baseline mat-mdc-paginator mat-mdc-paginator-page-size-label w-50">
            <span class="mr-2 text-nowrap">{{ "SelectPage" | translate }}</span>
            <div class="">
                <mat-form-field class="mat-mdc-paginator-page-size-select m-0 customized w-25">
                    <mat-select matSelect
                                id="page-number"
                                class="d-block w-40px"
                                [formControl]="selectPageCtrl">
                        <mat-option *ngFor="let item of (pagination?.current_page ? lengthOptions : initialPagination.lengthOptions)"
                                    [value]="item"
                                    (change)="changePage($event)">
                            {{ item }}
                            <span appearance="none" class="mdc-line-ripple"></span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex">
            <span class="mat-mdc-paginator mat-mdc-paginator-page-size-label ml-2 align-self-center">
                {{ "Page" | translate }} {{ pagination?.current_page || initialPagination.pageIndex }} {{ "Of" | translate | lowercase}} {{ pagination?.last_page
                || initialPagination.lastPage }}
            </span>
        </div>

    </div>

    <mat-paginator #paginator
                   [length]="pagination?.total || initialPagination.length"
                   [pageIndex]="pagination?.current_page - 1"
                   [pageSize]="pagination?.per_page || initialPagination.pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   (page)="onPageChanged.emit($event)"
                   aria-label="{{ 'SelectPage' | translate}}">
    </mat-paginator>

</div>