import { DialogRef } from '@angular/cdk/dialog';
import { NgIf } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { ProductModel } from '../../models/product.model';
import { ProductsService } from '../../services/product.service';

@UntilDestroy()
@Component({
    selector: 'app-product-image-tab',
    templateUrl: './product-image-tab.component.html',
    styleUrls: ['./product-image-tab.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, NgIf, MatDialogModule, TranslateModule]
})
export class ProductImageTabComponent implements OnInit {
    public form: FormGroup;

    @Input() product: ProductModel;
    @Input() dialogRef: DialogRef;
    @Input() _saveAndClose: boolean;

    @ViewChild('removeImage', { static: true }) removeProductImage: ElementRef;


    constructor(
        private _productsService: ProductsService,
        private _sweetAlertService: SweetAlertService,
        private _formBuilder: FormBuilder) {
        this.form = this._formBuilder.group({
            image: [this.product?.image],
        });
    }

    ngOnInit(): void {
        this._productsService.onSaveSuccess$.pipe(filter(res => !!res), untilDestroyed(this)).subscribe(() => {
            this.removeProductImage.nativeElement.click();
        });
    }

    saveAndExit() {
        if (this.form.value.image) {
            this.uploadFileToActivity(this.form.value);
            this._saveAndClose = true;
            this.dialogRef.close();
            return;
        }
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.form.value.image) {
            this.uploadFileToActivity(this.form.value);
        }
    }

    handleFileInput(files: FileList, fileType: string) {
        this.form.get(fileType).setValue(files[0]);
    }

    uploadFileToActivity(img: string) {
        this._productsService.updateProductImage(this.product.id, img);
    }

    removeFormImage(input: HTMLInputElement) {
        input.value = '';
        this.form.controls.image.setValue('');
    }

    deleteProductImage() {
        this._sweetAlertService.showSwal('warning-message-ir', () => this._productsService.removeProductImage(this.product.id), "TheProductImage");

    }
}
