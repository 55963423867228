import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BrandInvoiceProfileModel } from '../../../models/brand-invoice-profile.model';
import { BrandInvoiceProfileService } from '../../../services/brand-invoice-profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorComponent } from '../../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../../shared/directives/form-validator.directive';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-invoice-profile-info-tab',
    templateUrl: './invoice-profile-info-tab.component.html',
    styleUrls: ['./invoice-profile-info-tab.component.css'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, TranslateModule]
})

export class InvoiceProfileInfoTabComponent implements OnInit {
    public form: FormGroup;
    public PermissionsEnum = PermissionsEnum;

    @Input() data: BrandInvoiceProfileModel;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
    @Output() saveAndClose: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private _brandInvoiceProfileService: BrandInvoiceProfileService,
        private _formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            name: this.data?.name || '',
            city: this.data?.city || '',
            address: this.data?.address || '',
            phone_fax: this.data?.phone_fax || '',
            mobile1: this.data?.mobile1 || '',
            mobile2: this.data?.mobile2 || '',
            email1: this.data?.email1 || '',
            email2: this.data?.email2 || '',
            website: this.data?.website || '',
            fiscal_code: this.data?.fiscal_code || '',
            chamber_of_commerce_code: this.data?.chamber_of_commerce_code || '',
            bank: this.data?.bank || '',
            bank_account: this.data?.bank_account || '',
            is_default_profile: this.data?.is_default_profile || '',
        });
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this.saveAndClose.emit(true);
        } else {
            this.form.markAllAsTouched();
        }
    }

    onSubmit() {
        if (!this.data) {
            this._brandInvoiceProfileService.createBrandInvoiceProfile(this.form.value);
        } else {
            this._brandInvoiceProfileService.updateBrandInvoiceProfile({
                id: this.data.id,
                ...this.form.value
            });
        }
    }
}
