import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MeasurementUnitsService } from '../../services/measurement-unit.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { validateAllFormFields } from 'src/app/shared/utils/form.utils';
import { filter } from 'rxjs';
import { MeasurementUnitModel } from '../../models/measurement-unit.model';
import { TranslateModule } from '@ngx-translate/core';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ContentLanguageDropdownComponent } from '../../../../shared/components/content-language-dropdown/content-language-dropdown.component';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-measurement-units-modal',
    templateUrl: './measurement-units-modal.component.html',
    styleUrls: ['./measurement-units-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, ContentLanguageDropdownComponent, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DialogFooterButtonsComponent, TranslateModule]
})
export class MeasurementUnitsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public selectedMeasurementUnit: MeasurementUnitModel;
    public form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<MeasurementUnitsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MeasurementUnitModel,
        private _formBuilder: FormBuilder,
        private _measurementUnitsService: MeasurementUnitsService) {
        super(dialogRef, _measurementUnitsService);
        this.data?.id && this._measurementUnitsService.getMeasurementUnit(this.data.id);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.form = this._formBuilder.group({
            id: this.selectedMeasurementUnit?.id || '',
            name: this.selectedMeasurementUnit?.name || '',
        });

        this._measurementUnitsService.selectedItem$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.selectedMeasurementUnit = data;
            this.form?.patchValue(data);
        })
        this._measurementUnitsService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => {
            this.dialogRef.close();
        });
        this.dialogRef.beforeClosed().pipe(untilDestroyed(this)).subscribe(() => this._measurementUnitsService.getMeasurementUnits());
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        if (!this.selectedMeasurementUnit) {
            this._measurementUnitsService.createMeasurementUnit(this.form.value);
            return;
        }
        this._measurementUnitsService.updateMeasurementUnit(this.form.value);
    }

    changeLang() {
        this.data.id && this._measurementUnitsService.getMeasurementUnit(this.data.id);
    }
}
