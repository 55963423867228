<div *ngIf="service.getRequestOptions() && localeLanguageDropdown" class="row justify-content-end">
    <div class="col-4 mt-3">
        <app-content-language-dropdown (changeLang)="changeLang.emit()" [events]="langChanged.asObservable()"></app-content-language-dropdown>
    </div>
</div>

<div class="container-fluid mt-4">
    <div class="row subheader-title pb-2 text-uppercase">
        <div class="col-1 small text-center px-0">#</div>
        <div class="col-9 small">{{ "Details" | translate }}</div>
        <div class="col-2 small text-right px-0">{{ "Actions" | translate }}</div>
    </div>

    <ng-container *ngIf="tableData?.dataRows?.length">
        <div *ngFor="let row of tableData.dataRows; index as i"
             class="row py-3"
             [appStrippedTable]="i">
            <div class="col-1 px-0 small text-center index-row">{{ withIndex ? (withIndex + i) : (i + 1) }}</div>
            <div class="col-10">
                <div *ngFor="let cell of headerRow">
                    <div *ngIf="!cell.hideOnTablet"
                         class="mb-2 d-sm-flex justify-content-between">
                        <div class="small text-secondary">{{ cell.name | translate }}:</div>
                        <app-table-cell class="small font-weight-bold {{cell.tabletClasses}}"
                                        [cell]="cell"
                                        [row]="row"
                                        [userStatuses]="userStatuses">
                        </app-table-cell>
                    </div>
                </div>
            </div>

            <div class="actions-wrapper col-1 px-0"
                 *ngIf="rowPossibleActions">
                <app-dropdown-actions [rowAvailableActions]="row?.actions"
                                      [rowPossibleActions]="rowPossibleActions"
                                      [rowIndex]="i"
                                      [row]="row"
                                      [icon]="'more_vert'"
                                      (actionType)="actionType.emit($event)">
                </app-dropdown-actions>
            </div>

            <div class="col-12 mt-2"
                 *ngIf="hasHiddenCols">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ "more..." | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <hr class="mt-0 mb-3">
                        <div *ngFor="let cell of headerRow"
                             class="table-{{cell.type}}-parent">
                            <div *ngIf="cell.hideOnTablet"
                                 class="mb-2 d-sm-flex justify-content-between">
                                <div class="small text-secondary">{{ cell.name | translate }}:</div>
                                <app-table-cell class="small font-weight-bold"
                                                [cell]="cell"
                                                [row]="row"
                                                [userStatuses]="userStatuses">
                                </app-table-cell>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!tableData?.dataRows?.length">
        <div class="d-flex justify-content-center my-3 w-100">{{ "NoResults" | translate }}</div>
    </ng-container>
</div>