import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { InvoiceModel, InvoiceServiceModel } from '../../models/invoice.model';
import { InvoicesService } from '../../services/invoices.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { GradientBorderTitleComponent } from '../../../../../shared/components/gradient-border-title/gradient-border-title.component';
import { PreloaderComponent } from '../../../../../shared/components/preloader/preloader.component';


@UntilDestroy()
@Component({
    selector: 'app-reverse-invoice-modal',
    templateUrl: './reverse-invoice-modal.component.html',
    styleUrls: ['./reverse-invoice-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, PreloaderComponent, GradientBorderTitleComponent, AsyncPipe, DatePipe, TranslateModule]
})
export class ReverseInvoiceModalComponent implements OnInit {
    public invoiceServicesList: InvoiceServiceModel[];
    public isLoading = false;
    public selectedInvoice: InvoiceModel;
    public reverseStatus: boolean;
    public undistributedValue: number;
    public parseFloat = parseFloat;

    constructor(public dialogRef: MatDialogRef<ReverseInvoiceModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private _data: InvoiceModel,
        private _sweetAlertService: SweetAlertService,
        public _settingsService: SettingsService,
        private _invoicesService: InvoicesService) {
        this.selectedInvoice = _data;
    }

    ngOnInit(): void {
        this.selectedInvoice?.id && this._invoicesService.getInvoice(this.selectedInvoice.id);

        this._invoicesService.selectedInvoice$.pipe(filter(invoice => !!invoice), untilDestroyed(this)).subscribe(invoice => this.selectedInvoice = invoice);
        this.dialogRef.beforeClosed().subscribe(() => this._invoicesService.getInvoices());
    }

    reverseInvoice(): void {
        this._sweetAlertService.showSwal("warning-message-send-reverse", () => {
            this._invoicesService.reverseInvoice(this.selectedInvoice.id, this.invoiceServicesList);
            this.dialogRef.close();
        })
    }
}
