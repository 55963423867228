<ng-container *ngFor="let field of cell.fieldNames">
    <ng-container [ngSwitch]="cell.cellType">
        <span *ngSwitchCase="tableCellType.TOOLTIP" [appCreateTooltip]="row" [fieldName]="field"
            [tooltipStyle]="cell.cellContentStyle" [tooltipValue]="getValueFromObject(row, field)"
            class="table-cell table-{{cell.type}} {{field}}" style="cursor: pointer;">
        </span>

        <span *ngSwitchCase="tableCellType.DEFAULT_PROFILE" [appCreateBadge]="row" [fieldName]="field"
            [badgeValue]="getValueFromObject(row, field)" class="table-cell table-{{cell.type}} {{field}}"
            style="cursor: pointer;">
        </span>

        <span *ngSwitchCase="tableCellType.FULL_NAME">
            {{ row | SplitFieldsPipe : field }}
        </span>

        <span *ngSwitchCase="tableCellType.DASH_SEPARATED">
            {{ row | DashSepareFieldsPipe : cell.fieldNames : field }}
        </span>
        <span *ngSwitchCase="tableCellType.COUNT_WITH_POPOVER" style="cursor:pointer" [matTooltip]="getAlergens(row[cell.fieldNames])" matTooltipClass="allergens-tooltip">
            {{ row| AllergensCounter }}
        </span>

        <span *ngSwitchCase="tableCellType.PERCENT" class="d-block text-right">
            {{ row[field] }}%
        </span>

        <span *ngSwitchCase="tableCellType.PRICE" #tableCell class="table-cell table-{{cell.type}} {{field}} d-block  text-sm-right" >
            {{ row | ModelWithSubPropertiesPipe : field : tableCell | priceFormating}} {{settingsService.currency$ | async}}
        </span>

        <span *ngSwitchCase="tableCellType.DATE" class="d-block text-center">
            {{ row[field] | date : 'dd.MM.yyyy' }}
        </span>

        <span *ngSwitchCase="tableCellType.DATE_FORMAT_ON_MULTIPLE_ELEMENTS" #tableCell
            class="table-cell table-{{cell.type}} {{field}}">
            {{ row | ModelWithSubPropertiesPipe : field : tableCell | FormatDatePipe : field }}
        </span>

        <span *ngSwitchCase="tableCellType.DATE_HOUR_FORMAT" class="d-block text-center">
            <div>{{ row[field] | date : 'dd.MM.yyyy' }}</div>
            <span class="text-table-small">{{ row[field] | date : 'HH:mm:ss' }}</span>
        </span>
        <span *ngSwitchCase="tableCellType.CALCULATE_TOTAL_ORDER_VALUE" class="d-block text-right">
            <span class="">{{ row[field] | CalculateTotalOrderValuePipe}}</span>
        </span>
        <span *ngSwitchCase="tableCellType.OPEN_CLOSED" class="d-block text-left text-sm-right text-lg-center text-lg-center">
            <span class="badge {{row[field]? 'badge-success': 'badge-secondary'}}">{{ row[field] ?( "Open-f" | translate): ("Closed-f" | translate)}}</span>
        </span>

        <span *ngSwitchCase="tableCellType.PAYMENT_TYPE" class="d-block text-left text-sm-right text-lg-center text-lg-center">
            <span class="badge badge-warning" *ngIf="row[field] === 'cash'">{{row[field] | translate}}</span>
            <span class="badge badge-info" *ngIf="row[field] === 'card'">{{row[field] | translate}}</span>
        </span>
        <span #tableCell *ngSwitchCase="tableCellType.ASSET_TYPE" class="d-block text-left text-sm-right text-lg-center text-lg-center">
            <span class="badge {{(row['asset']['asset_type']['id']) === 1 ? 'badge-warning': 'badge-info'}}">          
                {{row | ModelWithSubPropertiesPipe : field : tableCell | translate }}
            </span>
        </span>
        
        <span #tableCell *ngSwitchCase="tableCellType.ASSET_NAME" class="d-block text-left text-sm-right text-lg-center">
            <span class="">{{ row | ModelWithSubPropertiesPipe : field : tableCell  }}</span>
        </span>

        <span *ngSwitchCase="tableCellType.DATE_HOUR_FORMAT_WITH_MULTIPLE_PROPS" class="d-block text-left text-sm-right text-lg-center">
            <span class="{{cell.block ? 'd-block' : 'mr-1' }}">{{ (row[field] | date : 'dd.MM.yyyy') || '-'}}</span>
            <span class="small">{{ row[field] | date : 'HH:mm' }}</span>
        </span>

        <span *ngSwitchCase="tableCellType.EMPTY_CONTENT_TO_LINE" class="table-cell table-{{cell.type}} {{field}}">
            {{ (row | SplitFieldsPipe : field) ? (row | SplitFieldsPipe : field) : "-"}}
        </span>

        <div *ngSwitchCase="tableCellType.BOOLEAN_LABEL" class="{{!isMobile ? 'text-center': ''}}">
            <span class="badge badge-{{row[field] ? 'success' : 'danger'}}">{{row[field] ? ("Yes" | translate) : ("No" |
                translate)}}</span>
        </div>

        <span #tableCell *ngSwitchCase="tableCellType.LABEL">
            {{row[field] | LabelPipe : tableCell : row : field | translate }}
        </span>

        <span #tableCell *ngSwitchCase="tableCellType.BADGE_OR_DATE">
            {{row[field] | BadgeOrDate : tableCell : row : field : element : tableCell | translate }}
        </span>

        <span #tableCell *ngSwitchCase="tableCellType.SUBSTRACT_LABEL">
            {{row | SubsctractLabelPipe : tableCell | translate }}
        </span>

        <span #tableCell *ngSwitchCase="tableCellType.TRANSLATE" class="table-cell table-{{cell.type}} {{field}}">
            {{row | ModelWithSubPropertiesPipe : field : tableCell | translate }}
        </span>

        <span *ngSwitchDefault #tableCell [appTransformToIcons]="row" class="table-cell table-{{cell.type}} {{field}} {{isMobile &&cell.mobileClasses}}"
            style="cursor: pointer;" currentCell="{{field}}" [appCreatedAt]="cell" fieldValue="{{row[field]}}">
            {{ row |
            ModelWithSubPropertiesPipe : field : tableCell |
            UserStatusesPipe : tableCell : userStatuses : row |
            DisplaySymbolPipe : field }}
        </span>
    </ng-container>
</ng-container>