import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CustomerRequestModel } from '../models/customer-request.model';

@Injectable({
    providedIn: 'root'
})

export class CustomerRequestsService extends BaseService {
    itemsList$: BehaviorSubject<CustomerRequestModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<CustomerRequestModel> = new Subject();
    customerRequestUpdated$: Subject<boolean> = new Subject();

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,
        private _lsService: LocalStorageService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    addCustomerRequest(customerRequest) {
        this.appSettingsService.isLoading.next(true);
        this._apiRequestService.submitPostRequest(apiEndpoints.customerRequests, customerRequest).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create customer request', error)
        });
    }

    createCustomerToMakeRequest(payload) {
        this.appSettingsService.isLoading.next(true);
        this._apiRequestService.submitPostRequest(apiEndpoints.customers, { name: null }).subscribe({
            next: apiResponse => {
                this._lsService.setValue('customerData', JSON.stringify(apiResponse.data));
                this.addCustomerRequest({ ...payload, customer_id: apiResponse.data.id });
                this.appSettingsService.isLoading.next(false);
            },
            error: error => this.reportError('Error on create customer', error)
        });
    }

    getCustomerRequests() {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.customerRequests).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get customerRequests', error)
        });
    };
    getCustomerRequests$() {
        this._appSettingsSerice.isLoading.next(true);
        return this._apiRequestService.submitGetRequest(apiEndpoints.customerRequests);
    };

    getCustomerRequest(id) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.customerRequests + '/' + id).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get customerRequests', error)
        });
    };

    updateCustomerRequest(id: string, payload?: any) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPutRequest(apiEndpoints.customerRequests + '/' + id, payload).subscribe({
            next: (apiResponse) => {
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data);
                this.customerRequestUpdated$.next(true)
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on update customerRequest', error)
        });
    }

    deleteCustomerRequest(id) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitDeleteRequest(apiEndpoints.customerRequests + '/' + id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on delete customerRequest', error)
        });
    }
}
