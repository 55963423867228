<div class="row dialog-top-header">
    <div class="col-12 dialog-pre-title">
        <small>{{ 'InvoiceProfiles' | translate }}</small>
    </div>
    <div class="col-10">
        <h1 class="mb-0" mat-dialog-title *ngIf="data;else add_brand_invoice_profile">
            {{ 'Edit' | translate }}: {{ data?.name }}
        </h1>
        <ng-template #add_brand_invoice_profile>
            <h1 class="mb-0" mat-dialog-title>
                {{ 'AddInvoiceProfile' | translate }}
            </h1>
        </ng-template>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn" (click)="closeModal()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="card-header card-header-tabs card-header-secondary">
        <div class="nav-tabs-navigation">
            <ul role="tablist" class="nav nav-pills nav-pills-primary">
                <li class="nav-item">
                    <a data-toggle="tab" href="#general-info" role="tablist" class="nav-link active">{{ 'GeneralInfo' |
                        translate }}</a>
                </li>
                <li class="nav-item" *ngIf="currentInvoiceProfile?.id">
                    <a data-toggle="tab" href="#invoice-series-tab" role="tablist" class="nav-link">{{ 'InvoiceSeries' |
                        translate }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div mat-dialog-content class="position-relative">
    <app-preloader></app-preloader>
    <div class="tab-content tab-space h-100 pb-0">
        <div id="general-info" class="tab-pane active pt-3">
            <app-invoice-profile-info-tab [data]="currentInvoiceProfile" (closeModal)="closeModal()"
                (saveAndClose)="saveAndClose($event)"></app-invoice-profile-info-tab>
        </div>

        <div id="invoice-series-tab" class="tab-pane">
            <app-invoice-series-tab *ngIf="currentInvoiceProfile?.id" [data]="currentInvoiceProfile"
                (closeModal)="closeModal()"></app-invoice-series-tab>
        </div>
    </div>
</div>