import { Directive, Input, ComponentFactoryResolver, ViewContainerRef, ElementRef, ComponentRef } from '@angular/core';
import { TooltipComponent } from '../components/tooltip/tooltip.component';
import { CellContentStyle } from '../models/table-data-headers.model';

@Directive({
    selector: '[appCreateTooltip]',
    standalone: true
})
export class CreateTooltipDirective {
    private _componentRef: ComponentRef<TooltipComponent>;
    private _value: string;
    public possibleFields = ['details_tab.order_details'];

    @Input() appCreateTooltip;
    @Input() fieldName: string;
    @Input() tooltipStyle: CellContentStyle;
    @Input() set tooltipValue(value: string) {
        this._value = value;
        this.tooltipSetup(value);
    }

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _viewContainerRef: ViewContainerRef,
        private _elementRef: ElementRef<HTMLElement>) { }

    tooltipSetup(value: string) {
        const foundField = !!this.possibleFields.find(field => field === this.fieldName);

        if (!value) {
            this._componentRef?.destroy();
            return;
        }
        if (foundField) {
            this._componentRef?.destroy();
            this.createComponent();
            return;
        }

        this.dispayInfoText(value);
    }

    createComponent() {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TooltipComponent);
        this._componentRef = this._viewContainerRef.createComponent(componentFactory);
        const keys = this.fieldName.split('.');
        this._componentRef.instance.message = this._value;
        this._componentRef.instance.icon = this.tooltipStyle.icon;
        this._componentRef.instance.iconColor = this.tooltipStyle.color;
        this._componentRef.instance.position = this.tooltipStyle.position;
    }

    dispayInfoText(value: string) {
        this._elementRef.nativeElement.innerHTML = value;
        this._elementRef.nativeElement.style.marginRight = '3px';
    }
}

