import { BrandProfileModel } from '../../../models/brand-profile.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BrandProfileService } from '../../../services/brand-profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FormFieldErrorComponent } from '../../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../../shared/directives/form-validator.directive';

@UntilDestroy()
@Component({
    selector: 'app-brand-general-info-tab',
    templateUrl: './brand-general-info-tab.component.html',
    styleUrls: ['./brand-general-info-tab.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, MatDialogModule, TranslateModule]
})
export class BrandGeneralInfoTabComponent implements OnInit {
    public form: FormGroup;

    @Input() data: BrandProfileModel;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
    @Output() saveAndClose: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private _brandProfileService: BrandProfileService,
        private _formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            address: this.data?.address,
            city: this.data?.city,
            default_invoice_profile_id: this.data?.brand_invoice_profile?.id || 0,
            email1: this.data?.email1,
            email2: this.data?.email2,
            facebook: this.data?.facebook,
            linkedin: this.data?.linkedin,
            instagram: this.data?.instagram,
            mobile1: this.data?.mobile1,
            mobile2: this.data?.mobile2,
            name: [this.data?.name, Validators.required],
            phone_fax: this.data?.phone_fax,
            website: this.data?.website,
            app_key: this.data?.app_key
        });
    }

    save() {
        if (this.form.valid) {
            this.onSubmit();
        }
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this.saveAndClose.emit(true);
        }
    }

    onSubmit() {
        this._brandProfileService.updateCurrentBrandProfile(this.form.value);
    }
}
