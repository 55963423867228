import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllergensComponent } from './allergens.component';
import { AllergensModalComponent } from './components/allergens-modal/allergens-modal.component';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';

import { TablesModule } from 'src/app/shared/components/tables/tables.module';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    AllergensComponent,
    AllergensModalComponent
],
})
export class AllergensModule { }
