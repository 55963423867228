import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../../shared/directives/form-validator.directive';

@Component({
    selector: 'app-add-costumer-confirmation-modal',
    templateUrl: './add-costumer-confirmation-modal.component.html',
    styleUrls: ['./add-costumer-confirmation-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, TranslateModule]
})
export class AddCostumerConfirmationModalComponent {
    @Output() onCreateCustomer = new EventEmitter<any>();
    public form: FormGroup;


    constructor(public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {

        this.form = this._formBuilder.group({
            name: `Customer C-${((data || 0) + 1)}`
        });
    }

}
