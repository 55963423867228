import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-no-item-to-show',
    templateUrl: './no-item-to-show.component.html',
    styleUrls: ['./no-item-to-show.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class NoItemToShowComponent implements OnInit {

  @Input() message: string;

  constructor() { }

  ngOnInit(): void {  
  }

}
