export enum PermissionsEnum {
    CAN_BE_EDITED = 'can_be_edited',
    CAN_BE_DELETED = 'can_be_deleted',
    CAN_BE_PRINTED = 'can_be_printed',
    CAN_COLLECT_PAYMENT = 'can_collect_payment',
    CAN_BE_REVERSED = 'can_be_reversed',

    VIEW_CUSTOMERS_LIST = 'customers_list.view',
    VIEW_CUSTOMERS_FORM = 'customers_form.view',
    CUSTOMERS_READ = 'customers.read',
    CUSTOMERS_ADD_AND_EDIT = 'customers.add_and_edit',
    CUSTOMERS_DELETE = 'customers.delete',


    VIEW_OWNERS_LIST = 'owners_list.view',
    VIEW_OWNERS_FORM = 'owners_form.view',
    OWNERS_READ = 'owners.read',
    OWNERS_ADD_AND_EDIT = 'owners.add_and_edit',
    OWNERS_DELETE = 'owners.delete',


    VIEW_ORDERS_LIST = 'orders_list.view',
    VIEW_ORDERS_FORM = 'orders_form.view',
    ORDERS_READ = 'orders.read',
    ORDERS_ADD_AND_EDIT = 'orders.add_and_edit',
    ORDERS_DELETE = 'orders.delete',

    VIEW_PAYMENTS_LIST = 'payments_list.view',
    VIEW_PAYMENTS_FORM = 'payments_form.view',
    PAYMENTS_READ = 'payments.read',
    PAYMENTS_ADD_AND_EDIT = 'payments.add_and_edit',
    PAYMENTS_DELETE = 'payments.delete',

    VIEW_ROLES_LIST = 'roles_list.view',
    VIEW_ROLES_FORM = 'roles_form.view',
    ROLES_READ = 'roles.read',
    ROLES_ADD_AND_EDIT = 'roles.add_and_edit',
    ROLES_DELETE = 'roles.delete',

    VIEW_ALLERGENS_LIST = 'allergens_list.view',
    VIEW_ALLERGENS_FORM = 'allergens_form.view',
    ALLERGENS_READ = 'allergens.read',
    ALLERGENS_ADD_AND_EDIT = 'allergens.add_and_edit',
    ALLERGENS_DELETE = 'allergens.delete',

    VIEW_ASSETS_LIST = 'assets_list.view',
    VIEW_ASSETS_FORM = 'assets_form.view',
    ASSETS_READ = 'assets.read',
    ASSETS_ADD_AND_EDIT = 'assets.add_and_edit',
    ASSETS_DELETE = 'assets.delete',

    VIEW_CATEGORIES_LIST = 'categories_list.view',
    VIEW_CATEGORIES_FORM = 'categories_form.view',
    CATEGORIES_READ = 'categories.read',
    CATEGORIES_ADD_AND_EDIT = 'categories.add_and_edit',
    CATEGORIES_DELETE = 'categories.delete',

    VIEW_PRODUCTS_LIST = 'products_list.view',
    VIEW_PRODUCTS_FORM = 'products_form.view',
    PRODUCTS_READ = 'products.read',
    PRODUCTS_ADD_AND_EDIT = 'products.add_and_edit',
    PRODUCTS_DELETE = 'products.delete',

    VIEW_TYPES_LIST = 'types_list.view',
    VIEW_TYPES_FORM = 'types_form.view',
    TYPES_READ = 'types.read',
    TYPES_ADD_AND_EDIT = 'types.add_and_edit',
    TYPES_DELETE = 'types.delete',

    VIEW_MEASUREMENT_UNITS_LIST = 'measurement_units_list.view',
    VIEW_MEASUREMENT_UNITS_FORM = 'measurement_units_form.view',
    MEASUREMENT_UNITS_READ = 'measurement_units.read',
    MEASUREMENT_UNITS_ADD_AND_EDIT = 'measurement_units.add_and_edit',
    MEASUREMENT_UNITS_DELETE = 'measurement_units.delete',

    VIEW_LOCATIONS_LIST = 'locations_list.view',
    VIEW_LOCATIONS_FORM = 'locations_form.view',
    LOCATIONS_READ = 'locations.read',
    LOCATIONS_ADD_AND_EDIT = 'locations.add_and_edit',
    LOCATIONS_DELETE = 'locations.delete',


    VIEW_RESERVATIONS_LIST = 'reservations_list.view',
    VIEW_RESERVATIONS_FORM = 'reservations_form.view',
    RESERVATIONS_READ = 'reservations.read',
    RESERVATIONS_ADD_AND_EDIT = 'reservations.add_and_edit',
    RESERVATIONS_DELETE = 'reservations.delete',


    VIEW_SERVICES_LIST = 'services_list.view',
    VIEW_SERVICES_FORM = 'services_form.view',
    SERVICES_READ = 'services.read',
    SERVICES_ADD_AND_EDIT = 'services.add_and_edit',
    SERVICES_DELETE = 'services.delete',

    VIEW_SESSIONS_LIST = 'sessions_list.view',
    VIEW_SESSIONS_FORM = 'sessions_form.view',
    SESSIONS_READ = 'sessions.read',
    SESSIONS_ADD_AND_EDIT = 'sessions.add_and_edit',
    SESSIONS_DELETE = 'sessions.delete',

    VIEW_SESSION_SERVICES_LIST = 'session_services_list.view',
    VIEW_SESSION_SERVICES_FORM = 'session_services_form.view',
    SESSION_SERVICES_READ = 'session_services.read',
    SESSION_SERVICES_ADD_AND_EDIT = 'session_services.add_and_edit',
    SESSION_SERVICES_DELETE = 'session_services.delete',

    SESSIONS_FINISH = 'sessions.finish',

    BRAND_PROFILE_READ = 'brand_profile.read',
    BRAND_PROFILE_ADD_AND_EDIT = 'brand_profile.add_and_edit',

    BRAND_INVOICE_PROFILE_READ = 'brand_invoice_profile.read',
    BRAND_INVOICE_PROFILE_ADD_AND_EDIT = 'brand_invoice_profile.add_and_edit',
    BRAND_INVOICE_PROFILE_DELETE = 'brand_invoice_profile.delete',

    SETTINGS_READ = 'settings.read',
    SETTINGS_ADD_AND_EDIT = 'settings.add_and_edit',

    PERMISSIONS_CHANGE = 'permissions.change',

    VIEW_INVOICES_LIST = "invoices_list.view",
    VIEW_INVOICES_FORM = "invoices_form.view",
    INVOICES_READ = 'invoices.read',
    INVOICES_ADD_AND_EDIT = 'invoices.add_and_edit',

    INVOICE_PAYMENTS_READ = 'invoice_payments.read',
    INVOICE_PAYMENTS_ADD_AND_EDIT = 'invoice_payments.add_and_edit',
    INVOICE_PAYMENTS_DELETE = 'invoice_payments.delete',

    INVOICE_SERIES_READ = 'invoice_series.read',
    INVOICE_SERIES_CREATE = 'invoice_series.create',
    INVOICE_SERIES_UPDATE = 'invoice_series.update',

    USERS_READ = 'users.read',
    USERS_ADD_AND_EDIT = 'users.add_and_edit',
    USERS_DELETE = 'users.delete',

    SELF_PROFILE_READ = 'self.profile.read',
    SELF_PROFILE_UPDATE = 'self.profile.update',

    VIEW_EXPENSE_CATEGORIES_LIST = 'expense_categories_list.view',
    VIEW_EXPENSE_CATEGORIES_FORM = 'expense_categories_form.view',
    EXPENSE_CATEGORIES_READ = 'expense_categories.read',
    EXPENSE_CATEGORIES_ADD_AND_EDIT = 'expense_categories.add_and_edit',
    EXPENSE_CATEGORIES_DELETE = 'expense_categories.delete',

    VIEW_EXPENSES_LIST = 'expenses_list.view',
    VIEW_EXPENSES_FORM = 'expenses_form.view',
    EXPENSES_READ = 'expenses.read',
    EXPENSES_ADD_AND_EDIT = 'expenses.add_and_edit',
    EXPENSES_DELETE = 'expenses.delete',

    INTERFACE_ACCESS_DASHBOARD = 'interface.access_dashboard',
    INTERFACE_ACCESS_PERMISSIONS = 'interface.access_permisions',

    PAYMENT_DOCUMENTS_READ = 'payment_documents.read',
    PAYMENT_DOCUMENTS_ADD_AND_EDIT = 'payment_documents.add_and_edit',
    PAYMENT_DOCUMENTS_DELETE = 'payment_documents.delete',

    REPORTS_READ = 'reports.read',
}
