import { Injectable } from '@angular/core';
import { CustomersService } from 'src/app/modules/customers/services/customers.service';
import { HttpContext, HttpContextToken } from '@angular/common/http';

export const customerVerification = new HttpContextToken<boolean>(() => null);
@Injectable({
    providedIn: 'root'
})
export class VerifyCCustomerIdService {

    constructor(private _custoemrsService: CustomersService) { }

    public initliaze(): Promise<any> {
        return new Promise((resolve, reject) => {
            let customerId = JSON.parse(localStorage.getItem('customerData'))?.id;
            customerId && this._custoemrsService.getCustomer$(customerId, undefined, {
                context: new HttpContext().set(customerVerification, true),
            }).subscribe({
                next: () => resolve(true),
                error: () => {
                    localStorage.removeItem('customerData');
                    resolve(true);
                }
            });
            resolve(true);
        })
    }
}
