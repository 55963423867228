import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { PaymentModel } from '../models/payment.model';

@Injectable({
    providedIn: 'root'
})

export class PaymentsService extends BaseService {
    itemsList$: BehaviorSubject<PaymentModel[]> = new BehaviorSubject(undefined);
    paymentTypesList$: BehaviorSubject<PaymentModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<PaymentModel> = new Subject();
    paidProductsChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    paymentsListChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    addPayment(payments: string[], type: string) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPostRequest(`${apiEndpoints.payments}`, { order_products_ids: payments, type: type }).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.paidProductsChanged$.next(true)
                this.paymentsListChanged$.next(true)
            },
            error: error => this.reportError('Error on create payment', error)
        });
    }

    getPayments(filter?: string, paginationSettings?: string) {
        this.appSettingsService.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.payments + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get payments list', error)
        });
    }

    getPayment(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitGetRequest(`${apiEndpoints.payments}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get payment', error)
        });
    };

    updatePayment(id: number, payment: PaymentModel) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPutRequest(apiEndpoints.payments + '/' + id, { type: payment.type }).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data);
                this.paidProductsChanged$.next(true)
            },
            error: error => this.reportError('Error on update payment', error)
        });
    }

    deletePayment(paymentId) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitDeleteRequest(apiEndpoints.payments + '/' + paymentId).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this.paymentsListChanged$.next(true);
            },
            error: error => this.reportError('Error on delete payment', error)
        });
    }
}
