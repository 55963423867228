import { SETTINGS } from "../constants";

export function payloadBuild(form: any, elementsToPop: string[]) {
    const newForm = { ...form };
    for (let item of elementsToPop) {
        if (newForm[item]) {
            delete newForm[item];
        }
    }
    return newForm;
}

export function getTotalForReverse(param): number {
    let sum = 0;
    this._invoicesServices?.forEach(service => {
        sum += +service[param];
    });
    return sum;
}

export function formatNumberToString(number: number) {
    return number.toFixed(2);
}

export function formatTime(number): string {
    return number.toString().length < 2 ? '0' + number : number;
}

export function getLang() {
    return localStorage.getItem("locale") ? localStorage.getItem("locale") : SETTINGS.DEFAULT_LANG;
}

export function utcConvert(date) {
    return new Date(new Date(date) + ' UTC').toString();
}