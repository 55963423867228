import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppSettingsService } from '../../services/app-settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, UpperCasePipe, KeyValuePipe } from '@angular/common';


@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, UpperCasePipe, KeyValuePipe, TranslateModule]
})
export class UploadFilesComponent implements OnInit, OnDestroy {

  public fileToUpload: File | null = null;
  public errorField:number = undefined;

  @Input() fileType;
  @Input() downloadUrl:string;
  @Input() errors;
  @Input() marketSelected;
  @Output() onUpload = new EventEmitter();
  @Output() clearErrors = new EventEmitter();
  @ViewChild('takeInput', {static: false}) InputVar: ElementRef;


  constructor(private changeDetection: ChangeDetectorRef,
    protected _appSettingsService: AppSettingsService) { }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.dismissFile();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.clearErrors.emit();
    this.refresh();
  }

  uploadFile() {
    const file = {
      file_name: this.fileToUpload.name.split('.')[0],
      file: this.fileToUpload,
      market: this.marketSelected || null
    }
    this.onUpload.emit(file);    
    this.refresh();
    this.errorField = undefined;
  }

  dismissFile() {
    this.fileToUpload = undefined;
    this.clearErrors.emit();
    this.refresh();
  }

  checkErrorLine(field) {
    if(this.errorField === field) {
      return false;
    }
    this.errorField = field;
    return true;
  }

  resetInputValue() {
    this.InputVar.nativeElement.value = "";
    this.clearErrors.emit();
  }

  refresh() {
    this.changeDetection.detectChanges();
  }

}
