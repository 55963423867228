import { MatPaginatorModule } from '@angular/material/paginator';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MatTooltipModule } from '@angular/material/tooltip';
import { RegularTableComponent } from './regular-table/regular-table.component';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedComponentsModule } from '../shared-components.module';
import { TableFiltersComponent } from './regular-table/components/table-filters/table-filters.component';
import { TableCellComponent } from './regular-table/components/table-cell/table-cell.component';
import { MobileTableComponent } from './regular-table/components/mobile-table/mobile-table.component';
import { MaterialModule } from '../../modules/material.module';
import { DatatablesCalculateTotalDirective } from './regular-table/directives/datatables-calculate-total.directive';
import { TabletTableComponent } from './regular-table/components/tablet-table/tablet-table.component';

@NgModule({
    imports: [
    CommonModule,
    MatTooltipModule,
    MaterialModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoader,
            deps: [HttpClient]
        }
    }),
    SharedComponentsModule,
    MatPaginatorModule,
    RegularTableComponent,
    TableFiltersComponent,
    TableCellComponent,
    MobileTableComponent,
    TabletTableComponent,
    DatatablesCalculateTotalDirective
],
    exports: [
        RegularTableComponent,
        TableFiltersComponent,
        TableCellComponent,
        MobileTableComponent,
        TabletTableComponent,
        DatatablesCalculateTotalDirective
    ]
})

export class TablesModule { }

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
