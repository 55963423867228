import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';

import { filter } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SETTINGS } from 'src/app/shared/constants';
import { ExportFormatsService } from './services/export-format.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { InvoiceModel } from '../../models/invoice.model';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { AccountsService } from 'src/app/modules/accounts/services/accounts.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { createFileName } from 'src/app/shared/utils/export.utils';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { InvoicesToExportListComponent } from './components/invoices-to-export-list/invoices-to-export-list.component';
import { DropdownWithSearchComponent } from '../../../../../shared/components/dropdown-with-search/dropdown-with-search.component';
import { NgIf } from '@angular/common';
import { DateRangePickerComponent } from '../../../../../shared/components/date-range-picker/date-range-picker.component';
import { FormValidatorDirective } from '../../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../../shared/components/preloader/preloader.component';


@UntilDestroy()
@Component({
    selector: 'app-export-invoices-modal',
    templateUrl: './export-invoices-modal.component.html',
    styleUrls: ['./export-invoices-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, DateRangePickerComponent, NgIf, DropdownWithSearchComponent, InvoicesToExportListComponent, TranslateModule]
})
export class ExportInvoicesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public SETTINGS = SETTINGS;
    public filters: string;
    public invoicesList: InvoiceModel[];
    public PermissionsEnum = PermissionsEnum;
    public usersList = this.accountsService.itemsList;
    public currentUser = this.accountsService.currentUser;
    public isAdmin = false;
    public showList = false;
    public isLoading = this._appSettings.isLoading;
    public formatExportCtrl = new FormControl;

    constructor(public dialogRef: MatDialogRef<ExportInvoicesModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public accountsService: AccountsService,
        private _appSettings: AppSettingsService,
        public userProfileService: UserProfileService,
        private _translateService: TranslateService,
        private _fb: FormBuilder,
        private _exportFormatsSerivce: ExportFormatsService) {
        super(dialogRef, _exportFormatsSerivce);
    }

    ngOnInit(): void {
        this.userProfileService.checkForPermission(PermissionsEnum.USERS_READ) && this.accountsService.getAccounts();

        this.form = this._fb.group({
            start_date: moment().format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            user_id: 0
        });

        this.form.valueChanges.subscribe(formValues => {
            const { user_id, ...rest } = formValues;
            const payload = {
                user_id: user_id
            };

            for (const prop in rest) {
                payload[prop] = moment(rest[prop]).format('YYYY-MM-DD');
            }

            this._exportFormatsSerivce.exportInvoices(parseMultiselectFilters(payload));
        })

        this._exportFormatsSerivce.invoicesToExport$.pipe(filter(invoices => !!invoices), untilDestroyed(this)).subscribe(invoices => this.invoicesList = invoices);
        // this.accountsService.itemsList$.pipe(filter(users => !!users), untilDestroyed(this)).subscribe(users => this.usersList = users);
        // this.userProfileService.currentUser$.pipe(filter(user => !!user), untilDestroyed(this)).subscribe(user => {
        //     this.currentUser = user;
        //     this.currentUser.roles[0] === 'admin' && (this.isAdmin = true);
        //     this.form.controls.user_id.setValue(this.isAdmin ? 0 : user.id);
        // });
    }

    ngOnDestroy(): void {
        this._exportFormatsSerivce.invoicesToExport$.next(undefined);
        // this.accountsService.itemsList$.next(undefined);
    }

    exportInvoices(): void {
        const exportFormat = this.formatExportCtrl.value;
        const title = this._translateService.instant('ExportInvoices');
        const fileName = createFileName(title, this.form, this.usersList());
        const queryParams = `&format_as=${exportFormat}&export_as=file`;
        this._exportFormatsSerivce.exportInvoices(parseMultiselectFilters(this.form.value), queryParams, exportFormat, fileName);
    }
}
