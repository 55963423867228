import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InvoiceModel } from '../../../../models/invoice.model';
import { InvoiceServicesService } from '../../../../services/invoice-services.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownWithEnumValuesComponent } from '../../../../../../../shared/components/dropdown-with-enum-values/dropdown-with-enum-values.component';
import { FormFieldErrorComponent } from '../../../../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../../../../shared/directives/form-validator.directive';

@UntilDestroy()
@Component({
    selector: 'app-invoice-services-form',
    templateUrl: './invoice-services-form.component.html',
    styleUrls: ['./invoice-services-form.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DropdownWithEnumValuesComponent, TranslateModule]
})
export class InvoiceServicesFormComponent implements OnInit, OnDestroy {

    private _data: InvoiceModel;
    public form: FormGroup;
    public initialFormValues = {
        name: '',
        measurement_unit: 'pcs',
        quantity: 1,
        vat: 0,
        unitary_price_including_vat: 0,
    }

    @Input() set data(value: InvoiceModel) {
        this._data = value;
        this.form && this.form.controls.vat.setValue(this._data.invoice_series?.vat);
    };

    get data() {
        return this._data;
    }

    constructor(private _invoiceServicesService: InvoiceServicesService, private _fb: FormBuilder) { }

    ngOnInit(): void {
        this.form = this._fb.group(this.initialFormValues);

        this._invoiceServicesService.selectedInvoiceService$.pipe(filter(service => !!service), untilDestroyed(this)).subscribe(() => this.form.reset(this.initialFormValues));
    }

    ngOnDestroy(): void {
        this._invoiceServicesService.selectedInvoiceService$.next(undefined);
    }

    addService() {
        this._invoiceServicesService.createInvoiceService({
            invoice_id: this.data.id,
            ...this.form.value
        });
    }
}
