import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownWithEnumValuesComponent } from '../../../../../shared/components/dropdown-with-enum-values/dropdown-with-enum-values.component';
import { DropdownWithSearchComponent } from '../../../../../shared/components/dropdown-with-search/dropdown-with-search.component';
import { FormFieldErrorComponent } from '../../../../../shared/components/form-field-error/form-field-error.component';
import { GradientBorderTitleComponent } from '../../../../../shared/components/gradient-border-title/gradient-border-title.component';
import { FormValidatorDirective } from '../../../../../shared/directives/form-validator.directive';
import { BrandInvoiceProfileModel, InvoiceSerieModel } from '../../../models/brand-invoice-profile.model';
import { BrandInvoiceProfileService } from '../../../services/brand-invoice-profile.service';
import { InvoiceSeriesListComponent } from './invoice-series-list/invoice-series-list.component';

@Component({
    selector: 'app-invoice-series-tab',
    templateUrl: './invoice-series-tab.component.html',
    styleUrls: ['./invoice-series-tab.component.css'],
    standalone: true,
    imports: [GradientBorderTitleComponent, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DropdownWithSearchComponent, DropdownWithEnumValuesComponent, InvoiceSeriesListComponent, TranslateModule]
})
export class InvoiceSeriesTabComponent implements OnInit {

    public form: FormGroup;
    public statusActive = [
        {
            name: 'Yes',
            id: 1,
            value: true
        },
        {
            name: 'No',
            id: 2,
            value: false
        }
    ];

    @Input() data: BrandInvoiceProfileModel;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

    constructor(private _formBuilder: FormBuilder, private _brandInvoiceProfileService: BrandInvoiceProfileService) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            series: '',
            brand_invoice_profile_id: this.data.id,
            start_no: 1,
            active: 0,
            currency: '',
        });
    }

    onNoClick(): void {
        this.closeModal.emit()
    }

    save() {
        this.addInvoiceSerie();
    }

    addInvoiceSerie() {
        const currentStatus = this.statusActive.find(status => status.id === this.form.controls.active.value);

        const payload: InvoiceSerieModel = {
            ...this.form.value,
            active: currentStatus?.value,
        };

        this._brandInvoiceProfileService.createInvoiceSerie(payload, this.data.id);
    }

    changeStatusActive(statusId: number) {
        this.form.controls.active.setValue(statusId);
    }
}
