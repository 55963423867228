<mat-form-field class="customized" [attr.data-cy]="data_cy">
    <mat-label [attr.for]="cypressSelector">{{ label | translate }}</mat-label>
    <mat-select matSelect [id]="cypressSelector" (selectionChange)="changeOption.emit($event.value)" [attr.name]="cypressSelector"
        disableOptionCentering [formControl]="control">
        <mat-option class="search-option" [attr.data-cy]="data_cy + '-dropdown-search'">
            <ngx-mat-select-search [formControl]="filterFormControl" [placeholderLabel]="'QuickSearch' | translate"
                [noEntriesFoundLabel]="'NoResults' | translate">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="firstOptionMessage" [value]="0" [id]="0">{{ firstOptionMessage | translate}}</mat-option>
        <mat-option *ngFor="let item of filteredItemsList" [value]="item.id || item.user_id" [attr.data-cy]="data_cy + '-dropdown-with-search-option'"
            [id]="item.id || item.user_id">{{
            cypressSelector == 'locations' && (item.name + ' - ' + item.owner.name) ||
            customField && item[customField] ||
            item?.name && item.name ||
            item?.title && item.title ||
            item?.series && item.series ||
            item?.first_name && (item.last_name + ' ' + item.first_name) ||
            item?.code && (item.code + ' - ' + item.value + ((item.type === 'Procent') ? '%' : ' ' + currency ))
            | translate }}
        </mat-option>
    </mat-select>
    <mat-error>
        <app-form-field-error [control]="control"></app-form-field-error>
    </mat-error>
</mat-form-field>