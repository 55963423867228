<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
         <small>{{(!data?.self_update ? 'Accounts' : 'EditProfile') | translate}}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12 pb-3">
        <h1 class="mb-0 dialog-title"
            *ngIf="currentUser()">
            {{'Edit' | translate}}:
            <span *ngIf="currentUser(); else namePlaceholder">
                {{ currentUser()?.first_name + ' ' + currentUser()?.last_name }}
            </span>
            <ng-template #namePlaceholder>-</ng-template>
        </h1>
        <h1 class="mb-0 dialog-title" *ngIf="!currentUser()"> {{'AddAccount' | translate}}</h1>
    </div>

    <div class="card-header card-header-tabs card-header-secondary">
        <div class="nav-tabs-navigation">
            <ul role="tablist"
                class="nav nav-pills nav-pills-primary">
                <li class="nav-item">
                    <a data-toggle="tab"
                        href="#general-info"
                        role="tablist"
                        class="nav-link active">
                        {{ 'GeneralInfo'| translate }}
                    </a>
                </li>
                <li class="nav-item"
                    *ngIf="currentUser()">
                    <a data-toggle="tab"
                        href="#images"
                        role="tablist"
                        class="nav-link">
                        {{ 'ProfilePicture' | translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<mat-dialog-content>
    <div class="position-relative">
        <app-preloader></app-preloader>
        <div class="tab-content tab-space pb-0">
            <div id="general-info"
                 class="tab-pane active">
                <app-accounts-general-info-tab [data]="currentUser()" [dialogRef]="dialogRef" [_saveAndClose]="_saveAndClose">
                </app-accounts-general-info-tab>
            </div>
    
            <div id="images"
                 class="tab-pane"
                 *ngIf="currentUser()">
                <app-accounts-image-tab [data]="currentUser()"
                                        [dialogRef]="dialogRef"
                                        [_saveAndClose]="_saveAndClose"
                                        (removeProfilePic)="removeProfilePic()">
                </app-accounts-image-tab>
            </div>
        </div>
    </div>
</mat-dialog-content>