<form [formGroup]="form">
    <div class="form-row">
        <div class="col-6 ">
            <mat-form-field class="customized">
                <mat-label>{{ "StartDate" | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" [formControl]="form.controls[startCtrlName]"
                    (click)="startDate.open()">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="customized">
                <mat-label>{{ "EndDate" | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" [formControl]="form.controls[endCtrlName]"
                    (click)="endDate.open()">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</form>