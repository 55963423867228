import { HttpErrorResponse } from '@angular/common/http';
import { AppSettingsService } from './app-settings.service';
import { FormErrorService } from './form-error.service';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { apiEndpoints, ApiRequestService } from './api-request.service';
import { ResourceListParamsModel } from '../models/generic-resource.model';
import { parsePayload } from '../utils/form.utils';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    onSaveSuccess$: Subject<any> = new Subject();
    resourceList$: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
    chainedList = signal<any[]>([]);
    chainedList$: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
    chainedDraft = signal<any[]>([]);

    constructor(
        public formErrorService: FormErrorService,
        public appSettingsService: AppSettingsService,
        protected _apiRequestService: ApiRequestService,
    ) { }

    protected getResourceList(payload) {
        return this.getResorce(apiEndpoints.genericResourceList + parsePayload(payload));
    }

    protected getChainedList(payload: ResourceListParamsModel) {
        this.appSettingsService.isLoading.next(true);
        this.getResourceList(payload).subscribe({
            next: apiResponse => {
                this.chainedDraft.set([...this.chainedDraft(), ...apiResponse.data]);
                if (apiResponse.current_page < apiResponse.last_page) {
                    this.getChainedList({ ...payload, page: apiResponse.current_page + 1, records_number: apiResponse.per_page })
                } else {
                    this.chainedList.set([...this.chainedDraft()])
                    this.chainedList$.next([...this.chainedDraft()])
                    this.chainedDraft.set([])
                    this.appSettingsService.isLoading.next(false);
                    return
                }
            },
            error: error => this.reportError('Error on get resource list', error)
        });
    }

    protected getRequestOptions(param?: any, context?: any) {
        return null;
    }

    protected reportError(message: string, response: HttpErrorResponse) {
        this.formErrorService.reportError$.next(response);
        this.appSettingsService.isLoading.next(false);
        this.onSaveSuccess$.next(undefined);
        console.error(message);
    }

    protected createResorce(url, payload): Observable<any> {
        return this._apiRequestService.submitPostRequest(url, payload, this.getRequestOptions());
    }
    protected getResorce(url, options?: object, context?: any): Observable<any> {
        return this._apiRequestService.submitGetRequest(url, this.getRequestOptions(options, context));
    }
    protected updateResorce(url, payload): Observable<any> {
        return this._apiRequestService.submitPutRequest(url, payload, this.getRequestOptions());
    }
    protected deleteResorce(url, options?: object): Observable<any> {
        return this._apiRequestService.submitDeleteRequest(url, this.getRequestOptions(options));
    }
}
