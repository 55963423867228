import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from 'src/app/shared/services/lang.service';
import { parseDateFormat, parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { DateIntervalPickerComponent } from '../../../../date-interval-picker/date-interval-picker.component';
import { DropdownWithBooleansComponent } from '../../../../dropdown-with-booleans/dropdown-with-booleans.component';
import { DropdownWithEnumValuesComponent } from '../../../../dropdown-with-enum-values/dropdown-with-enum-values.component';
import { DropdownWithSearchComponent } from '../../../../dropdown-with-search/dropdown-with-search.component';
import { SimpleInputTextComponent } from '../../../../simple-input-text/simple-input-text.component';
import { TableFilter, TableFilterTypesEnum } from './models/table-filter.model';

@UntilDestroy()
@Component({
    selector: 'app-table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, NgFor, NgSwitch, NgSwitchCase, DateIntervalPickerComponent, DropdownWithSearchComponent, DropdownWithEnumValuesComponent, SimpleInputTextComponent, DropdownWithBooleansComponent, TranslateModule]
})
export class TableFiltersComponent implements OnInit {

    private _queryParams: any;
    private _initialFormValues = {};
    public form: FormGroup;
    public options: any = {};
    public filterTypes = TableFilterTypesEnum;
    public parsedFilters: string;
    public isMobile = isMobile();

    @ViewChild('top') top: ElementRef<any>;

    @Input() filters: TableFilter[];
    @Input() tableId: string;
    @Input() set queryParams(value: any) {
        this._queryParams = value;
        this.form && this.updateLocalStorageTable(parseDateFormat(this.form.value), value);
        this.top?.nativeElement.scrollIntoView(0, 0);
    };
    get queryParams(): any {
        return this._queryParams;
    };
    @Output() applyFilters: EventEmitter<any> = new EventEmitter();

    constructor(public langService: LangService, private cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.form = new FormGroup({});
        this.createFilters();
        this.langService.langBehaviorSubject.subscribe(() => this.createFilters())
    }

    createFilters() {
        this.filters.forEach(filter => {
            filter.controls.forEach(control => {
                this.form.addControl(control, new FormControl(''), { emitEvent: false });
                this.setInitialFormValues(filter, control);
            });
            const existingValues = JSON.parse(localStorage.getItem(this.tableId))?.form;
            existingValues && this.form.patchValue(existingValues);

            filter.dispatchAction && filter.dispatchAction();

            filter.optionsList$?.pipe(map(res => res?.data || res), untilDestroyed(this)).subscribe(list => {
                this.cd.markForCheck();
                this.options[filter.resourceName] = list;
                const control = this.form.controls[filter.controls[0]];
                control.setValue(control.value || control.value === false ? control.value : 0, { emitEvent: false });
            });
        });
        setTimeout(() => this.save(true));
    }

    save(fromCloseModal?: boolean) {
        const queryParams = this.getQueryParamsFromLocalStorage();
        this.parsedFilters = parseMultiselectFilters(parseDateFormat(this.form.value), '&');
        !fromCloseModal && (queryParams.page = 1);
        this.updateLocalStorageTable(parseDateFormat(this.form.value), queryParams);
        this.applyFilters.emit({ parsedFilters: this.parsedFilters, queryParams });
    }

    updateLocalStorageTable(form: any, queryParams: any) {
        const payload = { queryParams, form };
        this.tableId && localStorage.setItem(this.tableId, JSON.stringify(payload));
    }

    getQueryParamsFromLocalStorage() {
        const localStorageParams = localStorage.getItem(this.tableId);
        const localStorageQueryParams = JSON.parse(localStorageParams)?.queryParams;
        if (localStorageQueryParams) return localStorageQueryParams;
        return this.queryParams;
    }

    setInitialFormValues(filter: TableFilter, control: string) {
        this._initialFormValues[control] = (filter.type === this.filterTypes.TEXT) ? '' : 0;
    }

    resetFilters() {
        this.form.reset(this._initialFormValues);
        this.save();
    }
}
