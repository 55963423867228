<div class="wrapper" #wrapper>



    <app-preloader></app-preloader>

    <div class="sidebar" data-color="accent" data-background-color="black" data-image="./assets/img/image_placeholder.jpg">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/image_placeholder.jpg)"></div>
    </div>


    <!-- Menu Nav -->
    <header class="app-header">  
        <section id="nav">
            <nav class="navbar navbar-light py-2 px-3 mb-1 d-block">
                <div class="d-flex">
                    <div class="mr-auto">
                        <a class="logo-link" href="#">
                            <img [src]="currentBrand?.logo" class="logotype 1" width="100%" height="100%" *ngIf="currentBrand?.logo; else fallbackCover"  alt="...">
                            <ng-template #fallbackCover>
                                <img src="../../assets/img/company_logo.png" class="logotype 2" width="100%" height="100%" alt="Menu App">
                            </ng-template>
                        </a>
                    </div>

                    <div class="d-flex justify-content-end align-items-center">
                        <div class="nav-item dropdown language">
                            <a class="nav-link" href="javascript:void(0)" id="language-dropdown" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="material-icons">language</i>
                            </a>
                             <div class="dropdown-menu dropdown-menu-right" aria-labelledby="language-dropdown">
                                <a class="dropdown-item" href="javascript:void(0)"
                                    *ngFor="let language of languages; trackBy:identifyLanguage" (click)="switchLang(language)">{{
                                    language | uppercase }}</a>
                            </div>
                        </div>

                        <div >
                        <button
                        class="navbar-toggler openbtn" 
                        type="button" 
                        (click)="openNav()" 
                        data-toggle="collapse" 
                        data-target="#navbarLinks">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="navbar-toggler-icon icon-bar"></span>
                            <span class="navbar-toggler-icon icon-bar"></span>
                            <span class="navbar-toggler-icon icon-bar"></span>
                        </button>
                        </div>
                    </div>
                    
                    <div id="mySidebar" class="sidebar">
                        <div class="sidebar-head">
                            <img [src]="currentBrand?.logo" class="logotype 3" width="100%" height="100%" *ngIf="currentBrand?.logo; else fallbackCover" alt="...">
                            <ng-template #fallbackCover>
                                <img src="../../assets/img/company_logo.png" class="logotype 4" width="100%" height="100%" alt="Menu App">
                            </ng-template>

                            <!-- <a href="javascript:void(0)" class="closebtn fade-out-btn" (click)="closeNav()">×</a> -->
                            <button class="btn btn-secondary-custom small-btn font-custom-500 white"
                                (click)="closeNav()">
                                <span class="material-icons">close</span>
                            </button>
                        </div>
                        <div class="top-header asset font-custom-500 uppercase pl-4" *ngIf="selectedAsset">
                        <p class="pl-2 mb-0 font-custom-500 white">{{selectedAsset.asset_type.name}}  {{selectedAsset.name}}</p>
                        </div>
                        <ul class="side-menu pl-0">
                            <li *ngFor="let category of availableCategories; trackBy:identifyCategory" class=""><a class="no-user-select" (click)="scrollTo(category)" routerLinkActive="active">{{ category.name }}</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    </header>
    <!-- End Menu Nav -->
    
    <div class="container-fluid px-0" #sectionCards>
        <!-- Pills section -->
        <div class="section-pills position-sticky" *ngIf="productsLoaded">
            <ul class="pills-list mb-0 mt-0">
                <li *ngFor="let category of availableCategories; index as i; trackBy:identifyCategory" class="category-pill my-2 {{i === 0 && 'active-pill'}}" [id]="'category-pill-'+ category.id"><span class="pills-link py-2 m-1" (click)="scrollTo(category)" routerLinkActive="active">{{ category.name }}</span></li>
                <button class="toggle-search" (click)="toggleSearch()" type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" class="bi bi-search" fill="none" viewBox="-4 -2 24 24" alt="Icon" class="undefined">
                    <path fill="#000" stroke="#000" stroke-width=".75" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>
                <span class="pl-1">{{"Search" | translate}}</span>
                </button>
            </ul>
            <!-- <hr class="search-separator mb-0"> -->
            <div class="input-field" *ngIf="search">
                <input id="inputSearch" [ngModel]="textSearch" (ngModelChange)="filterResults($event)" class="input-search font-custom-normal small" placeholder='{{"Search" | translate}}' type="text" autocomplete="off" (focusout)="focusOutSearch($event)">
            </div>
            <div class="alert py-2 mb-0" *ngIf="textSearch">
                <div class="small text-center font-custom-normal" *ngIf="searchProductsCount; else noProductsFound">
                    <div *ngIf="searchProductsCount > 1;else oneProduct">{{searchProductsCount}} {{"ProductsFound" | translate}}</div>
                    <ng-template #oneProduct>{{searchProductsCount}} {{"ProductFound" | translate}}</ng-template>
                </div>
                <ng-template #noProductsFound>
                    <div class="small text-center font-custom-normal">
                        {{"NoResults" | translate}}
                    </div>
                </ng-template>
            </div>
        </div>
        <!-- End Pills section -->
        <div class="cards-section">
            <div *ngIf="!productsLoaded; else products" class="loading-products w-100 h-50">
                <div class="h-100 d-flex flex-column">
                    <h3 class="d-flex justify-content-center">{{"LoadingProducts" | translate}}...</h3>

                    <div class="app-brand mt-auto py-4">
                        <div class="text-center">
                            <a class="app-link">
                                <img src="../../assets/img/sip&bite_secondary_transp_no-margins.png" width="160px" height=""  alt="img" class="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #products>
                <app-qr-menu-product 
                    [asset_id]="id" 
                    [categoriesList]="categoriesList" 
                    [logo]="currentBrand?.logo" 
                    [structuredList]="filteredList ? filteredList:structuredList"
                    [typesList]="typesList" 
                    (addOrderProduct)="addOrderProduct($event)"
                    ></app-qr-menu-product>
            </ng-template>
        </div>
    </div>
</div>
<app-scroll-to-top *ngIf="showScroll$ | async" (scrollToTop)="onScrollToTop()"></app-scroll-to-top>
<app-shopping-cart *ngIf="id" [selectedAsset]="selectedAsset" (openShoppingList)="openShoppingList($event)"></app-shopping-cart>
<app-call-waiter *ngIf="id" [selectedAsset]="selectedAsset" (callWaiter)="callWaiter($event)"></app-call-waiter>