import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { LS_KEY_LANG, LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class LangService {
    defaultLang: string = 'ro'
    langBehaviorSubject = new BehaviorSubject<string>(this.defaultLang);

    constructor(
        private translate: TranslateService,
        private localStorageService: LocalStorageService,
    ) {
        this.translate.addLangs(['ro', 'en', 'de']);
        let lang = this.localStorageService.getValue(LS_KEY_LANG);

        if (!lang) {
            lang = this.defaultLang;
        }

        this.translate.setDefaultLang(lang);

        this.langBehaviorSubject.next(lang);
    }


    setLang(lang: string) {
        const avlbLangs = this.translate.getLangs();
        if (avlbLangs.indexOf(lang) === -1) {
            console.error('Unavailable language:' + lang);
            return;
        }

        this.translate.use(lang);
        this.localStorageService.setValue(LS_KEY_LANG, lang);
        setTimeout(() => {
            this.langBehaviorSubject.next(lang);
        }, 50)
    }
}
