import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RegularTableCellType } from 'src/app/shared/enums/RegularTableCellType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { isMobile, isTablet } from 'src/app/shared/utils/responsive.utils';
import { TableData } from '../../md/md-table/md-table.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { PaginationModel } from './models/pagination.model';
import { Subject } from 'rxjs';
import { getLang } from 'src/app/shared/utils/utils';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DatatablesCalculateTotalDirective } from './directives/datatables-calculate-total.directive';
import { DropdownActionsComponent } from '../../dropdown-actions/dropdown-actions.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { ContentLanguageDropdownComponent } from '../../content-language-dropdown/content-language-dropdown.component';
import { TabletTableComponent } from './components/tablet-table/tablet-table.component';
import { MobileTableComponent } from './components/mobile-table/mobile-table.component';
import { NgIf, NgFor, AsyncPipe, UpperCasePipe, LowerCasePipe } from '@angular/common';
import { PreloaderComponent } from '../../preloader/preloader.component';

@UntilDestroy()
@Component({
    selector: 'app-regular-table',
    templateUrl: 'regular-table.component.html',
    styleUrls: ['./regular-table.component.scss'],
    standalone: true,
    imports: [PreloaderComponent, NgIf, MobileTableComponent, TabletTableComponent, ContentLanguageDropdownComponent, NgFor, TableCellComponent, DropdownActionsComponent, DatatablesCalculateTotalDirective, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatOptionModule, MatPaginatorModule, AsyncPipe, UpperCasePipe, LowerCasePipe, TranslateModule]
})

export class RegularTableComponent implements OnInit, OnChanges {
    langChanged: Subject<any> = new Subject<any>();
    public readonly pageSizeOptions: number[] = [10, 25, 50, 100];
    public tableData: TableData;
    public regularTableCellType = RegularTableCellType;
    public pageEvent: PageEvent;
    public userStatuses = this.appSettingsService.userStatuses;
    public lengthOptions: number[];
    public selectPageCtrl = new FormControl(0);
    public isMobile = isMobile();
    public isTablet = isTablet();
    public initialPagination = {
        pageSize: 1,
        length: 1,
        pageIndex: 1,
        lastPage: 1,
        lengthOptions: [1]
    }

    @Input() hideIndex: number;
    @Input() path: string;
    @Input() headerRow: string[];
    @Input() rowPossibleActions: RowPossibleActions[];
    @Input() icon: string;
    @Input() tableId: string;
    @Input() totals: any;
    @Input() pagination: PaginationModel;
    @Input() service: any;
    @Input() loading: boolean;
    @Input() length: number;
    @Input() pageIndex: number;
    @Input() lastPage: number;
    @Input() dataList: any[];
    @Input() localeLanguageDropdown: boolean;

    @Output() onPageChanged = new EventEmitter<PageEvent>();
    @Output() actionType = new EventEmitter<any>();
    @Output() changeLang = new EventEmitter<any>();

    @ViewChild('paginator') paginator: MatPaginator;

    constructor(
        private _router: Router,
        public appSettingsService: AppSettingsService,
        private _userProfileService: UserProfileService,
        private _translate: TranslateService,
        public settingsService: SettingsService
    ) { }

    ngOnInit() {
        this.tableData = {
            headerRow: this.headerRow,
            dataRows: []
        };

        this.selectPageCtrl.valueChanges.subscribe(value => this.onPageChanged.emit({
            pageSize: Number(this.pagination.per_page),
            pageIndex: value - 1,
            length: this.pagination.total
        }));
        this._translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
            this.paginator._intl.itemsPerPageLabel = this._translate.instant('ITEMS_PER_PAGE_LABEL');
            this.paginator._intl.nextPageLabel = this._translate.instant('NEXT_PAGE_LABEL');
            this.paginator._intl.previousPageLabel = this._translate.instant('PREVIOUS_PAGE_LABEL');
            this.paginator._intl.firstPageLabel = this._translate.instant('FIRST_PAGE_LABEL');
            this.paginator._intl.lastPageLabel = this._translate.instant('LAST_PAGE_LABEL');
            this.paginator['_intlChanges']._next(undefined);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.langChanged.next(getLang());

        changes.lastPage?.currentValue && (this.lengthOptions = Array(changes.lastPage.currentValue).fill('').map((q, i) => i + 1));
        changes.pageIndex?.currentValue && this.selectPageCtrl.setValue(changes.pageIndex.currentValue, { emitEvent: false });

        if (!this.pageIndex) {
            this.initialPagination.length = this.dataList?.length;
            this.selectPageCtrl.setValue(1, { emitEvent: false });
        }

        if (changes.dataList?.currentValue) {
            this.tableData && (this.tableData.dataRows = changes.dataList.currentValue);
            this.appSettingsService.isLoading.next(false);
        }
    }

    goToLink(path: string, id: number) {
        this._router.navigate([path + id]);
    }

    openDialogFromField(index: number, rowPossibleActions: RowPossibleActions[], rowAvailableActions?: any) {
        let permissionName = ' ';
        const defaultAction = rowPossibleActions.find(action => {
            return action.default
        });

        const type = defaultAction?.type;

        if (rowAvailableActions) {
            Object.keys(rowAvailableActions).forEach(action => {
                if (action === 'can_be_edited' && rowAvailableActions[action] && type) {
                    this.tableActionClicked({ index, type });
                }
            });
            return;
        }

        this.rowPossibleActions.forEach(permission => {
            if (permission.name === 'Edit' || permission.name === 'View') {
                permissionName = permission.permission;
            }
        });

        if (type && this._userProfileService.checkForPermission(permissionName)) {
            this.tableActionClicked({ index, type });
        }
    }

    tableActionClicked(event) {
        this.actionType.emit({ type: event.type, entry: this.tableData.dataRows[event.index] });
    }
}
