
    <div class="customer-modal">
        <div class="top-header py-1">
            <div class="dialog-top-header align-items-center d-flex w-100" mat-dialog-title id="mat-mdc-dialog-title-0">
                <div class="col-9">
                    <h5 class="asset-name font-custom-500 white">{{'CallWaiter' | translate}}</h5>
                </div>
                <div class="col-3 text-right">
                    <button class="btn btn-secondary-custom small-btn font-custom-500 white"
                            (click)="dialogRef.close()">
                        <span class="material-icons">close</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="dropdown" *ngIf="requestAlreadyExist !== undefined; else noActions">
            <app-call-waiter-form [requestAlreadyExist]="requestAlreadyExist" (onCallWaiter)="callWaiter($event)"></app-call-waiter-form>
        </div>
        <ng-template #noActions>
            <div class="requests-placeholder">
                <div class="h-100 d-flex align-items-center justify-content-center">
                    <h3>{{ 'Loading' | translate }}</h3>
                </div>
            </div>
        </ng-template>
    </div>
