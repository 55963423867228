import { Observable, of } from "rxjs";
import { SettingModel } from "../modules/settings/models/settings.model";


export const REFRESH_INTERVAL = 10000;
export const SETTINGS = {
    DEFAULT_VAT_VALUE: '19',
    DEFAULT_EXPORT_FORMAT: 'JSON',
    DEFAULT_LANG: 'ro'
}


export function changeThresholdDays(settings: SettingModel[]): any {
    if (settings) {
        settings.forEach(setting => {
            setting.id === 1 && (SETTINGS.DEFAULT_VAT_VALUE = setting.value);
            setting.id === 3 && (SETTINGS.DEFAULT_EXPORT_FORMAT = setting.value);
        });
        return SETTINGS;
    }
}

export function booleanOptions$(nameYes?: string, nameNo?: string, valueYes?: string | boolean, valueNo?: string | boolean): Observable<any> {
    return of({
        data: [
            {
                name: nameYes ? nameYes : 'Yes',
                value: valueYes ? valueYes : true
            },
            {
                name: nameNo ? nameNo : 'No',
                value: valueNo ? valueNo : false
            },
        ],
    });
}