import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { isMobile, isTablet } from '../utils/responsive.utils';


@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private _dialog: MatDialog) { }

    public openModal(entry: any | null, modalComponent: ComponentType<any>, height?: string, width?: string, panelClass?: string) {
        return this._dialog.open(modalComponent, {
            width: width ? width : ((isMobile() || isTablet()) ? '100vw' : '80vw'),
            maxWidth: panelClass === 'fullWidth' ? '100vw' : isMobile() || isTablet() ? '95vw' : '80vw',
            height: height ? height : '100vh',
            data: entry ? entry : null,
            disableClose: false,
            panelClass: panelClass
        });
    }

    public closeModal() {
        return this._dialog.closeAll()
    }
}
