import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { CustomerRequestsService } from './services/customer-requests.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CustomerRequestModel } from './models/customer-request.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { AssetsService } from '../assets/services/assets.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorComponent } from '../../shared/components/form-field-error/form-field-error.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../shared/directives/form-validator.directive';
import { CommonModule, NgIf } from '@angular/common';
import { PreloaderComponent } from '../../shared/components/preloader/preloader.component';
import { CallWaiterFormComponent } from 'src/app/shared/components/call-waiter-form/call-waiter-form.component';
@UntilDestroy()
@Component({
    selector: 'app-customer-requests',
    templateUrl: './customer-requests.component.html',
    styleUrls: ['./customer-requests.component.css'],
    standalone: true,
    imports: [PreloaderComponent, MatDialogModule, NgIf, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatSelectModule, MatOptionModule, FormFieldErrorComponent, TranslateModule, CommonModule, CallWaiterFormComponent]
})
export class CustomerRequestsComponent extends BaseModalComponent implements OnInit {
    public form: FormGroup;
    public callWaiterForm: FormGroup;
    public selectedRequest: CustomerRequestModel;
    public isPayment: boolean;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
    @Output() saveAndClose: EventEmitter<boolean> = new EventEmitter();
    public requestAlreadyExist = undefined;


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CustomerRequestsService>,
        private _customerRequestsService: CustomerRequestsService,
        private _formBuilder: FormBuilder,
        private _appSettingsSerice: AppSettingsService,
        private _assetsService: AssetsService
    ) {
        super(dialogRef, _customerRequestsService);
        this._assetsService.getAsset$(this.data.asset_id).subscribe(data => {
            this.requestAlreadyExist = data.data.customer_request ? data.data.customer_request : false;
            this._appSettingsSerice.isLoading.next(false);
        });
        this._customerRequestsService.onSaveSuccess$.pipe(untilDestroyed(this)).subscribe(res => res && this.dialogRef.close());
    }

    ngOnInit(): void {
        this._customerRequestsService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
        this.form = this._formBuilder.group({
            asset_id: this.data.asset_id,
            order_id: this.data.order_id,
            customer_id: this.data.customer_id,
            details: "",
            type: 0
        });

        this.form.controls.type.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.isPayment = value === 'payment' ? true : false;
        });
    }

    ngOnDestroy(): void {
        this._customerRequestsService.itemsList$.next(undefined)
    }

    callWaiter(e) {
        this._assetsService.getAsset$(this.data.asset_id).subscribe(data => {
            this.requestAlreadyExist = data.data.customer_request ? true : false;
            this._appSettingsSerice.isLoading.next(false);
            if (!this.requestAlreadyExist) {
                let payload = {...this.form.value, details: e.paymentType, type: e.requestType};
                payload.type !== 'payment' && delete payload.details;
                !payload.order_id && delete payload.order_id;
                if (payload.customer_id) {
                    this._customerRequestsService.addCustomerRequest(payload);
                    return;
                }
                this._customerRequestsService.createCustomerToMakeRequest(payload);
            }
        })
    }

}
