<div class="row dialog-top-header">
    <div class="col-12 dialog-pre-title">
        <small>{{ 'ExportInvoices' | translate }}</small>
    </div>
    <div class="col-10">
        <h1 class="mb-0" mat-dialog-title>
            {{ 'ExportInvoicesForm' | translate }}
        </h1>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn" (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
</div>

<div class="container-fluid position-relative">
    <app-preloader></app-preloader>

    <div class="spacer-2"></div>
    
    <form [formGroup]="form" appFormValidator>
        <div class="form-row mb-0">
            <div class="col-md-8">
                <app-date-range-picker [form]="form"></app-date-range-picker>
            </div>
            <div class="col-md-4" *ngIf="isAdmin; else showCurrentUser">
                <label for="user">{{ "User" | translate }}</label>
                <app-dropdown-with-search
                    [control]="form.controls.user_id"
                    [itemsList]="usersList"
                    [firstOptionMessage]="'AllAccounts'"
                    [cypressSelector]="'user'">
                </app-dropdown-with-search>
            </div>
            <ng-template #showCurrentUser>
                <div class="col-md-4 disabled-input">
                    <label>{{ "User" | translate }}</label>
                    <div class="input-border">{{ currentUser.first_name }} {{ currentUser.last_name }}</div>
                </div>
            </ng-template>
        </div>
    </form>
    
    <div class="row">
        <div class="col-12 text-center">
            <span>{{ "FoundInvoicesNo" | translate }}: </span>
            <span class="font-weight-bold">{{ invoicesList?.length || 0 }}</span>
            <button class="btn btn-seconary small-btn ml-4" (click)="showList = !showList" *ngIf="invoicesList?.length">
                <span class="material-icons mr-1">search</span>
                <span class="mr1">{{ showList ? ("HideDetails" | translate) : ("SeeDetails" | translate) }}</span>
            </button>
        </div>

    </div>
    
    <app-invoices-to-export-list class="col-12 list-wrapper" [invoicesList]="invoicesList" *ngIf="showList"></app-invoices-to-export-list>
    
    <div class="spacer-2"></div>
    
    <div class="row">
        <!-- <div class="col-12 d-flex align-items-end justify-content-end">
            <label for="type" class="mr-3 mb-0">{{ "ExportFormat" | translate }}</label>
            <div class="w-120px">
                <app-dropdown-with-enum-values [enumName]="'export_format'" [control]="formatExportCtrl"
                    [optionFormParent]="SETTINGS.DEFAULT_EXPORT_FORMAT">
                </app-dropdown-with-enum-values>
                <mat-error>
                    <app-form-field-error [control]="formatExportCtrl"></app-form-field-error>
                </mat-error>
            </div>
            <button class="btn btn-primary ml-4 mb-0" (click)="exportInvoices()" [disabled]="!invoicesList?.length">{{ "ExportInvoices" | translate }}</button>
        </div> -->
    </div>
</div>
