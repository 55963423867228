import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { AccountModel } from '../../models/account.model';
import { AccountsService } from '../../services/accounts.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccountsImageTabComponent } from './accounts-image-tab/accounts-image-tab.component';
import { AccountsGeneralInfoTabComponent } from './accounts-general-info-tab/accounts-general-info-tab.component';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-accounts-modal',
    templateUrl: './accounts-modal.component.html',
    styleUrls: ['./accounts-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, PreloaderComponent, AccountsGeneralInfoTabComponent, AccountsImageTabComponent, TranslateModule]
})
export class AccountsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public currentUser = this._accountsService.currentUser;

    constructor(
        public dialogRef: MatDialogRef<AccountsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AccountModel,
        private _sweetAlertService: SweetAlertService,
        private _accountsService: AccountsService) {
        super(dialogRef, _accountsService);
        this.data?.id && this._accountsService.getAccount(this.data.id, true);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.dialogRef.beforeClosed().pipe(untilDestroyed(this)).subscribe(() => this._accountsService.getAccounts());
    }

    ngOnDestroy(): void {
        this._accountsService.currentUser.set(undefined);
    }

    removeProfilePic() {
        this._sweetAlertService.showSwal('remove-picture', () => this._accountsService.removeProfileImage(this.currentUser().id), 'TheProfilePic')
    }
}
