import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Subscription, timer } from 'rxjs';
import { CustomerRequestsService } from 'src/app/modules/customer-requests/services/customer-requests.service';
import { OrderProductModel } from 'src/app/modules/orders/models/order-roduct.model';
import { OrdersService } from 'src/app/modules/orders/services/oerders.service';
import { OrderProductsService } from 'src/app/modules/orders/services/order-products.service';
import { PaymentsService } from 'src/app/modules/payments/services/payments.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { PaymentConfirmationComponent } from '../payment-confirmation/payment-confirmation.component';
import { parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { PaymentModel } from 'src/app/modules/payments/models/payment.model';
import { AssetsService } from 'src/app/modules/assets/services/assets.service';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentsListComponent } from '../payments-list/payments-list.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddCustomerComponent } from '../add-customer/add-customer.component';
import { OrderStatusesComponent } from '../order-statuses/order-statuses.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PreloaderComponent } from '../../../../../shared/components/preloader/preloader.component';
import { CustomersService } from 'src/app/modules/customers/services/customers.service';
@UntilDestroy()
@Component({
    selector: 'app-open-table-asset-modal',
    templateUrl: './open-table-asset-modal.component.html',
    styleUrls: ['./open-table-asset-modal.component.scss'],
    standalone: true,
    imports: [PreloaderComponent, MatDialogModule, NgIf, OrderStatusesComponent, AddCustomerComponent, NgFor, MatCheckboxModule, MatFormFieldModule, MatInputModule, PaymentsListComponent, AsyncPipe, TranslateModule]
})
export class OpenTableAssetModalComponent implements OnInit, OnDestroy {

    public products: OrderProductModel;
    public confirmedProductIds: string[] = [];
    public productsToPayIds: string[] = [];
    public unconfirmedProducts = 0;
    public unpaidProducts = 0;
    public productsToPay = [];
    public productsToConfirm = [];
    public payments: PaymentModel[];
    public totalToPaySelected = 0;
    public toPayQuantity = 0;
    public toConfirmQuantity = 0;
    readonly panelOpenState = signal(false);
    public totalProducts = 0;
    public quantity;
    public firstTimeLoading = true;
    parseFloat = parseFloat;
    obsValue = '';
    qtyValue: number;
    @Output() onCloseOrder = new EventEmitter<any>();
    public subscription: Subscription;
    public refreshTimer = this._settingsService.waiterPanelRefresh$.value || 20000;

    constructor(
        public dialogRef: MatDialogRef<CustomerRequestsService>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _ordersService: OrdersService,
        private _paymentService: PaymentsService,
        private _orderProductsService: OrderProductsService,
        public _settingsService: SettingsService,
        private _sweetAlertService: SweetAlertService,
        private _dialogService: DialogService,
        private _paymentsService: PaymentsService,
        private _assetsService: AssetsService,
        private _customersService: CustomersService
    ) {
        !data.id && this._assetsService.getAsset$(data.asset_id).subscribe(response => {
            if (response.data.order) data.id = response.data.order.id;
        })

        this.subscription = data.id && timer(0, this.refreshTimer).subscribe(() => {
            this._orderProductsService.getOrderProducts({ id: data.id }, this.firstTimeLoading)
            this.firstTimeLoading = false;

        })
        data.id && this._ordersService.orderClosed$.next(false)
        data.id && this._paymentsService.getPayments(parseMultiselectFilters({ "orderProducts.order_id": data.id }));
        this._orderProductsService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.products = data;
            this.updateProductsCount();
        })
        this._ordersService.orderProductsChanged$.pipe(filter(value => !!value), untilDestroyed(this)).subscribe(() => {
            this.confirmedProductIds = [];
            data.id && this._orderProductsService.getOrderProducts({ id: data.id })
            this.updateProductsCount();
        })
        this._paymentService.paidProductsChanged$.pipe(filter(value => !!value), untilDestroyed(this)).subscribe(() => {
            this.productsToPayIds = [];
            data.id && this._orderProductsService.getOrderProducts({ id: data.id })
            data.id && this._paymentsService.getPayments(parseMultiselectFilters({ "orderProducts.order_id": data.id }));
        })
        this._paymentsService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(payments => this.payments = payments)

        this._orderProductsService.selectedItem$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.data.id = this.data.id ? this.data.id : data.order_product.order_id
        })
    }

    ngOnInit() { }

    ngOnDestroy(): void {
        this._paymentsService.itemsList$.next(undefined);
        this.data = null;
        this.subscription.unsubscribe();
    }

    getTotal(order) {
        return order ? order.map(item => parseFloat(item.product.price) * parseFloat(item.quantity)).reduce((acc, current) => acc + current, 0) : 0;
    }

    removeProduct(product, customer) {
        this._sweetAlertService.showSwal('warning-message-remove-product', () => this._orderProductsService.deleteOrderProduct(product), { ...product, customer_name: customer });
    }

    isOrderProductConfirmed(id) {
        return this.confirmedProductIds.indexOf(id) >= 0;
    }


    addOrRemoveFromConfirmedProducts(e, prod) {
        e.checked ? this.confirmedProductIds.push(prod.id) : this.confirmedProductIds.splice(this.confirmedProductIds.indexOf(prod.id), 1);
        this.updateProductsCount();
    }

    updateProductsCount() {
        let unconfirmed = 0;
        let unpaid = 0;
        let total = 0;
        this.products?.customers?.forEach(customer => {
            customer.order_products?.forEach(prod => {
                total = total + this.products.quantity;
                if (!prod.confirmed_at) {
                    unconfirmed = unconfirmed + prod.quantity;
                }
                if (!prod.payment_id) {
                    unpaid = unpaid + prod.quantity;
                }
                if (this.productsToPayIds.find(id => id === prod.id) && !this.productsToPay.find(el => el.id === prod.id)) {
                    this.productsToPay.push({ id: prod.id, value: parseFloat(prod.quantity) * parseFloat(prod.unitary_price), quantity: prod.quantity })
                }
                if (this.productsToConfirm.find(el => el.id === prod.id && el.quantity !== prod.quantity)) {
                    this.productsToConfirm.splice(this.productsToConfirm.indexOf(this.productsToConfirm.find(el => el.id === prod.id)), 1);
                }
                if (this.confirmedProductIds.find(id => id === prod.id) && !this.productsToConfirm.find(el => el.id === prod.id)) {
                    this.productsToConfirm.push({ id: prod.id, quantity: prod.quantity })
                }

            })
        })
        this.totalProducts = total;
        this.unconfirmedProducts = unconfirmed;
        this.unpaidProducts = unpaid;
        this.totalToPaySelected = this.productsToPay.map(item => item.value).reduce((acc, current) => acc + current, 0);
        this.toPayQuantity = this.productsToPay.map(item => item.quantity).reduce((acc, current) => acc + current, 0);
        this.toConfirmQuantity = this.productsToConfirm.map(item => item.quantity).reduce((acc, current) => acc + current, 0);
    }

    confirmOrderProducts() {
        this._sweetAlertService.showSwal('confirmOrderProducts', () => this._ordersService.confirmOrderProducts({ id: this.data.id, order_products_ids: this.confirmedProductIds }), { toConfirm: this.toConfirmQuantity })
    }


    isOrderProductPaid(id) {
        return this.productsToPayIds.indexOf(id) >= 0;
    }

    addOrRemoveFromPaid(e, prod) {
        !e.checked && this.productsToPay.splice(this.productsToPay.indexOf(this.productsToPay.find((product) => product.id === prod.id)), 1);
        e.checked ? this.productsToPayIds.push(prod.id) : this.productsToPayIds.splice(this.productsToPayIds.indexOf(prod.id), 1);
        this.updateProductsCount()
    }

    addPayment() {
        this._dialogService.openModal({ productsToPay: this.productsToPay, productsToPayIds: this.productsToPayIds, value: this.totalToPaySelected, asset: this.data.asset_name }, PaymentConfirmationComponent, DimensionsEnum.AUTO, "85vw");
    }

    updateQuantity(product, e) {
        if (e.type === 'focusin') this.qtyValue = e.target.value;
        this.productsToPay.splice(this.productsToPay.indexOf(this.productsToPay.find(el => el.id === product.id)), 1)
        let payload = {
            quantity: e.target.value,
            customer_id: product.customer_id,
            observations: product.observations
        }
        if (e.target.value !== this.qtyValue) this._orderProductsService.updateOrderProduct(product.id, payload)
    }

    updateObservations(product, e) {
        if (e.type === 'focusin') this.obsValue = e.target.value;

        let payload = {
            quantity: product.quantity,
            customer_id: product.customer_id,
            observations: e.target.value
        }

        if (e.target.value !== this.obsValue) this._orderProductsService.updateOrderProduct(product.id, payload)
    }

    allProductsSelected(customer) {
        return customer.order_products.filter(item => !item.payment_id && item.confirmed_at).every(item => !item.payment_id && this.productsToPayIds.find(el => el === item.id));
    }

    cashAllPaymentsForCustomer(e, customer) {
        if (e.checked) {
            for (let product of customer.order_products) {
                if (product.confirmed_at && !product.payment_id && !this.productsToPayIds.find(item => item === product.id)) {
                    this.productsToPayIds.push(product.id)
                }
            }
        } else {
            for (let product of customer.order_products) {
                if (product.confirmed_at && !product.payment_id && this.productsToPayIds.find(item => item === product.id)) {
                    this.productsToPayIds.splice(this.productsToPayIds.indexOf(product.id), 1)
                }
            }
        }
        this.updateProductsCount();
    }

    hasProductsToPayAvailable(customer) {
        return customer.order_products.some(product => product.confirmed_at && !product.payment_id);
    }

    deleteCustomer(id) {
        this._sweetAlertService.showSwal('deleteCustomer', () => this._customersService.deleteCustomer(id))
    }

}
