import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { RolesModel } from './models/roles.model';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { RolesService } from './services/roles.service';
import { RolesModalComponent } from './components/roles-modal/roles-modal.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { TranslateModule } from '@ngx-translate/core';
import { UpperCasePipe } from '@angular/common';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { RestrictPermissionsDirective } from '../../shared/directives/restrict-permissions.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.css'],
    standalone: true,
    imports: [MatButtonModule, RestrictPermissionsDirective, RegularTableComponent, UpperCasePipe, TranslateModule]
})
export class RolesComponent implements OnInit, OnDestroy {
    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            sorting: true
        }
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.ROLES_ADD_AND_EDIT,
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.ROLES_DELETE,
        }
    ];

    public roles = this.rolesService.rolesList;
    public PermissionsEnum = PermissionsEnum;

    constructor(
        public rolesService: RolesService,
        public sweetAlertService: SweetAlertService,
        private _dialogService: DialogService
    ) {
        this.rolesService.getRoles();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.rolesService.rolesList.set(undefined)
    }

    tableActionClicked(e: any) {
        if (e.type === 'EDIT') {
            this._dialogService.openModal(e.entry, RolesModalComponent, DimensionsEnum.AUTO);
        }

        if (e.type === 'DELETE') {
            const item = 'Role';
            this.sweetAlertService.showSwal('warning-message', () => this.deleteEntry(e.entry), item);
        }
    }

    addEntry() {
        this._dialogService.openModal(null, RolesModalComponent, DimensionsEnum.AUTO);
    }

    private deleteEntry(entry: RolesModel) {
        this.rolesService.deleteRole(entry);
    }
}
