import { DropdownMenuOptionsComponent } from './dropdown-menu-options/dropdown-menu-options.component';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DropdownActionsComponent } from './dropdown-actions/dropdown-actions.component';
import { GradientBorderTitleComponent } from './gradient-border-title/gradient-border-title.component';
import { InputWithFocusComponent } from './input-with-focus/input-with-focus.component';
import { NoItemToShowComponent } from './no-item-to-show/no-item-to-show.component';
import { RolesCheckAllComponent } from './roles-check-all/roles-check-all.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { DropdownWithSearchComponent } from './dropdown-with-search/dropdown-with-search.component';
import { MaterialModule } from '../modules/material.module';
import { BadgeComponent } from './badge/badge.component';
import { TimerComponent } from './timer/timer.component';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';
import { SendToInvoiceComponent } from './send-to-invoice/send-to-invoice.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { DropdownWithMultiSelectComponent } from './dropdown-with-multiselect/dropdown-with-multiselect.component';
import { DownloadXlsPdfButtonsComponent } from './download-xls-pdf-buttons/download-xls-pdf-buttons.component';
import { GeneratePdfComponent } from './generate-pdf/generate-pdf.component';
import { GenerateXlsComponent } from './generate-xls/generate-xls.component';
import { DropdownWithBooleansComponent } from './dropdown-with-booleans/dropdown-with-booleans.component';
import { DropdownWithEnumValuesComponent } from './dropdown-with-enum-values/dropdown-with-enum-values.component';
import { SimpleInputTextComponent } from './simple-input-text/simple-input-text.component';
import { DateIntervalPickerComponent } from './date-interval-picker/date-interval-picker.component';
import { UpToDateComponent } from './up-to-date/up-to-date.component';
import { UserAccountFieldsComponent } from './user-account-fields/user-account-fields.component';
import { DialogFooterButtonsComponent } from './dialog-footer-buttons/dialog-footer-buttons.component';
import { ContentLanguageDropdownComponent } from './content-language-dropdown/content-language-dropdown.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { CallWaiterFormComponent } from './call-waiter-form/call-waiter-form.component';

@NgModule({
    imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    DateRangePickerComponent,
    DropdownActionsComponent,
    GradientBorderTitleComponent,
    InputWithFocusComponent,
    NoItemToShowComponent,
    RolesCheckAllComponent,
    TooltipComponent,
    DropdownWithSearchComponent,
    DropdownWithMultiSelectComponent,
    BadgeComponent,
    DropdownMenuOptionsComponent,
    TimerComponent,
    FormFieldErrorComponent,
    SendToInvoiceComponent,
    UploadFilesComponent,
    DownloadXlsPdfButtonsComponent,
    GeneratePdfComponent,
    GenerateXlsComponent,
    DropdownWithBooleansComponent,
    DropdownWithEnumValuesComponent,
    SimpleInputTextComponent,
    DateIntervalPickerComponent,
    UpToDateComponent,
    UserAccountFieldsComponent,
    DialogFooterButtonsComponent,
    ContentLanguageDropdownComponent,
    ScrollToTopComponent,
    WelcomePageComponent,
    PreloaderComponent,
    CallWaiterFormComponent
],
    exports: [
        DateRangePickerComponent,
        DropdownActionsComponent,
        GradientBorderTitleComponent,
        InputWithFocusComponent,
        NoItemToShowComponent,
        RolesCheckAllComponent,
        TooltipComponent,
        DropdownWithSearchComponent,
        DropdownWithMultiSelectComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        BadgeComponent,
        DropdownMenuOptionsComponent,
        TimerComponent,
        FormFieldErrorComponent,
        SendToInvoiceComponent,
        UploadFilesComponent,
        DownloadXlsPdfButtonsComponent,
        GeneratePdfComponent,
        GenerateXlsComponent,
        DropdownWithBooleansComponent,
        DropdownWithEnumValuesComponent,
        SimpleInputTextComponent,
        DateIntervalPickerComponent,
        UpToDateComponent,
        UserAccountFieldsComponent,
        DialogFooterButtonsComponent,
        ContentLanguageDropdownComponent,
        ScrollToTopComponent,
        WelcomePageComponent,
        PreloaderComponent,
        CallWaiterFormComponent
    ],
})

export class SharedComponentsModule { }