import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InvoiceSerieModel } from 'src/app/modules/brand-invoice-profile/models/brand-invoice-profile.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DropdownWithSearchComponent } from '../dropdown-with-search/dropdown-with-search.component';
import { GradientBorderTitleComponent } from '../gradient-border-title/gradient-border-title.component';
import { FormValidatorDirective } from '../../directives/form-validator.directive';

@Component({
    selector: 'app-send-to-invoice',
    templateUrl: './send-to-invoice.component.html',
    styleUrls: ['./send-to-invoice.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, FormValidatorDirective, GradientBorderTitleComponent, DropdownWithSearchComponent, MatFormFieldModule, FormFieldErrorComponent, TranslateModule]
})
export class SendToInvoiceComponent implements OnInit {

  @Input() QRScan: boolean;
  @Input() form: FormGroup;
  @Input() invoiceSeriesList: InvoiceSerieModel[]
  @Output() sendToInvoice = new EventEmitter()

  constructor() { }

  ngOnInit(): void {}

  onSendToInvoice() {
    this.sendToInvoice.emit();
  }
}
