<div class="spacer-1"></div>
<form [formGroup]="form" appFormValidator>
    <div class="form-row">
        <div class="col-md-8">
            <mat-form-field class="customized">
                <mat-label for="name">{{ 'Name' | translate }}</mat-label>
                <input type="text" matInput id="name" autocomplete="off" formControlName="name" required>
                <mat-error>
                    <app-form-field-error [control]="form.controls.name"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="customized">
                <mat-label for="name">{{ 'AppKey' | translate }}</mat-label>
                <input type="text" matInput id="app_key" autocomplete="off" formControlName="app_key" required>
                <mat-error>
                    <app-form-field-error [control]="form.controls.app_key"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-4">
            <mat-form-field class="customized">
                <mat-label for="address">{{ 'Address' | translate }}</mat-label>
                <input type="text" matInput id="address" autocomplete="off" formControlName="address">
                <mat-error>
                    <app-form-field-error [control]="form.controls.address"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="customized">
                <mat-label for="city">{{ 'City' | translate }}</mat-label>
                <input type="text" matInput id="city" autocomplete="off" formControlName="city">
                <mat-error>
                    <app-form-field-error [control]="form.controls.city"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-4">
            <mat-form-field class="customized">
                <mat-label for="phone_fax">{{ 'PhoneFax' | translate }}</mat-label>
                <input type="text" matInput id="phone_fax" autocomplete="off" formControlName="phone_fax">
                <mat-error>
                    <app-form-field-error [control]="form.controls.phone_fax"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="customized">
                <mat-label for="mobile1">{{ 'Mobil' | translate }} 1</mat-label>
                <input type="text" matInput id="mobile1" autocomplete="off" formControlName="mobile1">
                <mat-error>
                    <app-form-field-error [control]="form.controls.mobile1"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="customized">
                <mat-label for="mobile2">{{ 'Mobil' | translate }} 2</mat-label>
                <input type="text" matInput id="mobile2" autocomplete="off" formControlName="mobile2">
                <mat-error>
                    <app-form-field-error [control]="form.controls.mobile2"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-6">
            <mat-form-field class="customized">
                <mat-label for="email1">{{ 'Email' | translate }} 1</mat-label>
                <input type="text" matInput id="email1" autocomplete="off" formControlName="email1">
                <mat-error>
                    <app-form-field-error [control]="form.controls.email1"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="customized">
                <mat-label for="email2">{{ 'Email' | translate }} 2</mat-label>
                <input type="text" matInput id="email2" autocomplete="off" formControlName="email2">
                <mat-error>
                    <app-form-field-error [control]="form.controls.email2"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-3">
            <mat-form-field class="customized">
                <mat-label for="website">{{ 'Website' | translate }}</mat-label>
                <input type="text" matInput id="website" autocomplete="off" formControlName="website">
                <mat-error>
                    <app-form-field-error [control]="form.controls.website"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="customized">
                <mat-label for="facebook">Facebook</mat-label>
                <input type="text" matInput id="facebook" autocomplete="off" formControlName="facebook">
                <mat-error>
                    <app-form-field-error [control]="form.controls.facebook"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="customized">
                <mat-label for="linkedin">LinkedIn</mat-label>
                <input type="text" matInput id="linkedin" autocomplete="off" formControlName="linkedin">
                <mat-error>
                    <app-form-field-error [control]="form.controls.linkedin"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="customized">
                <mat-label for="instagram">Instagram</mat-label>
                <input type="text" matInput id="instagram" autocomplete="off" formControlName="instagram">
                <mat-error>
                    <app-form-field-error [control]="form.controls.instagram"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="mb-0">
        <div class="w-100 modal-footer border-0 p-0 pt-4">
            <button type="button" class="btn btn-secondary pull-right" (click)="closeModal.emit()">{{ 'Close' |
                translate }}</button>
            <button class="btn btn-primary pull-right" (click)="saveAndExit()">{{ 'SaveAndClose' | translate }}</button>
            <button class="btn btn-primary pull-right" (click)="save()">{{ 'Save' | translate }}</button>
        </div>
    </div>
</form>