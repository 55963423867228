<mat-dialog-content class="pt-5">
    <div class="d-flex justify-content-center align-items-center mb-4">
        <i class="material-icons text-success fs-5">credit_score</i>
    </div>
    <div class="text-center">
        <h4 class="font-weight-normal">{{"RegisterPayment" | translate}} {{"For" | translate | lowercase }} 
            <span class="font-weight-bold custom-black">{{ data.asset }}</span>
        </h4>
        <h4 class="register-payment-title font-weight-normal">
            <span class="font-weight-bold custom-black">{{totalQuantity}}</span> 
            {{"Products" | translate | lowercase}}, {{"TotalValueOf" | translate | lowercase}} 
            <span class="font-weight-bold custom-black">{{data.value.toFixed(2)}} {{settingsService.currency$ | async}}</span>
        </h4>
        <form [formGroup]="form" appFormValidator class="mt-3 w-75 mx-auto">
            <div class="">
                <mat-form-field class="customized">
                    <mat-label>{{ 'SelectPaymentMethod' | translate }}</mat-label>
                        <mat-select matSelect disableOptionCentering [formControl]="form.controls.type">
                            <mat-option value="cash" id="cash">{{ 'cash' | translate}}</mat-option>
                            <mat-option value="card" id="card">{{ 'card' | translate}}</mat-option>
                        </mat-select>
                    <mat-error>
                        <app-form-field-error [control]="form.controls.type"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="mb-0 px-0">
        <div class="w-100 modal-footer border-0 p-0 pt-4">
            <button type="button" class="btn btn-primary pull-right mr-2" (click)="confirmProducts()">{{ 'RegisterThePayment' | translate }}</button>
            <button type="button" class="btn btn-secondary pull-right" (click)="dialogRef.close()">{{ 'Cancel' | translate }}</button>
        </div>
    </div>
</mat-dialog-content>

