import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { CategoryModel } from '../models/category.model';
import { HttpParams } from '@angular/common/http';
import { getLang } from 'src/app/shared/utils/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CategoriesService extends BaseService {
    itemsList$: BehaviorSubject<CategoryModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<CategoryModel> = new Subject();

    constructor(
        protected _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    protected getRequestOptions() {
        return { params: new HttpParams().set("locale", getLang()) };
    }

    createCategory(category: CategoryModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.createResorce(apiEndpoints.categories, category).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create category', error)

        });
    }

    getCategory(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.categories}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get category', error)
        });
    };

    getCategories(filter?: string, paginationSettings?: string) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(apiEndpoints.categories + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get categories list', error)
        });
    }

    getCategories$(): Observable<any> {
        return this.getResorce(apiEndpoints.categories + '/dropdown-values');
    }

    updateCategory(category: CategoryModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.categories + '/' + category.id, category).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data)
            },
            error: error => this.reportError('Error on update category', error)
        });
    }

    deleteCategory(category) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitDeleteRequest(apiEndpoints.categories + '/' + category.id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this.getCategories();
            },
            error: error => this.reportError('Error on delete category', error)
        });
    }
}
