
export function parseQueryParams(params: any) {
    const paramsKeys = Object.keys(params);
    let queryParams = paramsKeys.length ? '?' : '';

    paramsKeys.forEach((param, i) => {
        if (Array.isArray(param)) {
            params[param].shift();
            const subParam = [...params[param]];
            queryParams += `${params[param]}=${[subParam]}`
        }
        params[param] && (queryParams += `${param}=${params[param]}${i < paramsKeys.length - 1 ? '&' : ''}`)
    })
    queryParams.slice(-1) === '&' && (queryParams = queryParams.substring(0, queryParams.length - 1));
    return queryParams;
}