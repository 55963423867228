<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
         <small>{{"Allergens" | translate}}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12 pb-3">
        <h1 class="mb-0 dialog-title"
            *ngIf="selectedAllergen">
            {{'Edit' | translate}}:
            <span> {{ selectedAllergen?.name }} </span>
            <ng-template #namePlaceholder>-</ng-template>
        </h1>
        <h1 class="mb-0 dialog-title" *ngIf="!selectedAllergen"> {{'AddAllergen' | translate}}</h1>
    </div>

</div>

<mat-dialog-content>
    <div class="position-relative">
        <app-preloader></app-preloader>
        <form [formGroup]="form" appFormValidator>
            <div class="row mt-4 justify-content-end">
                <div class="col-4 col-md-2">
                    <app-content-language-dropdown (changeLang)="changeLang()"></app-content-language-dropdown>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-3">
                    <mat-form-field class="customized">
                        <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                        <input type="text" matInput id="name" autocomplete="off" formControlName="name">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['name']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

<app-dialog-footer-buttons class="w-100"
    (close)="dialogRef.close()"
    (saveAndClose)="saveAndExit()"
    (save)="onSubmit()">
</app-dialog-footer-buttons>
</mat-dialog-content>