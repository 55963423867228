import { Pipe, PipeTransform } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { filter } from "rxjs";
import { SettingsService } from "src/app/modules/settings/services/settings.service";
@UntilDestroy()
@Pipe({
    name: "priceFormating",
    standalone: true
})

export class PriceFormatingPipe implements PipeTransform {
    public currency;
    constructor(private _settingsService: SettingsService) {
        this._settingsService.currency$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.currency = data.value)
    }


    transform(item: any, displayCurrency?: boolean): any {
        if (item) {
            return `${parseFloat(item).toFixed(2)} ${displayCurrency ? (' ' + this.currency) : ''}`;
        }
        return '-'
    }
}