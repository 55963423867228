import { Component, ViewChildren } from '@angular/core';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { OrderModel } from './models/order.model';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { OrdersService } from './services/oerders.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';
import { PageEvent } from '@angular/material/paginator';
import { booleanOptions$ } from 'src/app/shared/constants';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { AssetsService } from '../assets/services/assets.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { OpenTableAssetModalComponent } from '../dashboard/dashboard-assets/table-assets/open-table-asset-modal/open-table-asset-modal.component';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { TableFiltersComponent as TableFiltersComponent_1 } from '../../shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { CommonModule, NgIf, UpperCasePipe } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css'],
    standalone: true,
    imports: [NgIf, TableFiltersComponent_1, RegularTableComponent, UpperCasePipe, TranslateModule, CommonModule]
})
export class OrdersComponent {
    public PermissionsEnum = PermissionsEnum;
    public itemsList: OrderModel[];
    public itemsData: PaginationModel;
    public parsedFilters = '';
    public assetsDropdownPayload = {
        table_name: 'Assets',
        page: 1,
        records_number: 100,
        "fields[assets]": "id,name"
    };

    public queryParams = {
        page: 1,
        records_number: 10
    };


    public filters: TableFilter[] = [
        {
            resourceName: 'asset_type',
            label: 'AssetType',
            type: TableFilterTypesEnum.SELECT,
            controls: ['asset.asset_type_id'],
            firstOptionMessage: 'SelectAssetType',
            style: 'col-md-3',
            dropdownId: 'asset_types',
            placeholders: [''],
            optionsList$: this._assetsService.getAssetTypes$()
        },
        {
            resourceName: 'asset',
            label: 'Asset',
            type: TableFilterTypesEnum.SELECT,
            controls: ['asset_id'],
            firstOptionMessage: 'SelectAsset',
            style: 'col-md-3',
            dropdownId: 'assets',
            placeholders: [''],
            optionsList$: this._assetsService.chainedList$
        },
        {
            resourceName: 'interval',
            label: 'Date',
            type: TableFilterTypesEnum.DATE_RANGE,
            controls: ['start_date', 'end_date'],
            style: 'col-md-4',
            defaultRange: { start: '', end: '' }
        },
        {
            resourceName: 'is_open',
            label: 'Status',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['is_open'],
            style: 'col-md-2',
            firstOptionMessage: 'All',
            dropdownId: 'is_open',
            optionsList$: booleanOptions$(this._translateService.instant('Open-f'), this._translateService.instant('Closed-f'), null, null)
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Date',
            fieldNames: ['created_at'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.DATE_HOUR_FORMAT_WITH_MULTIPLE_PROPS,
        },
        {
            name: 'AssetName',
            fieldNames: ['asset.name'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.ASSET_NAME
        },
        {
            name: 'AssetType',
            fieldNames: ['asset.asset_type.name'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.ASSET_TYPE,
        },
        {
            name: 'Status',
            fieldNames: ['is_open'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.OPEN_CLOSED,
        },
        {
            name: 'OrderValue',
            fieldNames: ['total'],
            cssClasses: 'all text-right',
            searchable: true,
            sorting: true,
            cellType: TableCellType.PRICE,
            total: ['total']
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'View',
            type: TableActionTypeEnum.View,
            permission: PermissionsEnum.ORDERS_READ
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.ORDERS_DELETE
        }
    ];

    @ViewChildren('table') table: TableFiltersComponent;


    constructor(public ordersService: OrdersService,
        private _dialogService: DialogService,
        private _sweetAlertService: SweetAlertService,
        private _assetsService: AssetsService,
        private _translateService: TranslateService
    ) {
        this._assetsService.getChainedResourceList(this.assetsDropdownPayload);
        this.ordersService.itemsData$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsData = data)
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void { }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.View) {
            this.openEditModal(e.entry);
        }
        if (e.type === TableActionTypeEnum.Delete) {
            this._sweetAlertService.showSwal('warning-message', () => this.ordersService.deleteOrder(e.entry), "Order");
        }
    }

    private openEditModal(entry: OrderModel | null) {
        this._dialogService.openModal(entry, OpenTableAssetModalComponent, DimensionsEnum.AUTO);
    }

    changeLang() {
        this.ordersService.getOrders(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    updatePage(event: PageEvent) {
        this.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this.ordersService.getOrders(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    applyFilters(filters: ApplyFiltersModel) {
        this.parsedFilters = filters.parsedFilters;
        this.queryParams = filters.queryParams;
        this.ordersService.getOrders(this.parsedFilters, parseQueryParams(filters.queryParams));
    }
}
