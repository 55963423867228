import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BrandInvoiceProfileModel, InvoiceSerieModel } from 'src/app/modules/brand-invoice-profile/models/brand-invoice-profile.model';
import { BrandInvoiceProfileService } from 'src/app/modules/brand-invoice-profile/services/brand-invoice-profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { NoItemToShowComponent } from '../../../../../../shared/components/no-item-to-show/no-item-to-show.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgIf, NgFor, UpperCasePipe } from '@angular/common';
import { GradientBorderTitleComponent } from '../../../../../../shared/components/gradient-border-title/gradient-border-title.component';

@Component({
    selector: 'app-invoice-series-list',
    templateUrl: './invoice-series-list.component.html',
    styleUrls: ['./invoice-series-list.component.css'],
    standalone: true,
    imports: [GradientBorderTitleComponent, NgIf, NgFor, MatSlideToggleModule, NoItemToShowComponent, UpperCasePipe, TranslateModule]
})
export class InvoiceSeriesListComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  public invoiceSeriesList: InvoiceSerieModel[];
  public currentActiveInvoiceSerie: number;

  @Input() data: BrandInvoiceProfileModel;

  constructor(private _brandInvoiceProfileService: BrandInvoiceProfileService) { }

  ngOnInit(): void {
    this.data && this._brandInvoiceProfileService.getInvoiceSeries(this.data?.id);
    this._subscription = this._brandInvoiceProfileService.invoiceSeriesList$.pipe(filter(invoiceSeries => !!invoiceSeries)).subscribe(invoiceSeries => {
      this.invoiceSeriesList = invoiceSeries;
      this.currentActiveInvoiceSerie = this.invoiceSeriesList.find(invoiceSerie => invoiceSerie.active)?.id;
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
    this._brandInvoiceProfileService.invoiceSeriesList$.next(undefined);
  }

  activateInvoiceSerie(invoiceSerieId: number) {
    this.currentActiveInvoiceSerie = invoiceSerieId;
    this._brandInvoiceProfileService.activateInvoiceSerie(invoiceSerieId, this.data.id);
  }
}
