<app-preloader></app-preloader>
<div class="px-0 py-0">
    <div class="mt-lg-0 mb-md-0">
        <div class="my-0 position-relative background-white border-radius-card card-shadow-box">
            <div class="pt-3 px-3">
                <form [formGroup]="form" appFormValidator class="">
                    <div class="form-row align-items-center">
                        <div class="col-7">
                            <mat-form-field class="customized">
                                <mat-label>{{ "Customers" | translate }}</mat-label>
                                <mat-select matSelect (selectionChange)="changeOption($event)"
                                    disableOptionCentering formControlName="customer_id">
                                    <mat-option *ngFor="let item of tableCustomers; index as i" [value]="item.id" [name]="item.name? item.name : 'Customer ' + (i+1)"
                                        [id]="item.id">{{ item.name? item.name : 'Customer ' + (i+1) }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <app-form-field-error [control]="form.controls.customer"></app-form-field-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-5 d-flex justify-content-end">
                            <button class="btn button-accent-custom" (click)="addCustomer()">{{"AddNewCustomer" | translate}}</button>
                        </div>
                    </div>
                    <div class="add-product-to-customer pb-3" *ngIf="form.controls.customer_id.value">
                        <div class="form-row d-flex">
                            <div class="col-7">
                                <app-dropdown-with-search 
                                [control]="form.controls.product_id" 
                                [label]="'SelectProduct'"
                                [firstOptionMessage]="'SelectProduct'"
                                [itemsList]="productsList()"
                                [cypressSelector]="'products'">
                                </app-dropdown-with-search>
                            </div>
                            <div class="offset-1 col-4">
                                <mat-form-field class="customized">
                                    <mat-label>{{"Quantity" | translate}}</mat-label>
                                    <input matInput type="number" id="quantity" step="1" class="pb-2" (focus)="$event.target.select()"
                                        [formControlName]="'quantity'">
                                </mat-form-field>
                            </div>
                        </div>
                
                        <div class="form-row">
                            <div class="col-12">
                                <mat-form-field class="customized">
                                    <mat-label for="observations" class="observations-label font-custom-normal small">{{ 'Observations' | translate }}:</mat-label>
                                    <textarea type="textarea" matInput id="observations" rows="2" class="font-custom-normal" [formControlName]="'observations'">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-primary" (click)="addOrderProduct()">{{"AddOrderProduct" | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>