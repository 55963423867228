import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { getLang } from 'src/app/shared/utils/utils';
import { ProductsService } from './product.service';

@Injectable({
    providedIn: 'root'
})

export class ProductsAllergensService extends BaseService {

    constructor(
        protected _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,
        private _productsService: ProductsService) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    protected getRequestOptions() {
        return { params: new HttpParams().set("locale", getLang()) };
    }

    addProductAllergen(payload: any) {
        this._appSettingsSerice.isLoading.next(true);
        this.createResorce(apiEndpoints.productAllergens, payload).subscribe({
            next: () => {
                this._productsService.getProductAllergens(payload.product_id)
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error add product allergen', error)

        });
    }

    deleteProductAllergen(productAllergen) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitDeleteRequest(apiEndpoints.productAllergens + '/' + productAllergen.id).subscribe({
            next: () => {
                this._productsService.getProductAllergens(productAllergen.product_id)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on delete product allergen', error)
        });
    }
}
