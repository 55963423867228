import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { CustomerModel } from 'src/app/modules/customers/models/customer.model';
import { CustomersService } from 'src/app/modules/customers/services/customers.service';
import { OrderProductsService } from 'src/app/modules/orders/services/order-products.service';
import { ProductsService } from 'src/app/modules/products/services/product.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AddCostumerConfirmationModalComponent } from '../add-costumer-confirmation-modal/add-costumer-confirmation-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { DropdownWithSearchComponent } from '../../../../../shared/components/dropdown-with-search/dropdown-with-search.component';
import { FormFieldErrorComponent } from '../../../../../shared/components/form-field-error/form-field-error.component';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../../shared/components/preloader/preloader.component';
@UntilDestroy()
@Component({
    selector: 'app-add-customer',
    templateUrl: './add-customer.component.html',
    styleUrls: ['./add-customer.component.css'],
    standalone: true,
    imports: [PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, FormFieldErrorComponent, NgIf, DropdownWithSearchComponent, MatInputModule, TranslateModule]
})
export class AddCustomerComponent implements OnInit {

    public productsList = this._productsService.chainedList;

    public chainedProductsPayload = {
        "filter[is_active]": true,
        page: 1,
        records_number: 100,
        table_name: 'Product',
        "fields[products]": 'id,name',
    }
    @Input() tableCustomers: CustomerModel[]
    @Input() assetId: number;
    @Input() orderId: number;
    public form: FormGroup;

    constructor(private _formBuilder: FormBuilder,
        private _productsService: ProductsService,
        private _orderProductsService: OrderProductsService,
        private _customersService: CustomersService,
        private _dialogService: DialogService
    ) {
        this._productsService.getChainedProductsGeneralResource(this.chainedProductsPayload);
    }
    ngOnInit(): void {
        this.form = this._formBuilder.group({
            asset_id: this.assetId,
            customer_id: 0,
            product_id: 0,
            quantity: 1,
            observations: ''
        });
        this._orderProductsService.onSaveSuccess$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(() => this.form.patchValue({
            product_id: 0,
            quantity: 1,
            observations: ''
        }))

    }

    changeOption(e) {
        this.form.patchValue({
            product_id: 0,
            quantity: 1,
            observations: ''
        })
    }

    addCustomer() {
        const modalRef = this._dialogService.openModal((this.tableCustomers?.length || 0), AddCostumerConfirmationModalComponent, DimensionsEnum.AUTO);
        modalRef.componentInstance.onCreateCustomer.subscribe(event => this.createCustomer(event, modalRef));
    }

    createCustomer(name, dialogRef) {
        this._customersService.addCustomer$(name).subscribe({
            next: (resp) => {
                this.tableCustomers ? this.tableCustomers.push(resp.data) : this.tableCustomers = [resp.data];
                this.form.patchValue({ customer_id: resp.data.id });
                dialogRef.close();
            },
            error: (err) => console.log(err)
        })
    }

    addOrderProduct() {
        this._orderProductsService.addProductToOrder(this.form.value, this.orderId);
    }

}
