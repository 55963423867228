import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'AllergensCounter',
    standalone: true
})
export class AllergensCounterPipe implements PipeTransform {

    transform(value: unknown): unknown {
        return value['product_allergens']?.length
    }

}
