import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CategoriesService } from '../categories/services/category.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, fromEvent, map, switchMap, take, timer } from 'rxjs';
import { BrandProfileModel } from '../brand-profile/models/brand-profile.model';
import { BrandProfileService } from '../brand-profile/services/brand-profile.service';
import { ProductsService } from '../products/services/product.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { TypesService } from '../types/services/type.service';
import { SettingsService } from '../settings/services/settings.service';
import { ActivatedRoute, RouterLinkActive } from '@angular/router';
import { AssetsService } from '../assets/services/assets.service';
import { AssetModel } from '../assets/models/assets.model';
import { TypeModel } from '../types/models/type.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { OrderProductsService } from '../orders/services/order-products.service';
import { CustomersService } from '../customers/services/customers.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { WelcomePageComponent } from 'src/app/shared/components/welcome-page/welcome-page.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { ShoppingListComponent } from '../orders/components/shopping-list/shopping-list.component';
import { LangService } from 'src/app/shared/services/lang.service';
import { CustomerRequestsComponent } from '../customer-requests/customer-requests.component';
import { CustomerRequestsService } from '../customer-requests/services/customer-requests.service';
import { CallWaiterComponent } from '../orders/components/call-waiter/call-waiter.component';
import { ShoppingCartComponent } from '../orders/components/shopping-cart/shopping-cart.component';
import { ScrollToTopComponent } from '../../shared/components/scroll-to-top/scroll-to-top.component';
import { QrMenuProductComponent } from './qr-menu-product/qr-menu-product.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AsyncPipe, UpperCasePipe, CommonModule } from '@angular/common';
import { SidebarComponent } from '../../shared/components/sidebar/sidebar.component';
import { PreloaderComponent } from 'src/app/shared/components/preloader/preloader.component';

@UntilDestroy()
@Component({
    selector: 'app-qr-scan',
    templateUrl: './qr-scan.component.html',
    styleUrls: ['./qr-scan.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SidebarComponent,
        RouterLinkActive,
        ReactiveFormsModule,
        FormsModule,
        QrMenuProductComponent,
        ScrollToTopComponent,
        ShoppingCartComponent,
        CallWaiterComponent,
        AsyncPipe,
        UpperCasePipe,
        TranslateModule,
        PreloaderComponent,
        CommonModule
    ],
})

export class QrScanComponent implements OnInit, OnDestroy, AfterViewInit {
    public slideSearch;
    public currentBrand: BrandProfileModel;
    public categoriesList;
    public availableCategories = [];
    public filters = '&filter[is_active]=true';
    public queryParams = {
        page: 1,
        records_number: 10
    };


    // products (products service)
    public productsList = this._productsService.chainedProductsList;

    // generic (base service)
    // public productsList = this._productsService.chainedList;



    public structuredList;
    public filteredList;
    public search = false;
    public textSearch;
    public languages;
    public id: string;
    productsList$ = toObservable(this.productsList);
    public selectedAsset: AssetModel;
    public typesList: TypeModel[];
    public showScroll$: Observable<boolean>;
    public searchProductsCount = 0;
    public hasOrderId: Subscription;
    public productsLoaded;

    public chainedProductsPayload = {
        "filter[is_active]": true,
        page: 1,
        records_number: 100,
        model_name: 'Product',
        include: 'category,measurementUnit,productAllergens',
        "fields[products]": 'id,name,category_id,measurement_unit_id,type_id',
        "fields[categories]": "id,name",
        "fields[measurement_units]": 'id,name',
        "fields[types]": 'id,name',
        "fields[allergens]": 'id,name',
    }

    private languageSubject = new BehaviorSubject<string>('en');
    language$ = this.languageSubject.asObservable();
    combinedData$: Observable<any>;

    @ViewChild('wrapper') wrapper: ElementRef;

    constructor(
        public translateService: TranslateService,
        private _categoriesService: CategoriesService,
        private _brandProfileService: BrandProfileService,
        private _productsService: ProductsService,
        private _typesService: TypesService,
        private _settingsService: SettingsService,
        private _route: ActivatedRoute,
        private _assetsService: AssetsService,
        private _lsService: LocalStorageService,
        private _orderProductsService: OrderProductsService,
        private _customersService: CustomersService,
        private _dialogService: DialogService,
        public langService: LangService,
        private _customerRequestService: CustomerRequestsService,
        private cd: ChangeDetectorRef
    ) {
        this._orderProductsService.orderProductsChanged$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(() => {
            this.cd.markForCheck();
            this._assetsService.getAsset$(this.id).pipe(take(1)).subscribe(res => {
                this._assetsService.selectedItem$.next(res.data);
            })
        });

        this._route.params.pipe(untilDestroyed(this)).subscribe(params => {
            if (params.id) {
                this.id = params['id'];
                const modalRef = this._dialogService.openModal(params['id'], WelcomePageComponent, '100vh', '100vw', 'fullWidth');
                modalRef.componentInstance.changeLang.subscribe(event => this.switchLang(event));
                this.hasOrderId = timer(0, 20000).pipe(
                    switchMap(() => this._assetsService.getAsset$(params['id']))
                ).subscribe(res => {
                    this._assetsService.selectedItem$.next(res.data);
                })
            } else {
                this._productsService.getChainedProducts(this.filters, this.queryParams);
            };

        });
    }

    @HostListener('click', ['$event'])
    clickout(event) {
        let sidebar = document.getElementById("mySidebar");
        let isVisible = sidebar.clientWidth > 0;
        if (!isVisible) {
            return
        } else {
            if (!sidebar.contains(event.target)) {
                this.closeNav()
            }
        }
    }

    ngOnInit() {
        this._assetsService.selectedItem$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.cd.markForCheck();
            if (data.order?.id && !this.selectedAsset?.order?.id) this._orderProductsService.getOrderProducts({ id: data.order?.id, customer_id: JSON.parse(this._lsService.getValue('customerData'))?.id || null })
            this.selectedAsset = data;
            !data.customer_request && this._customerRequestService.selectedItem$.next(undefined)
        })

        this._brandProfileService.currentBrandProfile$.pipe(filter(currentBrand => !!currentBrand), untilDestroyed(this))
            .subscribe(currentBrand => this.currentBrand = currentBrand);
        this._settingsService.suportedLocales$.pipe(untilDestroyed(this)).subscribe(data => this.languages = data);
        this._orderProductsService.addOrderProductError$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.cd.markForCheck();
            if (data.error.error.errors.customer_id) {
                this._customersService.addCustomerWhenNotInLS({ name: null }, data.orderProduct);
            }
        })
        this._settingsService.getSettings();
        this._brandProfileService.getCurrentBrandProfile();
        this._settingsService.getSupportedLocales();

        // request at products endpoint

        //request at generic resource
        // this._productsService.getChainedProductsGeneralResource(this.chainedProductsPayload);


        this.combinedData$ = this.language$.pipe(
            switchMap(variable =>
                combineLatest([this.productsList$, this._categoriesService.getCategories$(), this._typesService.getTypesList$(), variable])
            )
        );

        this.combinedData$.subscribe(([products, categories, types]) => {
            this.availableCategories = []
            let organizedProductsList = {}
            this.categoriesList = categories.data;
            this.typesList = types.data;
            for (let type of types.data) {
                organizedProductsList[type.name] = {}
            }
            for (let product of products) {
                let type = types.data.find(type => product.type_id === type.id);
                let category = categories.data.find(category => product.category_id === category.id);
                if (!organizedProductsList[type.name][category.name]) {
                    organizedProductsList[type.name][category.name] = [product];
                    !this.availableCategories.find(cat => cat.id === category.id) && this.availableCategories.push(category);
                    this.productsLoaded = true;
                } else {
                    organizedProductsList[type.name][category.name].push(product);
                }
            }

            this.structuredList = { ...organizedProductsList }
        });
    };

    ngOnDestroy(): void {
        this.hasOrderId?.unsubscribe();
    }

    ngAfterViewInit() {
        this.showScroll$ = fromEvent(
            this.wrapper.nativeElement,
            'scroll'
        ).pipe(
            map(() => {
                this.getActivePill()
                return this.wrapper.nativeElement.scrollTop > 0
            })
        );

    }

    checkVisible(elm) {
        var rect = elm?.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect?.bottom < 0 || rect?.top - viewHeight >= 0);
    }

    getActivePill() {
        for (let category of this.availableCategories) {
            if (this.checkVisible(document.getElementById(`category${category.id}`))) {
                let previous = document.getElementsByClassName('active-pill')[0].id

                let pills = document.querySelectorAll('.category-pill');
                pills.forEach(pill => pill.classList.remove('active-pill'))
                let activePill = document.getElementById('category-pill-' + category.id)
                activePill.classList.add('active-pill')

                break;
            }
        }
    }

    openNav() {
        document.getElementById("mySidebar").classList.remove('nav-closed');
        document.getElementById("mySidebar").classList.add('nav-open');
    }

    closeNav() {
        document.getElementById("mySidebar").classList.remove('nav-open');
        document.getElementById("mySidebar").classList.add('nav-closed');
    }

    toggleSearch() {
        this.search = !this.search;
        this.search && setTimeout(() => document.getElementById("inputSearch").focus(), 0)
    }

    scrollTo(el: any) {
        document.getElementById(`category${el.id}`).scrollIntoView({ behavior: 'smooth', block: "end", inline: "center" })
    }

    filterResults(text: string) {
        this.textSearch = text;
        if (!text) {
            this.filteredList = { ...this.structuredList };
            return;
        }

        const filteredData = {};

        let count = 0
        for (let key in this.structuredList) {
            filteredData[key] = {};
            for (let prop in this.structuredList[key]) {
                filteredData[key][prop] = this.structuredList[key][prop].filter(product => product?.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")));
                count += filteredData[key][prop].length;
            }
        }
        this.searchProductsCount = count;
        this.filteredList = filteredData;
    }

    onScrollToTop(): void {
        this.wrapper.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }

    addOrderProduct(product) {
        let payload = {
            asset_id: this.id,
            customer_id: JSON.parse(this._lsService.getValue('customerData'))?.id || null,
            product_id: product.product_id,
            quantity: product.quantity,
            observations: product.observations,
            order_id: this.selectedAsset?.order?.id
        }

        if (this._lsService.getValue('customerData')) {
            this._orderProductsService.addProductToOrder(payload);
            return;
        }
        this._customersService.addCustomerWhenNotInLS({ name: null }, payload)
    }

    openShoppingList(val) {
        let payload = {
            asset: this.selectedAsset,
            customer_id: JSON.parse(this._lsService.getValue('customerData'))?.id || null,
            has_order_id: this.hasOrderId
        }

        const modalRef = this._dialogService.openModal(payload, ShoppingListComponent, DimensionsEnum.AUTO, '95vw', 'rounded-corners-modal');
        modalRef.afterClosed().subscribe(() => this.hasOrderId = timer(0, 20000).pipe(
            switchMap(() => this._assetsService.getAsset$(this.id))
        ).subscribe(res => {
            this._assetsService.selectedItem$.next(res.data);
        }));
        val && this.hasOrderId.unsubscribe();
    }

    switchLang(lang: string) {
        localStorage.setItem("locale", lang);
        this._productsService.getChainedProducts(this.filters, this.queryParams);
        this.languageSubject.next(lang);
        this.langService.setLang(lang);
    }

    callWaiter() {
        let payload =
        {
            asset_id: this.selectedAsset?.id,
            order_id: this.selectedAsset?.order?.id,
            customer_id: JSON.parse(this._lsService.getValue('customerData'))?.id || null
        }
        this._dialogService.openModal(payload, CustomerRequestsComponent, DimensionsEnum.AUTO, '95vw', 'rounded-corners-modal');
    }

    focusOutSearch(e) {
        if (!e.target.value) {
            this.search = false;
        }
    }

    identifyLanguage(index, language) {
        return index
    }
    identifyCategory(index, category) {
        return index
    }
}