import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { PaymentModel } from '../../models/payment.model';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PaymentsService } from '../../services/payments.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { validateAllFormFields } from 'src/app/shared/utils/form.utils';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
@UntilDestroy()
@Component({
    selector: 'app-payments-modal',
    templateUrl: './payments-modal.component.html',
    styleUrls: ['./payments-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatSelectModule, MatOptionModule, FormFieldErrorComponent, DialogFooterButtonsComponent, AsyncPipe, LowerCasePipe, TranslateModule]
})
export class PaymentsModalComponent extends BaseModalComponent {
    public form: FormGroup;
    public asset: string

    constructor(
        public dialogRef: MatDialogRef<PaymentsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PaymentModel,
        private _formBuilder: FormBuilder,
        private _paymentsService: PaymentsService,
        public settingsService: SettingsService
    ) {
        super(dialogRef, _paymentsService);
        this.asset = data.order_products[0].order.asset.name
        this.form = this._formBuilder.group({ type: data.type });
        this._paymentsService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close())
    }


    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        this._paymentsService.updatePayment(this.data.id, this.form.value);
    }
}
