import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class BadgeComponent {

    @Input() message: string;
    @Input() textColor: string;
    @Input() badgeColor: string;

    constructor() { }
}
