import { Component, Input} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.css'],
    standalone: true,
    imports: [MatTooltipModule, NgStyle, TranslateModule]
})
export class TooltipComponent {

  @Input() message: string;
  @Input() position: string;
  @Input() icon: string;
  @Input() iconColor: string;

  constructor() { }
}
