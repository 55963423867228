import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
    selector: 'app-dropdown-with-multiselect',
    templateUrl: './dropdown-with-multiselect.component.html',
    styleUrls: ['./dropdown-with-multiselect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatOptionModule, NgxMatSelectSearchModule, MatCheckboxModule, FormsModule, NgFor, TranslateModule]
})
export class DropdownWithMultiSelectComponent implements OnInit, OnChanges {
    public filteredItemsList: any[];
    public filterFormControl = new FormControl('');
    public allSelected = false;

    @Input() control: FormControl;
    @Input() cypressSelector: string;
    @Input() itemsList: any[];
    @Input() customField: string;
    @Output() changeOption: EventEmitter<number[]> = new EventEmitter<any>();
    @ViewChild('select') select: MatSelect;

    constructor() { }

    ngOnInit(): void {
        this.filterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(changes =>
            this.filteredItemsList = this.itemsList
                .filter(item => (item.name || item.first_name + ' ' + item.last_name || item.code || item[this.customField])
                    .toLowerCase()
                    .includes(changes.toLowerCase())));
        this.control.valueChanges.pipe(untilDestroyed(this))
            .subscribe(changes => this.control.setValue(changes.filter(e => e !== undefined), { emitEvent: false }));
        this.filterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.toggleSingleSelection());
    }

    ngOnChanges(changes: SimpleChanges) {
        const list = changes.itemsList.currentValue;
        this.itemsList = list;
        this.filteredItemsList = list;
        this.control.setValue([]);
    }

    onSelectionChange(values: number[]): void {
        this.changeOption.emit(values.filter(e => e !== undefined));
    }

    toggleAllSelection() {
        this.allSelected ?
            this.select.options.forEach((item: MatOption) => item.select()) :
            this.select.options.forEach((item: MatOption) => item.deselect());
    }

    toggleSingleSelection() {
        this.allSelected = (this.filteredItemsList.length === this.control.value.filter(e => e !== undefined).length) ? true : false;
    }
}
