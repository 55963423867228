import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { NgIf, NgFor } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
    selector: 'app-dropdown-with-search',
    templateUrl: './dropdown-with-search.component.html',
    styleUrls: ['./dropdown-with-search.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatOptionModule, NgxMatSelectSearchModule, NgIf, NgFor, FormFieldErrorComponent, TranslateModule]
})
export class DropdownWithSearchComponent implements OnInit, OnChanges {
    public filteredItemsList: any[];
    public filterFormControl = new FormControl('');

    @Input() control: FormControl;
    @Input() firstOptionMessage: string = undefined;
    @Input() cypressSelector: string;
    @Input() itemsList: any[];
    @Input() customField: string;
    @Input() label: string;
    @Input() data_cy: string;
    @Output() changeOption: EventEmitter<number> = new EventEmitter<any>();

    constructor(private _ref: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.filterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(changes =>
            this.filteredItemsList = this.itemsList?.filter(item => (item.name || item.first_name + ' ' + item.last_name || item.code)
                .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                .includes(changes.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))));
        this.control.statusChanges.pipe(delay(0), untilDestroyed(this)).subscribe(() => this._ref.detectChanges());
    }

    ngOnChanges(changes: SimpleChanges) {
        const list = changes.itemsList?.currentValue;
        this._setFirstValue(list);
        this.itemsList = list;
        this.filteredItemsList = list;
    }

    private _setFirstValue(list: any[]) {
        if (!this.firstOptionMessage && !this.control?.value && list?.length) {
            this.control.setValue(list[0].id || list[0].user_id);
        }
    }
}
