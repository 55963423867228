import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { isNullOrUndefined } from '../utils/isNullOrUndefined.utils';

@Directive({
    selector: '[appTransformToIcons]',
    standalone: true
})
export class TransformToIconsDirective implements OnInit {

    @Input() appTransformToIcons: any;
    @Input() currentCell: string;

    constructor(private _elementRef: ElementRef<HTMLElement>) { }

    ngOnInit(): void {
        if (!isNullOrUndefined(this.appTransformToIcons.to_pay) && this.currentCell === 'status') {
            this.createIcon(this.appTransformToIcons.status);
        }
    }

    createIcon(status: string) {
        this._elementRef.nativeElement.classList.add('material-icons');

        switch (status) {
            case 'config':
                this._elementRef.nativeElement.innerHTML = 'lock_open';
                break;
            case 'locked':
                this._elementRef.nativeElement.innerHTML = this.appTransformToIcons.invoice_no ? 'receipt_long' : 'lock';
                break;
            default:
                break;
        }
    }
}
