import { Component, ViewChildren } from '@angular/core';
import { PaymentModel } from './models/payment.model';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableDataHeaders, TableHeadersTypeEnum } from 'src/app/shared/models/table-data-headers.model';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { PaymentsService } from './services/payments.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';
import { PageEvent } from '@angular/material/paginator';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountsService } from '../accounts/services/accounts.service';
import { booleanOptions$ } from 'src/app/shared/constants';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PaymentsModalComponent } from './components/payments-modal/payments-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { AssetsService } from '../assets/services/assets.service';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { TableFiltersComponent as TableFiltersComponent_1 } from '../../shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { NgIf, UpperCasePipe } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.css'],
    standalone: true,
    imports: [NgIf, TableFiltersComponent_1, RegularTableComponent, UpperCasePipe, TranslateModule]
})
export class PaymentsComponent {
    public PermissionsEnum = PermissionsEnum;
    public itemsList: PaymentModel[];
    public itemsData: PaginationModel;
    public parsedFilters = '';
    public queryParams = {
        page: 1,
        records_number: 10
    };
    public usersDropdownPayload = {
        table_name: 'Users',
        page: 1,
        records_number: 100,
        "fields[users]": "users.id,users.first_name,users.last_name"
    };

    public assetsDropdownPayload = {
        table_name: 'Assets',
        page: 1,
        records_number: 100,
        "fields[assets]": "id,name"
    };

    public filters: TableFilter[] = [
        {
            resourceName: 'asset',
            label: 'Asset',
            type: TableFilterTypesEnum.SELECT,
            controls: ['orderProducts.order.asset_id'],
            firstOptionMessage: 'SelectAsset',
            style: 'col-md-3',
            dropdownId: 'assets',
            placeholders: [''],
            optionsList$: this._assetsService.chainedList$
        },
        {
            resourceName: 'user',
            label: 'User',
            type: TableFilterTypesEnum.SELECT,
            controls: ['user_id'],
            firstOptionMessage: 'SelectUser',
            style: 'col-md-3',
            dropdownId: 'assets',
            placeholders: [''],
            optionsList$: this._accountsService.chainedList$
        },
        {
            resourceName: 'interval',
            label: 'Date',
            type: TableFilterTypesEnum.DATE_RANGE,
            controls: ['start_date', 'end_date'],
            style: 'col-md-4',
            defaultRange: { start: '', end: '' }
        },
        {
            resourceName: 'type',
            label: 'Type',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['type'],
            style: 'col-md-2',
            firstOptionMessage: 'All',
            dropdownId: 'is_open',
            optionsList$: booleanOptions$(this._translateService.instant('cash'), this._translateService.instant('card'), 'cash', 'card')
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Date',
            fieldNames: ['created_at'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.DATE_HOUR_FORMAT_WITH_MULTIPLE_PROPS,
        },
        {
            name: 'AssetName',
            fieldNames: ['order_products[0].order.asset.name'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.ASSET_NAME,
        },
        {
            name: 'Type',
            fieldNames: ['type'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.PAYMENT_TYPE,
        },
        {
            name: 'User',
            fieldNames: ['user.first_name', 'user.last_name'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            type: TableHeadersTypeEnum.MULTIPLE_ELEMENTS,
        },
        {
            name: 'Total',
            fieldNames: ['total'],
            cssClasses: 'all text-right',
            searchable: true,
            sorting: true,
            cellType: TableCellType.PRICE,
            total: ['total']
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.CAN_BE_EDITED
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.CAN_BE_DELETED
        }
    ];

    @ViewChildren('table') table: TableFiltersComponent;


    constructor(public paymentsService: PaymentsService,
        private _accountsService: AccountsService,
        private _sweetAlertService: SweetAlertService,
        private _translateService: TranslateService,
        private _dialogService: DialogService,
        private _assetsService: AssetsService

    ) {
        this.paymentsService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsList = data);
        this.paymentsService.itemsData$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsData = data);
        this.paymentsService.paymentsListChanged$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(() => this.paymentsService.getPayments(this.parsedFilters, parseQueryParams(this.queryParams)));
        this._accountsService.getChainedResourceList(this.usersDropdownPayload);
        this._assetsService.getChainedResourceList(this.assetsDropdownPayload);
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Delete) {
            this._sweetAlertService.showSwal('warning-message', () => this.paymentsService.deletePayment(e.entry.id), "Payment");
        }
        if (e.type === TableActionTypeEnum.Edit) {
            this._dialogService.openModal(e.entry, PaymentsModalComponent, DimensionsEnum.AUTO).afterClosed().pipe(untilDestroyed(this)).subscribe(() => this.paymentsService.getPayments(this.parsedFilters, parseQueryParams(this.queryParams)));
        }
    }

    changeLang() {
        this.paymentsService.getPayments(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    updatePage(event: PageEvent) {
        this.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this.paymentsService.getPayments(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    applyFilters(filters: ApplyFiltersModel) {
        this.parsedFilters = filters.parsedFilters;
        this.queryParams = filters.queryParams;
        this.paymentsService.getPayments(this.parsedFilters, parseQueryParams(filters.queryParams));
    }

}
