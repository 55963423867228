import { Component, OnInit, ViewChildren } from '@angular/core';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { CategoriesService } from './services/category.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { CategoryModel } from './models/category.model';
import { PageEvent } from '@angular/material/paginator';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { CategoriesModalComponent } from './components/categories-modal/categories-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { TableFiltersComponent as TableFiltersComponent_1 } from '../../shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { NgIf, UpperCasePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
@UntilDestroy()

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.css'],
    standalone: true,
    imports: [MatButtonModule, NgIf, TableFiltersComponent_1, RegularTableComponent, UpperCasePipe, TranslateModule]
})
export class CategoriesComponent implements OnInit {
    public PermissionsEnum = PermissionsEnum;
    public itemsList: CategoryModel[];
    public itemsData: PaginationModel;
    public parsedFilters = '';

    public queryParams = {
        page: 1,
        records_number: 10
    };


    public filters: TableFilter[] = [
        {
            resourceName: 'name',
            label: 'Category',
            type: TableFilterTypesEnum.TEXT,
            controls: ['name'],
            style: 'col-md-4',
            placeholders: [''],
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        },
        {
            name: 'Rank',
            fieldNames: ['rank'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        }
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.CATEGORIES_ADD_AND_EDIT
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.CATEGORIES_DELETE
        }
    ];

    @ViewChildren('table') table: TableFiltersComponent;


    constructor(public categoriesService: CategoriesService,
        private _dialogService: DialogService,
        private _sweetAlertService: SweetAlertService
    ) { }

    ngOnInit(): void {
        this.categoriesService.getCategories();
        this.categoriesService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsList = data)
        this.categoriesService.itemsData$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsData = data)
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Edit) {
            this.openEditModal(e.entry);
        }
        if (e.type === TableActionTypeEnum.Delete) {
            this._sweetAlertService.showSwal('warning-message', () => this.categoriesService.deleteCategory(e.entry), "Category");
        }
    }

    changeLang() {
        this.categoriesService.getCategories(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    addEntry() {
        this.openEditModal(null);
    }

    private openEditModal(entry: CategoryModel | null) {
        this._dialogService.openModal(entry, CategoriesModalComponent, DimensionsEnum.AUTO).afterClosed().subscribe(() => this.categoriesService.getCategories(this.parsedFilters, parseQueryParams(this.queryParams)));
    }

    updatePage(event: PageEvent) {
        this.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this.categoriesService.getCategories(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    applyFilters(filters: ApplyFiltersModel) {
        this.parsedFilters = filters.parsedFilters;
        this.queryParams = filters.queryParams;
        this.categoriesService.getCategories(this.parsedFilters, parseQueryParams(filters.queryParams));
    }
}
