import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-generate-pdf',
    templateUrl: './generate-pdf.component.html',
    styleUrls: ['./generate-pdf.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class GeneratePdfComponent implements OnInit {

  @Output() downloadPDF: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
