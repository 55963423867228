import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'FormatDatePipe',
    standalone: true
})
export class FormatDatePipe implements PipeTransform {

  transform(row: any, field: any): any {
    let formattedDate: string;

    if (field === 'date_added') {
      formattedDate = moment(row).format('YYYY-MM-DD');
      return formattedDate;
    }

    return row;
  }
}
