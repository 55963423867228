import { NotifyModel } from './../models/notify.model';
import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import 'bootstrap-notify';
import { TranslateService } from '@ngx-translate/core';
import * as Hammer from 'hammerjs';

@Injectable({
    providedIn: 'root'
})
export class NotifyService {
    private _not;
    constructor(private _translateService: TranslateService) { }

    showNotification(notification: NotifyModel) {
        if (this._not) {
            this._not.$ele[0].classList.add('d-none');
            this._not.close();
        }

        this._not = $.notify({
            icon: notification.icon,
            title: notification.title,
            message: notification.message
        },
            {
                type: notification.type,
                timer: notification.timer,
                placement: {
                    from: notification.from,
                    align: notification.align
                },
                onShown: function () {
                    let mc = new Hammer(this)
                    mc.on("swipeleft swiperight", (ev) => {
                        ev.type === 'swipeleft' ? this.classList.add('take-out-left') : this.classList.add('take-out-right');
                        setTimeout(() => this.remove(), 300)
                    });

                },
                template:
                    `<div data-notify="container" class="col-xs-11 col-sm-6 col-lg-${notification.width ? '6' : '3'} alert alert-${notification.type} alert-with-icon">
                    <i class="material-icons" data-notify="icon">${notification.icon}</i>
                    <button data-cy="notify-btn-close" mat-button type="button" class="close" data-dismiss="alert" aria-label="Close"><i class="material-icons">close</i></button>
                    <span data-notify="message"><b>${notification.title}:</b> ${notification.message}</span>
                </div>`
            });
    }

    customMessageFormResponse(message: string) {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant(message),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }



    successfullyDeleted() {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('SuccessfullyDeleted'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }

    successfullyAdded() {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('SuccessfullyAdded'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }

    successfullyUpdated() {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('SuccessfullyUpdated'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }

    successfullySendOrderToProduction() {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('OrderHasBeenSentToProduction'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }


    successfullySendOrderToInvoicing() {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('OrderHasBeenSentToInvoicing'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }

    successfullyFinishOrder() {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('OrderHasBeenFinished'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }

    successfullyStartStage() {
        this.showNotification({
            icon: 'info',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('StageStarted'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }

    successfullyPausedStage() {
        this.showNotification({
            icon: 'notifications',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('StagePaused'),
            type: 'warning',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }

    successfullyFinishStage() {
        this.showNotification({
            icon: 'add_alert',
            title: this._translateService.instant('Success'),
            message: this._translateService.instant('StageFinished'),
            type: 'success',
            timer: 2000,
            from: 'top',
            align: 'right',
        });;
    }


    updateFiled(reason: string) {
        this.showNotification({
            icon: 'add_alert',
            title: this._translateService.instant('Error'),
            message: this._translateService.instant(reason),
            type: 'danger',
            timer: 2000,
            from: 'top',
            align: 'right',
        });;
    }

    valueExceededPrice(reason: string) {
        this.showNotification({
            icon: 'add_alert',
            title: this._translateService.instant('Error'),
            message: this._translateService.instant(reason),
            type: 'danger',
            timer: 2000,
            from: 'top',
            align: 'right',
        });;
    }

    warning(message: string) {
        this.showNotification({
            icon: 'notifications',
            title: this._translateService.instant('Attention'),
            message: this._translateService.instant(message),
            type: 'warning',
            timer: 2000,
            from: 'top',
            align: 'right',
        });
    }
}

