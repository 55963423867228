<div class="background-grey h-100">
    <div class="container">
        <div class="row h-100 align-content-between">
            <div class="col-12">
                <div class="text-center" *ngIf="brandProfileService.currentBrandProfile$ | async as currentBrand">
                    <a class="logo-link">
                        <img width="100%" height="100%"  alt="img" [src]="currentBrand?.logo" class="logotype 5 pt-5" *ngIf="currentBrand?.logo; else fallbackCover" alt="...">
                        <ng-template #fallbackCover>
                        <img alt="img" src="../../assets/img/company_logo.png" class="logotype 6" alt="Menu App" width="100%" height="100%" >
                        </ng-template>
                    </a>
                    <h3 class="font-weight-bold">{{currentBrand.name}}</h3>
                </div>
                <div class="asset font-weight-bold mt-3" *ngIf="assetsService.selectedItem$ | async as selectedAsset">
                    <h4 class="mb-0 mx-3">{{selectedAsset.asset_type.name}}  {{selectedAsset.name}}</h4>
                </div>
            </div>
            <div class="col-12 languages" *ngIf="settingsService.suportedLocales$ | async as languages">
                <div class="text-center"><h5>Choose your language:</h5></div>
                <div class="flags-list">
                    <div class="flag" *ngFor="let language of languages">
                        <img width="100%" height="100%" loading="lazy" alt="img" src="../../assets/img/flags/flags-round/{{ language }}.png" alt="..." style="width:32px" (click)="changeLanguage(language)">
                    </div>
                </div>
            </div>
            <div class="app-brand col-12 py-4">
                <div class="text-center">
                    <a class="app-link">
                        <img src="../../assets/img/sip&bite_secondary_transp_no-margins.png" width="160px" height=""  alt="img" class="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>