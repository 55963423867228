export function isMobile() {
    if ($(window).width() < 576) {
        return true;
    }
    return false;
}
export function isTablet() {
    if ($(window).width() < 992 && $(window).width() > 576) {
        return true;
    }
    return false;
}