import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { KeyValue, NgFor, NgIf, NgStyle, AsyncPipe, KeyValuePipe, NgOptimizedImage } from '@angular/common';
import { SettingsService } from '../../settings/services/settings.service';
import { ProductModel } from '../../products/models/product.model';
import { TypeModel } from '../../types/models/type.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AddProductToCartModalComponent } from './add-product-to-cart-modal/add-product-to-cart-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { NoItemToShowComponent } from '../../../shared/components/no-item-to-show/no-item-to-show.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
@Component({
    selector: 'app-qr-menu-product',
    templateUrl: './qr-menu-product.component.html',
    styleUrls: ['./qr-menu-product.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgIf, NgStyle, NoItemToShowComponent, AsyncPipe, KeyValuePipe, TranslateModule, NgOptimizedImage, LazyLoadImageModule, FooterComponent]
})
export class QrMenuProductComponent implements OnInit {
    public form: FormGroup;
    public booleanOptions;
    @Input() typesList: TypeModel[];
    @Input() logo;
    @Input() structuredList: any;
    @Input() categoriesList: any;
    @Input() asset_id: string;
    @Input() textSearch: string;
    @Input() searchProductsCount: number;
    @Output() addOrderProduct: EventEmitter<ProductModel> = new EventEmitter();
    math = Math;

    constructor(
        public translateService: TranslateService,
        public settingsService: SettingsService,
        public _dialogService: DialogService
    ) { }

    ngOnInit(): void { }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    getCategoryId(current) {
        return this.categoriesList.find(cat => cat.name === current)?.id;
    }

    notEmptyObj(obj) {
        if (Object.keys(obj).length) {
            for (let category in obj) {
                if ((obj[category].length))
                    return true;
            }
        }
        return false;
    }

    typeHasImage(type) {
        return this.typesList?.find(el => el.name === type).show_products_images
    }

    addProductToCart(product) {
        const modalRef = this._dialogService.openModal(product, AddProductToCartModalComponent, DimensionsEnum.AUTO, null, 'rounded-corners-modal');
        modalRef.componentInstance.addProduct.subscribe(event => this.addOrderProduct.emit(event));
    }

    identifyType(index, type) {
        return index
    }
    identifyCategory(index, category) {
        return index
    }
    identifyProduct(index, product) {
        return index
    }
}
