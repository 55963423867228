import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TypeModel } from '../../models/type.model';
import { TypesService } from '../../services/type.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { validateAllFormFields } from 'src/app/shared/utils/form.utils';
import { filter } from 'rxjs';
import { booleanOptions$ } from 'src/app/shared/constants';
import { TranslateModule } from '@ngx-translate/core';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { DropdownWithBooleansComponent } from '../../../../shared/components/dropdown-with-booleans/dropdown-with-booleans.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ContentLanguageDropdownComponent } from '../../../../shared/components/content-language-dropdown/content-language-dropdown.component';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-types-modal',
    templateUrl: './types-modal.component.html',
    styleUrls: ['./types-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, ContentLanguageDropdownComponent, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DropdownWithBooleansComponent, DialogFooterButtonsComponent, TranslateModule]
})
export class TypesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public selectedType: TypeModel;
    public booleanOptions;

    constructor(
        public dialogRef: MatDialogRef<TypesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TypeModel,
        private _formBuilder: FormBuilder,
        private _typesService: TypesService) {
        super(dialogRef, _typesService);
        this.data?.id && this._typesService.getType(this.data.id);

    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            id: this.selectedType?.id || '',
            name: this.selectedType?.name || '',
            rank: this.selectedType?.rank || '',
            is_active: this.selectedType ? this.selectedType.is_active : true,
            show_products_images: this.selectedType ? this.selectedType.show_products_images : true
        });
        booleanOptions$(null, null, true, false).pipe(untilDestroyed(this)).subscribe(value => this.booleanOptions = value.data);
        this._typesService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
        this._typesService.selectedItem$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.selectedType = data;
            this.form?.patchValue(data);
        })
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        if (!this.selectedType) {
            this._typesService.createType(this.form.value);
            return;
        }
        this._typesService.updateType(this.form.value);
    }

    changeLang() {
        this.data.id && this._typesService.getType(this.data.id);
    }
}
