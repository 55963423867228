import { Component } from '@angular/core';
import { AppSettingsService } from '../../services/app-settings.service';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.css'],
    standalone: true,
    imports: [NgIf, AsyncPipe]
})
export class PreloaderComponent {
    constructor(
        public appSettingsService: AppSettingsService,
    ) { }
}
