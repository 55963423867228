import { filter } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BrandProfileModel } from '../../models/brand-profile.model';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { BrandProfileService } from '../../services/brand-profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { BrandImagesTabComponent } from './brand-images-tab/brand-images-tab.component';
import { BrandGeneralInfoTabComponent } from './brand-general-info-tab/brand-general-info-tab.component';
@UntilDestroy()
@Component({
    selector: 'app-brand-profile-modal',
    templateUrl: './brand-profile-modal.component.html',
    styleUrls: ['./brand-profile-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, BrandGeneralInfoTabComponent, BrandImagesTabComponent, TranslateModule]
})
export class BrandProfileModalComponent extends BaseModalComponent implements OnInit {
    public currentBrandProfile: BrandProfileModel;

    constructor(
        public dialogRef: MatDialogRef<BrandProfileModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: BrandProfileModel,
        private _sweetAlertService: SweetAlertService,
        protected _brandProfileService: BrandProfileService) {
        super(dialogRef, _brandProfileService);
        this.currentBrandProfile = _data;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._brandProfileService.currentBrandProfile$.pipe(filter(currentBrand => !!currentBrand), untilDestroyed(this)).subscribe(currentBrand => this.currentBrandProfile = currentBrand)
        this._brandProfileService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
    }

    saveAndClose(event: boolean) {
        this._saveAndClose = event;
    }

    removeLogoBrand() {
        this._sweetAlertService.showSwal('remove-picture', () => this._brandProfileService.removeLogoImage(), 'TheLogoImage')
    }

    removeCoverBrand() {
        this._sweetAlertService.showSwal('remove-picture', () => this._brandProfileService.removeCoverImage(), 'TheCoverImage')
    }
}
