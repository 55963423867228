import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';

import { TablesModule } from 'src/app/shared/components/tables/tables.module';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { OrdersComponent } from './orders.component';
import { DashboardAssetsModule } from '../dashboard/dashboard-assets/dashboard-assets.module';



@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    DashboardAssetsModule,
    OrdersComponent
]
})
export class OrdersModule { }
