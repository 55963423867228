import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { CategoryModel } from '../../models/category.model';
import { CategoriesService } from '../../services/category.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { validateAllFormFields } from 'src/app/shared/utils/form.utils';
import { filter } from 'rxjs';
import { booleanOptions$ } from 'src/app/shared/constants';
import { TranslateModule } from '@ngx-translate/core';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { DropdownWithBooleansComponent } from '../../../../shared/components/dropdown-with-booleans/dropdown-with-booleans.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ContentLanguageDropdownComponent } from '../../../../shared/components/content-language-dropdown/content-language-dropdown.component';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-categories-modal',
    templateUrl: './categories-modal.component.html',
    styleUrls: ['./categories-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, ContentLanguageDropdownComponent, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DropdownWithBooleansComponent, DialogFooterButtonsComponent, TranslateModule]
})
export class CategoriesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public selectedCategory: CategoryModel;
    public booleanOptions;

    constructor(
        public dialogRef: MatDialogRef<CategoriesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CategoryModel,
        private _formBuilder: FormBuilder,
        private _categoriesService: CategoriesService) {
        super(dialogRef, _categoriesService);
        this.data?.id && this._categoriesService.getCategory(this.data.id);
        this._categoriesService.selectedItem$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.selectedCategory = data;
            this.form?.patchValue(data);
        })
    }

    ngOnInit(): void {
        this._categoriesService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
        this.form = this._formBuilder.group({
            id: this.selectedCategory?.id || '',
            name: this.selectedCategory?.name || '',
            is_active: this.selectedCategory?.is_active || true,
            rank: this.selectedCategory?.rank || '',
        });
        booleanOptions$(null, null, true, false).pipe(untilDestroyed(this)).subscribe(value => this.booleanOptions = value.data);
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        if (!this.selectedCategory) {
            this._categoriesService.createCategory(this.form.value);
            return;
        }
        this._categoriesService.updateCategory(this.form.value);
    }

    changeLang() {
        this.data.id && this._categoriesService.getCategory(this.data.id);
    }
}
