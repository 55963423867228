<mat-dialog-content class="pt-5">
    <div class="d-flex justify-content-center align-items-center mb-4">
        <i class="material-icons-outlined text-success fs-5">credit_card</i>
    </div>
    <div class="text-center">
        <h4 class="font-weight-normal">{{"EditPaymentType" | translate}} {{"For" | translate | lowercase }} 
            <span class="font-weight-bold custom-black">{{ asset }}</span>
        </h4>
        <h4 class="register-payment-title font-weight-normal">
            <span class="font-weight-bold custom-black">{{data.order_products.length}}</span> 
            {{"Products" | translate | lowercase}}, {{"TotalValueOf" | translate | lowercase}} 
            <span class="font-weight-bold custom-black">{{data.total}} {{settingsService.currency$ | async}}</span>
        </h4>
        <form [formGroup]="form" appFormValidator class="mt-4 w-75 mx-auto">
            <div class="">
                <mat-form-field class="customized">
                    <mat-label>{{ 'PaymentMethod' | translate }}</mat-label>
                        <mat-select matSelect disableOptionCentering [formControl]="form.controls.type">
                            <mat-option value="cash" id="cash">{{ 'cash' | translate}}</mat-option>
                            <mat-option value="card" id="card">{{ 'card' | translate}}</mat-option>
                        </mat-select>
                    <mat-error>
                        <app-form-field-error [control]="form.controls.type"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="mb-0 px-0">
        <app-dialog-footer-buttons class="w-100 text-center"
        (close)="dialogRef.close()"
        (saveAndClose)="saveAndExit()"
        (save)="onSubmit()"
        [hideSave]="true">
        </app-dialog-footer-buttons>
    </div>
</mat-dialog-content>