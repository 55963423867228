import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { AccountsModalComponent } from './components/accounts-modal/accounts-modal.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { TableDataHeaders, TableHeadersTypeEnum } from 'src/app/shared/models/table-data-headers.model';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { AccountModel } from './models/account.model';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { RolesService } from '../roles/services/roles.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AccountsService } from './services/accounts.service';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { toObservable } from '@angular/core/rxjs-interop';
import { PageEvent } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { TableFiltersComponent as TableFiltersComponent_1 } from '../../shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { NgIf, UpperCasePipe } from '@angular/common';
import { RestrictPermissionsDirective } from '../../shared/directives/restrict-permissions.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.css'],
    standalone: true,
    imports: [MatButtonModule, RestrictPermissionsDirective, NgIf, TableFiltersComponent_1, RegularTableComponent, UpperCasePipe, TranslateModule]
})

export class AccountsComponent implements OnInit, OnDestroy {

    public PermissionsEnum = PermissionsEnum;
    public accountsList = this.accountsService.itemsList;
    public accountsData = this.accountsService.itemsData;
    public rolesList = this._rolesService.rolesList;
    public parsedFilters = '';

    public queryParams = {
        page: 1,
        records_number: 10
    };


    public filters: TableFilter[] = [
        {
            resourceName: 'name',
            label: 'Account',
            type: TableFilterTypesEnum.SELECT,
            controls: ['id'],
            style: 'col-md-4',
            firstOptionMessage: 'All',
            dropdownId: 'account',
            optionsList$: toObservable(this.accountsList)
        },
        {
            resourceName: 'role',
            label: 'Role',
            type: TableFilterTypesEnum.SELECT,
            controls: ['roles.id'],
            style: 'col-md-4',
            firstOptionMessage: 'All',
            dropdownId: 'role',
            optionsList$: toObservable(this.rolesList)
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'LastName',
            fieldNames: ['last_name', 'first_name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            cellType: TableCellType.FULL_NAME,
        },
        {
            name: 'Username',
            fieldNames: ['username'],
            cssClasses: 'all',
            searchable: true,
            sorting: true
        },
        {
            name: 'Phone',
            fieldNames: ['mobile'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
        {
            name: 'Email',
            fieldNames: ['email'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        },
        {
            name: 'Roles',
            fieldNames: ['roles'],
            cssClasses: 'all',
            searchable: true,
            sorting: true
        },
        {
            name: 'Status',
            fieldNames: ['status'],
            cssClasses: 'all',
            mobileClasses: 'justify-content-start',
            type: TableHeadersTypeEnum.STATUS,
            searchable: true,
            sorting: true,
            hideOnMobile: true
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.USERS_ADD_AND_EDIT
        }
    ];

    @ViewChild('table', { static: true }) table: TableFiltersComponent;


    constructor(public accountsService: AccountsService,
        public userProfileService: UserProfileService,
        private _rolesService: RolesService,
        private _dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        this._rolesService.getRoles();
    }

    ngOnDestroy(): void {
        this.accountsService.itemsList.set([]);
        this.accountsService.itemsData.set(undefined);
        this._rolesService.rolesList.set([])
    }

    tableActionClicked(e: any) {
        this.openEditModal(e.entry);
    }

    addEntry() {
        this.openEditModal(null);
    }

    private openEditModal(entry: AccountModel | null) {
        this._dialogService.openModal(entry, AccountsModalComponent, DimensionsEnum.AUTO);
    }

    updatePage(event: PageEvent) {
        this.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this.accountsService.getAccounts(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    applyFilters(filters: ApplyFiltersModel) {
        this.parsedFilters = filters.parsedFilters;
        this.queryParams = filters.queryParams;
        this.accountsService.getAccounts(this.parsedFilters, parseQueryParams(filters.queryParams));
    }

}
