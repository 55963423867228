<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
         <small>{{"Products" | translate}}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12 pb-3">
        <h1 class="mb-0 dialog-title"
            *ngIf="selectedProduct">
            {{'Edit' | translate}}:
            <span> {{ selectedProduct?.name }} </span>
            <ng-template #namePlaceholder>-</ng-template>
        </h1>
        <h1 class="mb-0 dialog-title" *ngIf="!selectedProduct"> {{'AddProduct' | translate}}</h1>
    </div>

    <div class="card-header card-header-tabs card-header-secondary px-4">
        <div class="nav-tabs-navigation">
            <ul role="tablist" class="nav nav-pills nav-pills-primary">
                <li class="nav-item">
                    <a data-toggle="tab" href="#general-info" role="tablist" class="nav-link active">{{ "GeneralInfo" | translate }}</a>
                </li>
                <li class="nav-item" *ngIf="selectedProduct">
                    <a data-toggle="tab" href="#product-image" role="tablist" class="nav-link">{{ "Image" | translate }}</a>
                </li>
                <li class="nav-item" *ngIf="selectedProduct">
                    <a data-toggle="tab" href="#allergens" role="tablist" class="nav-link">{{ "Allergens" | translate }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<mat-dialog-content>
    <app-preloader></app-preloader>
    
    
    <div class="tab-content tab-space pb-0">
        <div id="general-info" class="position-relative tab-pane active" >
            <form [formGroup]="form" appFormValidator>
                <div class="row mt-4 justify-content-end">
                    <div class="col-4 col-lg-2">
                        <app-content-language-dropdown (changeLang)="changeLang()"></app-content-language-dropdown>
                    </div>
                </div>
                <div class="form-row mb-lg-0">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field class="customized">
                            <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                            <input type="text" matInput id="name" autocomplete="off" formControlName="name">
                            <mat-error>
                                <app-form-field-error [control]="form.controls.name"></app-form-field-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-dropdown-with-booleans 
                        [control]="form.controls.is_active" 
                        [label]="'IsActive'" 
                        [itemsList]="booleanOptions">
                        </app-dropdown-with-booleans>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2">
                        <app-dropdown-with-booleans 
                        [control]="form.controls.is_available" 
                        [label]="'IsAvailable'" 
                        [itemsList]="booleanOptions">
                        </app-dropdown-with-booleans>
                    </div>
    
                    <div class="col-6 col-sm-6 col-md-2">
                        <app-dropdown-with-search 
                        [control]="form.controls.type_id" 
                        [label]="'Type'"
                        [firstOptionMessage]="'SelectType'"
                        [itemsList]="typesList"
                        [cypressSelector]="'types'">
                        </app-dropdown-with-search>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                        <app-dropdown-with-search 
                        [control]="form.controls.category_id" 
                        [label]="'Category'"
                        [firstOptionMessage]="'SelectCategory'"
                        [itemsList]="categoriesList"
                        [cypressSelector]="'category'">
                        </app-dropdown-with-search>
                    </div>
                </div>
    
                <div class="row align-items-end">
                    <div class="col-md-4 mb-3 pb-3 mb-sm-0">
                        <div class="form-row mb-0 mb-sm-3 mb-md-0 mt-md-auto">
                            <mat-form-field class="w-100" class="customized">
                                <mat-label for="ingredients">{{ 'Ingredients' | translate }}:</mat-label>
                                <textarea matInput id="ingredients" autocomplete="off" rows="6" formControlName="ingredients"></textarea>
                                <mat-error>
                                    <app-form-field-error 
                                    [control]="form.controls.ingredients">
                                </app-form-field-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
    
                    <div class="col-md-8 mb-3 mb-sm-0">
                        <div class="form-row mb-0 mb-sm-3">
                            <div class="col-6 col-sm-3">
                                <mat-form-field class="customized">
                                    <mat-label for="amount">{{ 'Amount' | translate }}:</mat-label>
                                    <input type="text" matInput id="amount" autocomplete="off" formControlName="amount">
                                    <mat-error>
                                        <app-form-field-error [control]="form.controls.amount"></app-form-field-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3">
                                <app-dropdown-with-search 
                                [control]="form.controls.measurement_unit_id" 
                                [label]="'MeasurementUnit'"
                                [firstOptionMessage]="'Select'"
                                [itemsList]="measurementUnitsList"
                                [cypressSelector]="'measurementUnit'">
                                </app-dropdown-with-search>
                            </div>
                            <div class="col-6 col-sm-3">
                                <mat-form-field class="customized">
                                    <mat-label for="price">{{ 'Price' | translate }}:</mat-label>
                                    <input type="text" matInput id="price" autocomplete="off" formControlName="price">
                                    <mat-error>
                                        <app-form-field-error [control]="form.controls.price"></app-form-field-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3">
                                <mat-form-field class="customized">
                                    <mat-label for="servings">{{ 'Servings' | translate }}:</mat-label>
                                    <input type="text" matInput id="servings" autocomplete="off" formControlName="servings">
                                    <mat-error>
                                        <app-form-field-error [control]="form.controls.servings"></app-form-field-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
    
                        <div class="form-row mb-0 mb-sm-3 align-items-end">
                            <div class="col-6 col-sm-3">
                                <mat-form-field class="customized">
                                    <mat-label for="calories">{{ 'Calories' | translate }}:</mat-label>
                                    <input type="text" matInput id="calories" autocomplete="off" formControlName="calories">
                                    <mat-error>
                                        <app-form-field-error [control]="form.controls.calories"></app-form-field-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3">
                                <mat-form-field class="customized">
                                    <mat-label for="proteins">{{ 'Proteins' | translate }}:</mat-label>
                                    <input type="text" matInput id="proteins" autocomplete="off" formControlName="proteins">
                                    <mat-error>
                                        <app-form-field-error [control]="form.controls.proteins"></app-form-field-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3">
                                <mat-form-field class="customized">
                                    <mat-label for="carbohydrates">{{ 'Carbohydrates' | translate }}:</mat-label>
                                    <input type="text" matInput id="carbohydrates" autocomplete="off" formControlName="carbohydrates">
                                    <mat-error>
                                        <app-form-field-error [control]="form.controls.carbohydrates"></app-form-field-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-3">
                                <mat-form-field class="customized">
                                    <mat-label for="fats">{{ 'Fats' | translate }}:</mat-label>
                                    <input type="text" matInput id="fats" autocomplete="off" formControlName="fats">
                                    <mat-error>
                                        <app-form-field-error [control]="form.controls.fats"></app-form-field-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <app-dialog-footer-buttons class="w-100"
                (close)="dialogRef.close()"
                (saveAndClose)="saveAndExit()"
                (save)="onSubmit()">
            </app-dialog-footer-buttons>
        </div>
        <div id="allergens" class="tab-pane" *ngIf="selectedProduct">
            <app-product-allergens [product]="selectedProduct" [productAllergens]="productAllergens"></app-product-allergens>
        </div>
        <div id="product-image" class="tab-pane" *ngIf="selectedProduct">
            <app-product-image-tab [product]="selectedProduct" [productAllergens]="productAllergens" [dialogRef]="dialogRef"></app-product-image-tab>
        </div>
    </div>


</mat-dialog-content>