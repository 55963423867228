

import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrandInvoiceProfileModalComponent } from './components/brand-invoice-profile-modal/brand-invoice-profile-modal.component';
import { InvoiceProfileInfoTabComponent } from './components/brand-invoice-profile-modal/invoice-profile-info-tab/invoice-profile-info-tab.component';
import { InvoiceSeriesTabComponent } from './components/brand-invoice-profile-modal/invoice-series-tab/invoice-series-tab.component';
import { InvoiceSeriesListComponent } from './components/brand-invoice-profile-modal/invoice-series-tab/invoice-series-list/invoice-series-list.component';
import { BrandInvoiceProfileComponent } from './brand-invoice-profile.component';

@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    BrandInvoiceProfileModalComponent,
    BrandInvoiceProfileComponent,
    InvoiceProfileInfoTabComponent,
    InvoiceSeriesTabComponent,
    InvoiceSeriesListComponent
],
    exports: [
        BrandInvoiceProfileModalComponent,
        BrandInvoiceProfileComponent,
        InvoiceProfileInfoTabComponent,
        InvoiceSeriesTabComponent,
        InvoiceSeriesListComponent,
    ]
})

export class BrandInvoiceProfileModule { }