<section class="reports-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <span>{{ title | translate | uppercase }}</span>
                <!-- <ng-container *ngIf="goToLink">
                    <a href="#{{goToLink?.url}}" class="d-flex align-items-center text-secondary small">
                    <span class="material-icons icon-back">arrow_back</span>
                        <span>{{ goToLink?.name | translate }}</span>
                    </a>
                </ng-container> -->
            </div>
            <div class="col-12 mt-5">
                <app-preloader></app-preloader>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</section>