import { APP_INITIALIZER, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MatCommonModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HAMMER_GESTURE_CONFIG, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withHashLocation } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppComponent } from './app/app.component';
import { HammerConfig, SetupApp, VerifyCustomerId, fetchBuildDetails, httpTranslateLoader } from './app/app.module';
import { AppRoutes } from './app/app.routing';
import { AccountsModule } from './app/modules/accounts/accounts.module';
import { AllergensModule } from './app/modules/allergens/allergens.module';
import { AssetsModule } from './app/modules/assets/asset.module';
import { BrandInvoiceProfileModule } from './app/modules/brand-invoice-profile/brand-invoice-profile.module';
import { BrandModule } from './app/modules/brand-profile/brand.module';
import { CategoriesModule } from './app/modules/categories/categories.module';
import { DashboardModule } from './app/modules/dashboard/dashboard.module';
import { FinancialModule } from './app/modules/financial/financial.module';
import { MeasurementUnitsModule } from './app/modules/measurement-unit/measurement-units.module';
import { OrdersModule } from './app/modules/orders/orders.module';
import { PaymentsModule } from './app/modules/payments/payments.module';
import { ProductsModule } from './app/modules/products/products.module';
import { QRScanModule } from './app/modules/qr-scan/qr-scan.module';
import { ReportsModule } from './app/modules/reports/reports.module';
import { RolesModule } from './app/modules/roles/roles.module';
import { SettingsModule } from './app/modules/settings/settings.module';
import { TypesModule } from './app/modules/types/types.module';
import { HttpRequestInterceptor } from './app/shared/_helpers/http-request-interceptor';
import { SharedComponentsModule } from './app/shared/components/shared-components.module';
import { MatPaginatorIntlLangService } from './app/shared/components/tables/regular-table/mat-paginator-intl-lang.service';
import { TablesModule } from './app/shared/components/tables/tables.module';
import { MaterialModule } from './app/shared/modules/material.module';
import { BuildDetailsService } from './app/shared/services/build-details.service';
import { VerifyCCustomerIdService } from './app/shared/services/customer-id-check.service';
import { LangService } from './app/shared/services/lang.service';
import { SetupService } from './app/shared/services/setup.service';
import { environment } from './environments/environment';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(MatCommonModule, FormsModule, ReactiveFormsModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }), MaterialModule,
            TablesModule,
            DragDropModule,
            SharedComponentsModule,
            MatDatepickerModule,
            MatMomentDateModule,
            MatNativeDateModule,
            DashboardModule,
            AccountsModule,
            RolesModule,
            BrandInvoiceProfileModule,
            SettingsModule,
            BrandModule,
            ReportsModule,
            FinancialModule,
            QRScanModule,
            AllergensModule,
            CategoriesModule,
            ProductsModule,
            TypesModule,
            MeasurementUnitsModule,
            AssetsModule,
            HammerModule,
            OrdersModule,
            PaymentsModule),
        SetupService,
        VerifyCCustomerIdService,
        {
            provide: APP_INITIALIZER,
            useFactory: SetupApp,
            deps: [SetupService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: VerifyCustomerId,
            deps: [VerifyCCustomerIdService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        { provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [BuildDetailsService], multi: true },
        { provide: LOCALE_ID, useValue: 'ro-RO' },
        { provide: MAT_DATE_LOCALE, useValue: 'ro-RO' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new MatPaginatorIntlLangService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]
        },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
        LangService,
        provideAnimations(),
        provideRouter(AppRoutes, withHashLocation()),
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
    .catch(err => console.error(err));
