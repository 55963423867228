import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'LabelPipe',
    standalone: true
})

export class LabelPipe implements PipeTransform {
    transform(value: string, element: HTMLElement) {
        const tokens = element.classList.value.split(' ');
        tokens.forEach(token => token && element.classList.remove(token));

        element.classList.add('badge', 'custom-table-badge', 'badge-dark', `${value.toLocaleLowerCase()}-badge`);
        if (element.parentElement) {
            element.parentElement ? element.parentElement.style.textAlign = 'center' : null;
        }
        return value;
    }
}