import { TablesModule } from '../../shared/components/tables/tables.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/modules/material.module';

import { CommonModule } from '@angular/common';

import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { AccountsGeneralInfoTabComponent } from './components/accounts-modal/accounts-general-info-tab/accounts-general-info-tab.component';
import { AccountsImageTabComponent } from './components/accounts-modal/accounts-image-tab/accounts-image-tab.component';
import { AccountsModalComponent } from './components/accounts-modal/accounts-modal.component';
import { AccountsComponent } from './accounts.component';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    AccountsComponent,
    AccountsModalComponent,
    AccountsGeneralInfoTabComponent,
    AccountsImageTabComponent
],
    exports: [
        AccountsComponent,
        AccountsModalComponent,
        AccountsGeneralInfoTabComponent,
        AccountsImageTabComponent,
    ]
})

export class AccountsModule { }