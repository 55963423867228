import { AccountModel } from '../../../models/account.model';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AccountsService } from '../../../services/accounts.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogRef } from '@angular/cdk/dialog';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-accounts-image-tab',
    templateUrl: './accounts-image-tab.component.html',
    styleUrls: ['./accounts-image-tab.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, NgIf, MatDialogModule, TranslateModule]
})
export class AccountsImageTabComponent implements OnInit {
    public form: FormGroup;

    @Input() data: AccountModel;
    @Input() dialogRef: DialogRef;
    @Input() _saveAndClose: boolean;
    @Output() removeProfilePic: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('removeProfileImg', { static: true }) removeProfileImgRef: ElementRef;

    constructor(
        private _accountsService: AccountsService,
        private _formBuilder: FormBuilder) {
        this.form = this._formBuilder.group({
            profile_pic: [this.data?.profile_pic],
        });
    }

    ngOnInit(): void {
        this._accountsService.onSaveSuccess$.pipe(filter(res => !!res), untilDestroyed(this)).subscribe(() => {
            this.removeProfileImgRef.nativeElement.click();
        });
    }

    saveAndExit() {
        if (this.form.value.profile_pic) {
            this.uploadFileToActivity(this.form.value);
            this._saveAndClose = true;
            this.dialogRef.close();
            return;
        }
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.form.value.profile_pic) {
            this.uploadFileToActivity(this.form.value);
        }
    }

    handleFileInput(files: FileList, fileType: string) {
        this.form.get(fileType).setValue(files[0]);
    }

    uploadFileToActivity(img: string) {
        this._accountsService.updateUserProfilePicture(this.data.id, img);
    }

    removeImage(input: HTMLInputElement) {
        input.value = '';
        this.form.controls.profile_pic.setValue('');
    }
}
