import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-report-widget',
    templateUrl: './report-widget.component.html',
    styleUrls: ['./report-widget.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class ReportWidgetComponent implements OnInit {

    @Input() title: string;
    @Input() color: string;
    @Input() service: any;
    @Input() filters: any;

    @ViewChild('reportContent') reportContent: ElementRef;

    constructor() { }

    ngOnInit(): void { }

}
