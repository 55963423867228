<ng-container *ngIf="filterControls">
    <mat-form-field class="customized" >
        <mat-label>{{ label | translate }}</mat-label>
        <div class="d-flex align-items-center range-picker">
            <mat-date-range-input [formGroup]="form" [rangePicker]="picker" [updateDateRangeValueDirective]>
                <input matInput matStartDate formControlName="{{filterControls[0]}}" placeholder="{{ 'StartDate' | translate }}" (click)="picker.open()">
                <input matInput matEndDate formControlName="{{filterControls[1]}}" placeholder="{{ 'EndDate' | translate }}" (click)="picker.open()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker" class="toggler-btn"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
        <mat-error>
            <app-form-field-error [control]="form.controls[filterControls[0]]"></app-form-field-error>
            <app-form-field-error [control]="form.controls[filterControls[1]]"></app-form-field-error>
        </mat-error>
    </mat-form-field>
</ng-container>