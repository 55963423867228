import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { getLang } from 'src/app/shared/utils/utils';
import { AllergenModel } from '../models/alergen.model';

@Injectable({
    providedIn: 'root'
})

export class AllergensService extends BaseService {
    itemsList$: BehaviorSubject<AllergenModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<AllergenModel> = new Subject();

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    protected getRequestOptions() {
        return { params: new HttpParams().set("locale", getLang()) };
    }

    createAllergen(allergen: AllergenModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.createResorce(apiEndpoints.allergens, allergen).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create allergen', error)
        });
    }

    getAllergen(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.allergens}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get allergen', error)
        });
    };

    getAllergens(filter?: string, paginationSettings?: string) {
        this.appSettingsService.isLoading.next(true);
        this.getResorce(apiEndpoints.allergens + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get allergens list', error)
        });
    }

    getAllergens$(): Observable<any> {
        return this.getResorce(apiEndpoints.allergens + '/dropdown-values');
    }

    updateAllergen(allergen: AllergenModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.allergens + '/' + allergen.id, allergen).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data)
            },
            error: error => this.reportError('Error on update allergen', error)
        });
    }

    deleteAllergen(allergen) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitDeleteRequest(apiEndpoints.allergens + '/' + allergen.id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this.getAllergens();
            },
            error: error => this.reportError('Error on delete allergen', error)
        });
    }
}
