<div class="px-0 pt-3 my-0">
    <div class="col-12">
        <div class="card my-3">
            <div id="accordion-call-waiter" role="tablist">
                <div class="card-collapse mb-0">
                    <div role="tab" id="headingOne" class="pt-4 card-header">
                        <div class="position-absolute person-img">
                            <div class="icon-wrapper text-center" 
                            [ngClass]="requestAlreadyExist ? 'notif-icon-inactive' : 'notif-icon'">
                                <i class="material-icons payment-icon opacity-10">notifications</i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end flex-column"> 
                            <div class="text-right pr-2">
                                <div class="d-flex justify-content-end pr-2 pb-3">
                                    <small class="user-title mb-0">{{"CallWaiter" | translate}}</small>
                                </div>
                            </div>
                        </div>
                        <hr class="dark horizontal mt-2 mb-0">
                    </div>
                    <div>
                    <div class="card-body px-0">
                        <div class="container" *ngIf="!requestAlreadyExist; else requestAlreadyRegistered">
                            <div class="pb-0 px-0">
                                <p class="text-center">{{ 'CallWaiterInfo' | translate }}</p>
                                <div class="d-flex justify-content-between pb-2">
                                    <div class="col-6 px-0 pr-1">
                                        <button type="button" class="order-btn w-100" (click)="callWaiter({requestType:'order'})">{{ 'Confirmation' | translate }}</button>
                                    </div>
                                    <div class="col-6 px-0 pl-1">
                                        <button type="button" class="order-btn w-100" (click)="displayPaymentOptions = !displayPaymentOptions">{{ 'Payment' | translate }}</button>
                                        <div *ngIf="displayPaymentOptions" class="d-flex justify-content-between pt-3">
                                            <button type="button" class="button option-btn" (click)="callWaiter({requestType:'payment', paymentType:'cash'})">{{ 'cash' | translate}}</button>
                                            <button type="button" class="button option-btn" (click)="callWaiter({requestType:'payment', paymentType:'card'})">{{ 'card' | translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #requestAlreadyRegistered>
                            <div class="container text-center">
                                <p class="font-weight-bold my-3">{{"WaiterWillComeSoon" | translate}}</p>
                                <div class="" *ngIf="data?.asset">
                                    <p class="asset-name font-custom-500 fs-08 mb-0">{{"For" | translate}} 
                                        <span class="font-weight-bold">{{data.asset.asset_type.name}} {{data.asset.name}}</span> {{"RequestHasAlreadyBeenSent" | translate}}:</p>
                                </div>
                                <p class="fs-08 font-weight-bold">
                                    <span>({{requestAlreadyExist.type | translate}}</span>
                                    <span>{{ (requestAlreadyExist.details? ' - ' +  (requestAlreadyExist.details | translate): '')}})</span>
                                </p>
                            </div>
                        </ng-template>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="payments?.length">
        <div class="card">
            <div id="accordion-manual-order" role="tablist">
                <div class="card-collapse mb-0">
                    <div role="tab" id="headingOne" class="m-0 pt-2 card-header">
                        <div class="position-absolute person-img">
                            <div class="payment-icon-wrapper text-center">
                                <i class="material-icons payment-icon opacity-10">credit_score</i>
                            </div>
                        </div>
                        <a data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed d-flex justify-content-end flex-column pr-2"> 
                            <div class="text-right pr-2">
                                <div class="d-flex justify-content-end">
                                    <div class="px-0">
                                        <small class="user-title mb-0">{{"TotalPaid" | translate}}</small>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <p class="font-weight-bold mb-0">
                                        {{totalPaid}}  {{_settingsService.currency$ | async}}
                                    </p>
                                </div>
                            </div>
                            <span class="pr-1"><i class="material-icons float-right">keyboard_arrow_down</i></span>
                        </a>
                        <hr class="dark horizontal mt-2 mb-0">
                    </div>
                    <div id="collapseOne" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion-manual-order" class="collapse">
                        <div class="card-body px-0"> 
                            <ul class="px-2">
                                <li class="product-list-item pl-3 my-3 border-paid" *ngFor="let payment of payments" >
                                    <div class="d-flex justify-content-between">
                                        <div class="date text-center">
                                            <span class="">{{ utcConvert(payment.created_at) | date : 'HH:mm'}}</span> <br />
                                            <span class="small font-weight-bold">{{ (utcConvert(payment.created_at) | date : 'dd.MM.yyyy')}}</span>
                                        </div>
                                        <div class="payment-type px-2">
                                            <div class="text-right">
                                                <span class="badge badge-warning" *ngIf="payment.type === 'cash'">{{payment.type | translate}}</span>
                                                <span class="badge badge-info" *ngIf="payment.type === 'card'">{{payment.type | translate}}</span>
                                            </div>
                                            <span class="small text-right">{{payment.value.toFixed(2)}} {{_settingsService.currency$ | async}}</span>
                                        </div>
                                    </div>
                                    <hr class="dark horizontal my-0 mt-2">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
