import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices/invoices.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { ReverseInvoiceModalComponent } from './invoices/components/reverse-invoice-modal/reverse-invoice-modal.component';
import { ExportInvoicesModalComponent } from './invoices/components/export-invoices-modal/export-invoices-modal.component';
import { InvoicesToExportListComponent } from './invoices/components/export-invoices-modal/components/invoices-to-export-list/invoices-to-export-list.component';

import { MaterialModule } from 'src/app/shared/modules/material.module';
import { httpTranslateLoader } from 'src/app/app.module';

import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';

import { InvoicesModalComponent } from './invoices/components/invoices-modal/invoices-modal.component';
import { InvoiceServicesFormComponent } from './invoices/components/invoices-modal/invoice-services-tab/invoice-services-form/invoice-services-form.component';
import { InvoiceServicesListComponent } from './invoices/components/invoices-modal/invoice-services-tab/invoice-services-list/invoice-services-list.component';
import { InvoiceGeneralInfoTabComponent } from './invoices/components/invoices-modal/invoice-general-info-tab/invoice-general-info-tab.component';
import { InvoiceServicesTabComponent } from './invoices/components/invoices-modal/invoice-services-tab/invoice-services-tab.component';

@NgModule({
    imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoader,
            deps: [HttpClient]
        }
    }),
    MatFormFieldModule,
    SharedComponentsModule,
    TablesModule,
    InvoicesComponent,
    ReverseInvoiceModalComponent,
    ExportInvoicesModalComponent,
    InvoicesToExportListComponent,
    InvoicesModalComponent,
    InvoiceServicesFormComponent,
    InvoiceServicesListComponent,
    InvoiceGeneralInfoTabComponent,
    InvoiceServicesTabComponent
],
    exports: []
})
export class FinancialModule { }
