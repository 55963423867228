import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, RoutesRecognized } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, pairwise, tap } from 'rxjs/operators';
import { UserProfileService } from '../services/user-profile.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements OnDestroy {

    private _subscription: Subscription;
    private _previousUrl: string;

    constructor(private _userProfile: UserProfileService,
        private _router: Router) {
        this._subscription = this._router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                this._previousUrl = events[0].urlAfterRedirects
            });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._userProfile.isAuthenticated().pipe(tap());
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}

