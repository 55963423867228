<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
        <small>{{ 'Settings' | translate }}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn" (click)="onNoClick()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12">
        <h1 class="mb-0 dialog-title">
            {{ 'Edit' | translate }}: {{ data?.name }}
        </h1>
    </div>
    
</div>

<mat-dialog-content>

    <div class="position-relative mh-100 mt-4">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" appFormValidator>
        <div class="form-row">
            <div class="col-md-12">
                <mat-form-field class="customized">
                    <mat-label for="value">{{ 'Value' | translate }}:</mat-label>
                    <input type="text" matInput id="value" autocomplete="off" formControlName="value">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['value']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        
        <div mat-dialog-actions class="mb-0">
            <div class="w-100 modal-footer border-0 p-0">
                <button type="button" class="btn btn-secondary" (click)="onNoClick()">{{ 'Close' | translate }}</button>
                <button type="button" class="btn btn-primary" (click)="saveAndExit()">{{ 'SaveAndClose' | translate }}</button>
                <button class="btn btn-primary">{{ 'Save' | translate }}</button>
            </div>
        </div>
    </form>
</div>
</mat-dialog-content>