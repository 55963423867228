import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { getLang } from 'src/app/shared/utils/utils';
import { OrderProductsService } from '../../orders/services/order-products.service';
import { CustomerModel } from '../models/customer.model';
import { OrdersService } from '../../orders/services/oerders.service';

@Injectable({
    providedIn: 'root'
})

export class CustomersService extends BaseService {
    itemsList$: BehaviorSubject<CustomerModel[]> = new BehaviorSubject(undefined);
    orderTypesList$: BehaviorSubject<CustomerModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<CustomerModel> = new Subject();

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,
        private _orderProductsService: OrderProductsService,
        private _ordersService: OrdersService,
        private _lsService: LocalStorageService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    protected getRequestOptions(context?: any) {
        return { params: new HttpParams().set("locale", getLang()), context: context };
    }


    addCustomer(customer) {
        this.appSettingsService.isLoading.next(true);
        this.createResorce(apiEndpoints.customers, customer).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create customer', error)
        });
    }
    addCustomer$(customer) {
        return this.createResorce(apiEndpoints.customers, { name: customer });
    }

    addCustomerWhenNotInLS(customer, payload) {
        this.appSettingsService.isLoading.next(true);
        this.createResorce(apiEndpoints.customers, customer).subscribe({
            next: apiResponse => {
                this._lsService.setValue('customerData', JSON.stringify(apiResponse.data))
                this._orderProductsService.addProductToOrder({ ...payload, customer_id: apiResponse.data.id })
            },
            error: error => this.reportError('Error on create customer', error)
        });
    }

    getCustomer(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.customers}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get customer', error)
        });
    };
    getCustomer$(id: number, params?: any, context?: any) {
        return this.getResorce(`${apiEndpoints.customers}/${id}`, params, context);
    };

    updateCustomer(id: string, name: string) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.customers + '/' + id, { name: name }).subscribe({
            next: (apiResponse) => {
                this._lsService.setValue('customerData', JSON.stringify({ id: id, name: apiResponse.data.name }));
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data);
            },
            error: error => this.reportError('Error on update customer', error)
        });
    }

    deleteCustomer(id) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitDeleteRequest(apiEndpoints.customers + '/' + id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this._ordersService.orderProductsChanged$.next(true);
            },
            error: error => this.reportError('Error on delete customer', error)
        });
    }
}
