

import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/shared/modules/material.module';

import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { httpTranslateLoader, TablesModule } from 'src/app/shared/components/tables/tables.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReportsComponent } from './reports.component';
import { ReportWidgetComponent } from './report-widget/report-widget.component';

import { MatCommonModule } from '@angular/material/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
@NgModule({
    imports: [
    MatCommonModule,
    HttpClientModule,
    MaterialModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoader,
            deps: [HttpClient]
        }
    }),
    MatFormFieldModule,
    SharedComponentsModule,
    TablesModule,
    ReportsComponent,
    ReportWidgetComponent
],
    exports: [
        ReportsComponent,
        ReportWidgetComponent
    ]
})
export class ReportsModule { }
