import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

import { AdminLayoutComponent } from './shared/layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './shared/layouts/auth/auth-layout.component';
import { QrScanComponent } from './modules/qr-scan/qr-scan.component';
import { NoAccessComponent } from './modules/no-access/no-access.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
            }
        ]
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
        ]
    },
    {
        path: 'menu',
        component: QrScanComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/qr-scan/qr-scan.module').then(m => m.QRScanModule),
            },
        ]
    },
    {
        path: 'no-access',
        component: NoAccessComponent,
    },
    {
        path: 'print',
        loadChildren: () => import('./modules/print/print.module').then(m => m.PrintModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'menu',
    }
];
