import { NgIf, UpperCasePipe } from '@angular/common';
import { Component, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';
import { TableFiltersComponent as TableFiltersComponent_1 } from '../../shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { AssetsModalComponent } from './components/assets-modal/assets-modal.component';
import { AssetModel } from './models/assets.model';
import { AssetsService } from './services/assets.service';
@UntilDestroy()
@Component({
    selector: 'app-assets',
    templateUrl: './assets.component.html',
    styleUrls: ['./assets.component.css'],
    standalone: true,
    imports: [MatButtonModule, NgIf, TableFiltersComponent_1, RegularTableComponent, UpperCasePipe, TranslateModule]
})
export class AssetsComponent {
    public PermissionsEnum = PermissionsEnum;
    public itemsList: AssetModel[];
    public itemsData: PaginationModel;
    public parsedFilters = '';

    public queryParams = {
        page: 1,
        records_number: 10
    };


    public filters: TableFilter[] = [
        {
            resourceName: 'name',
            label: 'Asset',
            type: TableFilterTypesEnum.TEXT,
            controls: ['name'],
            style: 'col-sm-6 col-md-3',
            placeholders: ['']
        },
        {
            resourceName: 'type',
            label: 'Type',
            type: TableFilterTypesEnum.SELECT,
            controls: ['asset_type_id'],
            style: 'col-sm-6 col-md-3',
            firstOptionMessage: 'All',
            dropdownId: 'asset_type',
            optionsList$: this.assetsService.getAssetTypes$()
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        },
        {
            name: 'Type',
            fieldNames: ['asset_type.name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        }
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.ASSETS_ADD_AND_EDIT
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.ASSETS_DELETE
        },
        {
            name: 'PrintQR',
            type: TableActionTypeEnum.Print,
        }
    ];

    @ViewChildren('table') table: TableFiltersComponent;


    constructor(public assetsService: AssetsService,
        private _dialogService: DialogService,
        private _sweetAlertService: SweetAlertService
    ) {
        this.assetsService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsList = data)
        this.assetsService.itemsData$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsData = data)
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Edit) {
            this.openEditModal(e.entry);
        }
        if (e.type === TableActionTypeEnum.Delete) {
            this._sweetAlertService.showSwal('warning-message', () => this.assetsService.deleteAsset(e.entry), "Asset");
        }
        if (e.type === TableActionTypeEnum.Print) {
            this.assetsService.getQRCode(e.entry.id);
        }
    }

    getQRs() {
        this.assetsService.getAllQRCodes();
    }

    addEntry() {
        this.openEditModal(null);
    }

    private openEditModal(entry: AssetModel | null) {
        this._dialogService.openModal(entry, AssetsModalComponent, DimensionsEnum.AUTO).afterClosed().subscribe(() => this.assetsService.getAssets(this.parsedFilters, parseQueryParams(this.queryParams)));
    }

    changeLang() {
        this.assetsService.getAssets(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    updatePage(event: PageEvent) {
        this.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this.assetsService.getAssets(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    applyFilters(filters: ApplyFiltersModel) {
        this.parsedFilters = filters.parsedFilters;
        this.queryParams = filters.queryParams;
        this.assetsService.getAssets(this.parsedFilters, parseQueryParams(filters.queryParams));
    }
}
