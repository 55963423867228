import { TableCellType } from "../enums/TableCellType.enum";


export class TableDataHeaders {
    name: string;
    fieldNames?: string[];
    cssClasses?: string;
    searchable?: boolean;
    sorting?: boolean;
    type?: TableHeadersTypeEnum = TableHeadersTypeEnum.TEXT;
    cellType?: TableCellType;
    cellContentStyle?: CellContentStyle;
    total?: string[];
    hideOnMobile?: boolean;
    hideOnTablet?: boolean;
    hideName?: boolean;
    block?: boolean;
    mobileClasses?: string;
    tabletClasses?: string;
}

export interface CellContentStyle {
    message?: string;
    color?: string;
    bgColor?: string;
    position?: string;
    icon?: string;
}

export enum TableHeadersTypeEnum {
    TEXT = 'text',
    ICON = 'icon',
    NUMBER = 'number',
    NUMBER_WITH_ICON = 'number-with-icon',
    NUMBER_WITH_TOOLTIP = 'number-with-tooltip',
    PHONE = 'phone',
    CUI = 'fiscal_code',
    CNP = 'cnp',
    STATUS = 'status',
    STATUS_MIN_WIDTH = 'status-min-width',
    DATE = 'date',
    DURATION = 'duration',
    TYPE = 'type',
    MULTIPLE_ELEMENTS = 'multiple-elements',
    PRICE = 'price',
    CREATED_AT = 'created-at',
}