import { Injectable } from '@angular/core';
import { ApiRequestService, apiEndpoints, ErrorResponse } from './api-request.service';
import { LocalStorageService, AUTH_TOKEN, CURRENT_USER } from './local-storage.service';
import { ApiLoginResponseModel } from '../models/login-response.model';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CleanupService } from './cleanup.service';
import { AppSettingsService } from './app-settings.service';
import { ResetPasswordModel } from '../models/reset-password.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public errorHandler$ = new Subject<string>();

    constructor(private _apiRequestService: ApiRequestService,
        private _localStorageService: LocalStorageService,
        private _cleanupService: CleanupService,
        private _router: Router,
        private _appSettingsService: AppSettingsService) { }

    login(username: string, password: string) {
        this._appSettingsService.isLoading.next(true);
        this._apiRequestService.submitPostRequest(apiEndpoints.login, { username, password }).subscribe({
            next: response => {
                this._handleLoginResponse(<ApiLoginResponseModel>response);
            },
            error: error => this._reportError(<ErrorResponse>error.error)
        })
    }

    sendEmailResetLink(email: string) {
        this._apiRequestService.submitPostRequest(apiEndpoints.emailResetLink, { email }).subscribe({
            next: () => { },
            error: error => this._reportError(<ErrorResponse>error.error)
        })
    }

    resetPassword(payload: ResetPasswordModel) {
        this._apiRequestService.submitPostRequest(apiEndpoints.resetPassword, payload).subscribe({
            next: () => { },
            error: error => this._reportError(<ErrorResponse>error.error)
        });
    }

    logout() {
        this._appSettingsService.isLoading.next(true);
        this._apiRequestService.submitPostRequest(apiEndpoints.logout, null).subscribe({
            next: () => {
                this._handleLogoutResponse();
                this._appSettingsService.isLoading.next(false);
            },
            error: error => this._reportError(error.error)
        });
    }

    private _handleLoginResponse(response: ApiLoginResponseModel) {
        this.errorHandler$.next(undefined);

        this._localStorageService.setValue(AUTH_TOKEN, response.data.token);
        this._localStorageService.setValue(CURRENT_USER, JSON.stringify(response.data.user));
        this._router.navigate(['/admin']);
        this._appSettingsService.isLoading.next(false);
    }

    private _handleLogoutResponse() {
        this._localStorageService.deleteValue(AUTH_TOKEN);
        this._localStorageService.deleteValue(CURRENT_USER);
        this._cleanupService.resetAllSubjects();
        this._router.navigate(['/login']);
    }

    private _reportError(response: ErrorResponse) {
        this.errorHandler$.next(response?.message);
        this._appSettingsService.isLoading.next(false);
    }
}
