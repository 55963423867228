import { Component, OnInit } from '@angular/core';
import { AssetsService } from 'src/app/modules/assets/services/assets.service';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-nav-notifications',
    templateUrl: './nav-notifications.component.html',
    styleUrls: ['./nav-notifications.component.css'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})

export class NavNotificationsComponent implements OnInit {
    public activeCustomerRequests: number;
    public activeOrderAssets: number;
    public notifications;

    constructor(private _assetsService: AssetsService) {
        this._assetsService.itemsListNotPaginated$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.notifications = data;
            this.activeCustomerRequests = data.filter(asset => asset.customer_request).length;
            this.activeOrderAssets = data.filter(asset => asset.order).length;
        });
    }

    ngOnInit(): void { }

}
