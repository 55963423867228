import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { UserProfileService } from '../services/user-profile.service';

@Directive({
    selector: '[appRestrictPermissions]',
    standalone: true
})
export class RestrictPermissionsDirective implements OnInit {

    public elem: HTMLElement = this._element.nativeElement;

    @Input() appRestrictPermissions: boolean | string | string[];
    @Input() customPermissions: any;

    constructor(private _userProfileService: UserProfileService,
        private _element: ElementRef) { }

    ngOnInit(): void {
        if (this.appRestrictPermissions ===  false) {
            this.elem.classList.add('d-none');
        }

        if (!this.appRestrictPermissions || this.appRestrictPermissions === true) {
            return;
        }


        this.elem.classList.add('d-none');

        this._userProfileService.currentUser$.subscribe(user => {
            const result = this._userProfileService.checkForPermission(this.appRestrictPermissions);

            if (result) {
                if (this.elem.classList.contains('fallback-status')) {
                    return;
                }
                this.elem.classList.remove('d-none');
            }

            if (!result && this.elem.classList.contains('fallback-status') && user?.id !== parseInt(this.elem.id)) {
                this.elem.classList.remove('d-none');
            }

            if (this.customPermissions) {
                Object.entries(this.customPermissions).forEach(permission => {
                    if (permission[0] === this.appRestrictPermissions && permission[1]) {
                        this.elem.classList.remove('d-none');
                    }
                });
            }
        });
    }
}
