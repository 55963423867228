import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentModel } from 'src/app/modules/payments/models/payment.model';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { utcConvert } from 'src/app/shared/utils/utils';
@Component({
    selector: 'app-payments-list',
    templateUrl: './payments-list.component.html',
    styleUrls: ['./payments-list.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, AsyncPipe, DatePipe, TranslateModule]
})
export class PaymentsListComponent {
    @Input() payments: PaymentModel[];
    utcConvert = utcConvert
    constructor(public settingsService: SettingsService) { }
    getTotal() {
        return this.payments.map(payment => parseFloat(payment.total)).reduce((acc, current) => acc + current, 0).toFixed(2);
    }
}
