import { Component, Input, OnInit } from '@angular/core';
import { InvoiceModel } from '../../../../models/invoice.model';
import { NoCommaPipe } from '../../../../../../../shared/pipes/no-comma.pipe';
import { LabelPipe } from '../../../../../../../shared/pipes/label.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NoItemToShowComponent } from '../../../../../../../shared/components/no-item-to-show/no-item-to-show.component';
import { NgIf, NgFor, UpperCasePipe, DecimalPipe, DatePipe } from '@angular/common';


@Component({
    selector: 'app-invoices-to-export-list',
    templateUrl: './invoices-to-export-list.component.html',
    styleUrls: ['./invoices-to-export-list.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, NoItemToShowComponent, UpperCasePipe, DecimalPipe, DatePipe, TranslateModule, LabelPipe, NoCommaPipe]
})
export class InvoicesToExportListComponent implements OnInit {

    @Input() invoicesList: InvoiceModel[];

    constructor() { }

    ngOnInit(): void {
    }

}
