import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';

import { MaterialModule } from 'src/app/shared/modules/material.module';

import { QrScanComponent } from './qr-scan.component';
import { QRScanRoutes } from './qr-scan.routing';
import { httpTranslateLoader } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { NavbarModule } from 'src/app/shared/components/navbar/navbar.module';
import { SidebarModule } from 'src/app/shared/components/sidebar/sidebar.module';
import { CategoriesModule } from '../categories/categories.module';
import { QrMenuProductComponent } from './qr-menu-product/qr-menu-product.component';
import { ShoppingListComponent } from '../orders/components/shopping-list/shopping-list.component';
import { ShoppingCartComponent } from '../orders/components/shopping-cart/shopping-cart.component';
import { AddProductToCartModalComponent } from './qr-menu-product/add-product-to-cart-modal/add-product-to-cart-modal.component';
import { CallWaiterComponent } from '../orders/components/call-waiter/call-waiter.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';


@NgModule({
    imports: [
        SharedComponentsModule,
        RouterModule.forChild(QRScanRoutes),
        TablesModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        RouterModule,
        NavbarModule,
        SidebarModule,
        CategoriesModule,
        QrScanComponent,
        QrMenuProductComponent,
        ShoppingListComponent,
        ShoppingCartComponent,
        AddProductToCartModalComponent,
        CallWaiterComponent,
        LazyLoadImageModule
    ],
})
export class QRScanModule { }
