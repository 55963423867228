export class PaymentModel {
    id: number;
    type: string;
    total: string;
    order_products_ids: string[];
    order_products: any[]
    actions?: {
        can_be_deleted: boolean;
        can_be_edited: boolean;
    };
}