import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { TypeModel } from '../models/type.model';
import { HttpParams } from '@angular/common/http';
import { getLang } from 'src/app/shared/utils/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class TypesService extends BaseService {
    itemsList$: BehaviorSubject<TypeModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<TypeModel> = new Subject();

    constructor(
        protected _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    protected getRequestOptions() {
        return { params: new HttpParams().set("locale", getLang()) };
    }

    createType(type: TypeModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.createResorce(apiEndpoints.categories, type).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create type', error)

        });
    }

    getType(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.types}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get type', error)
        });
    };

    getTypes(filter?: string, paginationSettings?: string) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(apiEndpoints.types + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get types list', error)
        });
    }

    getTypesList$(): Observable<any> {
        return this.getResorce(apiEndpoints.types);
    }

    getTypes$(): Observable<any> {
        return this.getResorce(apiEndpoints.types + '/dropdown-values');
    }

    updateType(type: TypeModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.types + '/' + type.id, type).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data)
            },
            error: error => this.reportError('Error on update type', error)
        });
    }

    deleteType(type) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitDeleteRequest(apiEndpoints.types + '/' + type.id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this.getTypes();
            },
            error: error => this.reportError('Error on delete type', error)
        });
    }
}
