import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerRequestsService } from 'src/app/modules/customer-requests/services/customer-requests.service';

@Component({
  selector: 'app-call-waiter-form',
  templateUrl: './call-waiter-form.component.html',
  styleUrls: ['./call-waiter-form.component.css'],
  standalone:true,
  imports:[TranslateModule, CommonModule],
})
export class CallWaiterFormComponent implements OnInit {
  @Input() requestAlreadyExist; 
  @Input() callWaiterForm: FormGroup;
  @Output() onCallWaiter= new EventEmitter();
  payments = [];
  requestType = "";

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    console.log(this.requestAlreadyExist);
    
  }
  callWaiter(e) {
    this.onCallWaiter.emit(e)
  }
}
