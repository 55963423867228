import { Component } from '@angular/core';
import { BuildDetailsService } from '../../services/build-details.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html',
    standalone: true,
    imports: [DatePipe]
})

export class FooterComponent {
    public test: Date = new Date();
    public currentBuildNumber: string;

    constructor(private _buildDetailsService: BuildDetailsService) {
        this.currentBuildNumber = this._buildDetailsService.buildDetails.buildNumber;
    }
}
