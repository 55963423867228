<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row mt-5">
        <div class="col-md-6 text-center d-flex flex-column">
            <h5 class="font-weight-bold">{{ "Imagine" | translate }}</h5>
            <div class="fileinput fileinput-new {{data?.profile_pic && 'd-flex flex-column align-items-center h-100'}}">
                <div class="image-wrapper mb-2 text-center">
                    <div class="fileinput-new thumbnail">
                        <img loading="lazy" src="{{ data?.profile_pic || './assets/img/image_placeholder.jpg'}}"
                            onerror='this.onerror = null; this.src="./assets/img/image_placeholder.jpg"'
                            class="max-height" alt="currentProfilePicture">
                    </div>
                </div>
                <div *ngIf="data?.profile_pic" class="d-flex justify-content-center">
                    <button class="btn btn-danger btn-round" (click)="removeProfilePic.emit()">{{ "DeleteImage" |
                        translate}}</button>
                </div>
            </div>
        </div>
        <div class="col-md-6 d-flex flex-column align-items-center justify-content-between">
            <h5>{{ "RecommendedDimensions" | translate }} imagine (350 x 350px)</h5>
            <div class="fileinput fileinput-new" data-provides="fileinput">
                <div class="image-wrapper mb-2 text-center">
                    <div class="fileinput-new thumbnail">
                        <img loading="lazy" src="./assets/img/image_placeholder.jpg" alt="...">
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail image-wrapper"></div>
                </div>
                <div class="d-flex justify-content-center">
                    <span class="btn btn-primary btn-round btn-file">
                        <span class="fileinput-new">{{"SelectImage" | translate}}</span>
                        <span class="fileinput-exists">{{"Change" | translate}}</span>
                        <input #fileInputToUpload type="file" name="logo"
                            (change)="handleFileInput($event.target.files, 'profile_pic')" />
                    </span>
                    <button #removeProfileImg class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput"
                        (click)="removeImage(fileInputToUpload)">
                        <i class="fa fa-times"></i> {{"Delete" | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="mb-0">
        <div class="w-100 modal-footer border-0 p-0 pt-4">
            <button type="button" class="btn btn-secondary pull-right" (click)="dialogRef.close()">{{ 'Close' | translate
                }}</button>
            <button type="button" class="btn btn-primary pull-right" (click)="saveAndExit()">{{ 'SaveAndClose' |
                translate }}</button>
            <button type="button" class="btn btn-primary pull-right" (click)="onSubmit()">{{ 'Save' | translate
                }}</button>
        </div>
    </div>
</form>