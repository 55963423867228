import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';

@Pipe({
    name: 'CalculateTotalOrderValuePipe',
    standalone: true
})

export class CalculateTotalOrderValuePipe implements PipeTransform {

    constructor(private _settingsService: SettingsService) { }
    transform(row: any) {

        if (row.length) {
            return row.map(item => parseFloat(item.quantity) * parseFloat(item.unitary_price)).reduce((acc, current) => acc + current, 0).toFixed(2) + ' ' + this._settingsService.currency$.value;
        }
        return '0.00' + this._settingsService.currency$.value;
    }
}