import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InvoiceModel } from '../models/invoice.model';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/shared/services/base.service';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { RequestMethodsEnum } from 'src/app/shared/enums/RequestMethods.enum';

@Injectable({
    providedIn: 'root'
})

export class InvoicesService extends BaseService {

    itemsList$: BehaviorSubject<InvoiceModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedInvoice$: BehaviorSubject<InvoiceModel> = new BehaviorSubject(undefined);

    // public readonly invoicesFilters = {
    //     owner_id: 0,
    //     'subscriptionPayment.subscription.rentalSpace.market_id': 0,
    //     status: 'overdue_or_overdue_soon',
    // };

    constructor(
        protected _apiRequestService: ApiRequestService,
        private _router: Router,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    getInvoices(filter?: string, paginationSettings?: string) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.invoices + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                const invoicesWithIssuedForm = apiResponse.data.map(invoice => {
                    return {
                        ...invoice,
                        invoice_series_and_no: `${invoice.invoice_series} - ${invoice.invoice_no}`,
                    }
                });
                this.itemsList$.next(invoicesWithIssuedForm);
                this.itemsData$.next({ ...apiResponse, data: invoicesWithIssuedForm });
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get invoices', error)
        })
    }

    getInvoice(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.invoices + `/${id}`).subscribe({
            next: apiResponse => {
                this.selectedInvoice$.next(apiResponse.data);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get invoice', error)
        })
    }

    createInvoice(invoice: InvoiceModel, filters?: string) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPostRequest(apiEndpoints.invoices, invoice).subscribe({
            next: apiResponse => {
                this.selectedInvoice$.next(apiResponse.data);
                this._router.url !== '/admin/sessions-report' && this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Create, this.itemsList$);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create invoice', error)
        })
    }

    updateInvoice(invoice: InvoiceModel) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPutRequest(apiEndpoints.invoices + '/' + invoice.id, invoice).subscribe({
            next: apiResponse => {
                this.selectedInvoice$.next(apiResponse.data);
                this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Update, this.itemsList$);
                this._appSettingsSerice.isLoading.next(false);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on update invoice', error)
        });
    }

    generateInvoicePDF(id: number, print?: boolean) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitGetRequest(apiEndpoints.invoices + `/${id}/generate-pdf`, { responseType: 'blob' }).subscribe({
            next: apiResponse => this.openFile(apiResponse, (print && print)),
            error: error => this.reportError('Error on generate invoice', error)
        })
    }


    reverseInvoice(id: number, payload: any): void {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitPostRequest(apiEndpoints.invoices + `/${id}/reverse`, { invoice_services: payload }).subscribe({
            next: () => {
                this.selectedInvoice$.next(undefined);
                this.getInvoices();
            },
            error: error => this.reportError('Error on reverse invoice', error)
        });
    }

    openFile(file: any, print?: boolean) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        this._router.navigate([]).then(() => {
            const openedWindow = window.open(a.href, '_blank');
            print && openedWindow.print();
        });
        this._appSettingsSerice.isLoading.next(false);
    }

    downloadFile(file: any, fileName: string) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        this._router.navigate([]).then(() => { window.open(a.href, '_blank'); });
        a.click();
        this._appSettingsSerice.isLoading.next(false);
    }

    refreshListAfterChange(serverResponse: any, type: RequestMethodsEnum, list$: BehaviorSubject<any>) {
        const newList = [...list$.value];
        const index = newList.findIndex(item => item.id === serverResponse?.id);

        switch (type) {
            case RequestMethodsEnum.Create:
                newList.push(serverResponse);
                break;

            case RequestMethodsEnum.Update:
                newList[index] = serverResponse;
                break;

            case RequestMethodsEnum.Delete:
                newList.splice(index, 1);
                break;
        }

        list$.next(newList);
    }
}
