import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { UserProfileService } from '../../services/user-profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'app-dropdown-actions',
    templateUrl: './dropdown-actions.component.html',
    styleUrls: ['./dropdown-actions.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, TranslateModule]
})
export class DropdownActionsComponent implements OnInit {

    public availableActionsCounter: number = 0;

    @Input() rowAvailableActions: any;
    @Input() rowPossibleActions: any;
    @Input() rowIndex: number;
    @Input() row: any
    @Input() icon: string;

    @Output() actionType = new EventEmitter<any>();

    constructor(private _userProfileService: UserProfileService) { }

    ngOnInit(): void {
        this.generateAvailableActionsFromPermissions();

        Object.keys(this.rowAvailableActions).forEach(prop => {
            this.availableActionsCounter += Number(prop !== 'order_id' && this.rowAvailableActions[prop]);
        });
    }

    generateAvailableActionsFromPermissions() {
        this.rowAvailableActions = { ...this.rowAvailableActions };

        this.rowPossibleActions.forEach(action => {
            if (this._userProfileService.checkForPermission(action.permission)) {
                this.rowAvailableActions[action.permission] = true
            };
        });
    }

    checkPermissionFromResponse(permission: string) {
        if (!permission) return;

        const permissions = permission.split('.');
        let permissionPath = this.row;
        permissions.forEach(path => {
            permissionPath = permissionPath[path];
        });
        return permissionPath;
    }

    tableActionClicked(index: number, type: TableActionTypeEnum) {
        this.actionType.emit({ index, type });
    }
}
