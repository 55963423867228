<div class="row dialog-top-header">
    <div class="col-8">
        <div class="d-flex align-items-center">
            <h1 class="mb-0 text-nowrap" mat-dialog-title>
                {{ "ReverseTheInvoice" | translate }}:
                <span class="text-warning mr-2 font-weight-bold small">{{ selectedInvoice?.invoice_series }} - {{
                    selectedInvoice?.invoice_no }} / {{ selectedInvoice?.invoice_date | date: "dd.MM.yyyy" }}</span>
            </h1>
        </div>
    </div>
    <div class="col-4 d-flex align-items-center justify-content-end">
        <button class="btn btn-secondary-custom small-btn pull-right" (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
</div>

<div class="container-fluid px-4">
    <div class="mat-dialog-content position-relative">
        <app-preloader></app-preloader>

        <div class="spacer-2"></div>
        <app-gradient-border-title>{{ "ReverseInvoice" | translate }}</app-gradient-border-title>
        <div class="spacer-1"></div>

        <div class="spacer-1"></div>
        <app-gradient-border-title>{{ "ReverseInvoice" | translate }}</app-gradient-border-title>
        <div class="spacer-1"></div>

        <div class="row container-fluid ml-0">
            <div class="col-6 px-0">
                <div class="row py-1">
                    <div class="col-6">{{"InvoiceTotalValue" | translate}}</div>
                    <div class="col-6 font-weight-bold">{{parseFloat(selectedInvoice?.value).toFixed(2) }} {{_settingsService.currency$ | async}}
                    </div>
                </div>
                <div class="row py-1">
                    <div class="col-6">{{"ValuePaid" | translate }}</div>
                    <div class="col-6 font-weight-bold">{{selectedInvoice?.total_paid }} {{_settingsService.currency$ | async}}</div>
                </div>
            </div>
            <div class="col-6 px-0">
                <div class="row py-1">
                    <div class="col-6 text-right">{{"ValueNotPaid" | translate}}</div>
                    <div class="col-6 font-weight-bold text-right">{{ (selectedInvoice?.value -
                        selectedInvoice?.total_paid).toFixed(2)}} {{_settingsService.currency$ | async}}</div>
                </div>
                <div class="row py-1">
                    <div class="col-6 text-right">{{"ValueUndistributed" | translate }}</div>
                    <div class="col-6 font-weight-bold text-right">{{ +undistributedValue }} {{_settingsService.currency$ | async}}</div>
                </div>
            </div>
        </div>
        <div class="spacer-2"></div>

        <button class="btn btn btn-secondary pull-right" (click)="dialogRef.close()">{{ "Close" | translate }}</button>
        <button class="btn btn-primary pull-right" (click)="reverseInvoice()" [disabled]="!reverseStatus">{{
            "ReverseInvoice" | translate }}</button>
        <div class="spacer-1"></div>
    </div>
</div>