import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from './../services/notify.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { LocalStorageService, AUTH_TOKEN, CURRENT_USER } from '../services/local-storage.service';
import { Router } from '@angular/router';
import { apiEndpoints } from '../services/api-request.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { getErrors } from '../utils/errors.utils';
import { customerVerification } from '../services/customer-id-check.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    noAuthTokenRoutes: string[] = [
        apiEndpoints.login,
        apiEndpoints.resetPassword,
        // other routes to be added
    ];
    multipartRoutes: string[] = [
        apiEndpoints.userProfilePicture,
        apiEndpoints.ownerProfilePicture,
        apiEndpoints.brandProfileLogoAndCover,
        apiEndpoints.productImage,
        apiEndpoints.userProfilePicture
        // other routes to be added
    ]

    get token(): string {
        return this._localStorageService.getValue(AUTH_TOKEN)
    }

    constructor(
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _translateService: TranslateService,
        private _notifyService: NotifyService,
    ) { }


    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let headers: HttpHeaders = req.headers;

        headers = this.addAuthTokenHeaders(req.url, headers);
        headers = this.addContentTypeHeaders(req.url, headers);

        const request = req.clone({ headers });

        if (window.location.pathname === '/' && !localStorage.getItem(CURRENT_USER) && request.url.split('/').pop() === 'user') {
            !this._localStorageService.getValue('maintenance') && this._router.navigate(['/login']);
            return;
        }

        return next.handle(request)
            .pipe(
                tap((response: HttpResponse<any>) => {
                    if (response instanceof HttpResponse && response.status >= 200 && response.status < 300) {
                        this._handleRequestResponse(response, request);
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    if (req.context.get(customerVerification)) {
                        return;
                    }
                    this._handleRequestError(error);
                    return throwError(() => error);
                }));
    }

    private _handleRequestError(error: any) {
        if (error.status === 400) {
            this._notifyService.showNotification({
                icon: 'add_alert',
                title: this._translateService.instant('Error'),
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 3000,
                from: 'bottom',
                align: 'center',
            });
        }

        if (error.status === 401) {
            this._router.navigate(['/login']);

            this._notifyService.showNotification({
                icon: 'add_alert',
                title: this._translateService.instant('Error'),
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 3000,
                from: 'bottom',
                align: 'center',
            });
        }

        if (error.status === 402) {
            this._notifyService.showNotification({
                icon: 'add_alert',
                title: this._translateService.instant('Attention'),
                message: this._translateService.instant(error.error.message),
                type: 'warning',
                timer: 0,
                from: 'bottom',
                align: 'center',
            });
        }

        if (error.status === 403) {
            this._router.navigate(['/admin/not-allowed']);
            this._notifyService.showNotification({
                icon: 'error_outline',
                title: this._translateService.instant('Error'),
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 3000,
                from: 'bottom',
                align: 'center',
            });
        }

        if (error.status === 404) {
            this._notifyService.showNotification({
                icon: 'error_outline',
                title: this._translateService.instant('Error'),
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 3000,
                from: 'bottom',
                align: 'center',
            });
        }

        if (error.status === 409) {
            this._notifyService.showNotification({
                icon: 'error_outline',
                title: this._translateService.instant('Error'),
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 3000,
                from: 'bottom',
                align: 'center',
            });
        }

        if (error.status === 418) {
            this._router.navigate(['/public/no-access']);
            this._notifyService.showNotification({
                icon: 'error_outline',
                title: this._translateService.instant('Error'),
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 3000,
                from: 'bottom',
                align: 'center',
            });
            console.log(error.error)
            getErrors(error.error);
        }

        if (error.status === 422) {
            this._notifyService.showNotification({
                icon: 'error_outline',
                title: this._translateService.instant('Error'),
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 3000,
                from: 'bottom',
                align: 'center',
            });
            console.log(error.error)
            getErrors(error.error);
        }

        if (error.status === 500) {
            console.log('%c EROARE SERVER!', 'background: red; color: white');
            this._notifyService.showNotification({
                icon: 'error_outline',
                title: this._translateService.instant('Error') + ` server`,
                message: this._translateService.instant(error.error.message),
                type: 'danger',
                timer: 0,
                from: 'bottom',
                align: 'center',
                width: '6',
            });
        }

        if (error.status === 503) this._router.navigate(['under-maintenance']);

    }


    private _handleRequestResponse(response: HttpResponse<any>, request: HttpRequest<any>) {
        switch (response.status) {
            case 200:
                this.urlHasCustomMessage(request.url) ?
                    (response.body.message ? this._notifyService.customMessageFormResponse(response.body.message) : null) :
                    request.method === 'PUT' && this._notifyService.successfullyUpdated();
                break;
            case 201:
                if (this.urlHasCustomMessage(request.url)) {
                    response.body.message
                        ? this._notifyService.customMessageFormResponse(response.body.message)
                        :
                        (request.method === 'POST' && this._notifyService.successfullyAdded());
                    return;
                }
                request.method === 'POST' && this._notifyService.successfullyAdded();
                break;
            case 204:
                request.method === 'DELETE' && this._notifyService.successfullyDeleted();
                break;
            default:
                break;
        }
    }

    addAuthTokenHeaders(requestUrl: string, headers: HttpHeaders) {
        // adding auth token only to routes that require it
        const token = this._localStorageService.getValue(AUTH_TOKEN);
        if (!this.noAuthTokenRoutes.find(route => requestUrl.includes(route))) {
            if (token) {
                headers = headers.append('Authorization', `Bearer ${token}`);
            }
        }
        return headers
    }

    addContentTypeHeaders(requestUrl: string, headers: HttpHeaders) {
        // adding headers only to routes that require it
        if (!this.multipartRoutes.find(route => requestUrl.includes(route))) {
            const contentLang = localStorage.getItem('lang')
            headers = headers.set('Content-Type', 'application/json');
            headers = contentLang ? headers.set('Content-Language', contentLang) : headers;
        }
        return headers;
    }

    urlHasCustomMessage(requestUrl: string): boolean {
        const URLs = ['reset-password', 'email-reset-link'];
        return URLs.some(url => requestUrl.includes(url));
    }
}
