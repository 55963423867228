import { Component, Input, OnInit } from '@angular/core';
import { AllergenModel } from 'src/app/modules/allergens/models/alergen.model';
import { ProductModel } from '../../models/product.model';
import { ProductsAllergensService } from '../../services/product-allergens.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-product-allergens',
    templateUrl: './product-allergens.component.html',
    styleUrls: ['./product-allergens.component.css'],
    standalone: true,
    imports: [NgFor, MatCheckboxModule]
})
export class ProductAllergensComponent implements OnInit {
    @Input() product: ProductModel;
    @Input() productAllergens: AllergenModel[];

    constructor(private _productAllergensService: ProductsAllergensService) { }

    ngOnInit() { }

    addOrRemoveProductAllergen(e, el) {
        e.checked ?
            this._productAllergensService.addProductAllergen({ product_id: this.product.id, allergen_id: el.id }) :
            this._productAllergensService.deleteProductAllergen(el.product_allergen)
    }
}
