<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
        <small>{{'Brand' | translate}}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn" (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12 pb-3">
        <h1 class="mb-0 dialog-title">
            {{'Edit' | translate}}: {{currentBrandProfile?.name}}
        </h1>
    </div>
    <div class="card-header card-header-tabs card-header-secondary px-4">
        <div class="nav-tabs-navigation">
            <ul role="tablist" class="nav nav-pills nav-pills-primary">
                <li class="nav-item">
                    <a data-toggle="tab" href="#general-info" role="tablist" class="nav-link active">{{ "GeneralInfo" | translate }}</a>
                </li>
                <li class="nav-item">
                    <a data-toggle="tab" href="#images" role="tablist" class="nav-link">{{ "Images" | translate }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<mat-dialog-content>
    <div class="tab-content tab-space pb-0">
        <div id="general-info" class="tab-pane active">
            <app-brand-general-info-tab [data]="currentBrandProfile" (closeModal)="dialogRef.close()" (saveAndClose)="saveAndClose($event)"></app-brand-general-info-tab>
        </div>

        <div id="images" class="tab-pane">
            <app-brand-images-tab [data]="currentBrandProfile" (closeModal)="dialogRef.close()" (saveAndClose)="saveAndClose($event)" (removeLogoBrand)="removeLogoBrand()" (removeCoverBrand)="removeCoverBrand()"></app-brand-images-tab>
        </div>
    </div>
</mat-dialog-content>