import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { OrderProductsService } from 'src/app/modules/orders/services/order-products.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';

@Component({
    selector: 'app-add-product-to-cart-modal',
    templateUrl: './add-product-to-cart-modal.component.html',
    styleUrls: ['./add-product-to-cart-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        ReactiveFormsModule,
        FormValidatorDirective,
        MatFormFieldModule,
        MatInputModule,
        FormFieldErrorComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class AddProductToCartModalComponent extends BaseModalComponent implements OnInit {
    public form: FormGroup;
    public quantity = 1
    @Output() addProduct = new EventEmitter<any>();

    constructor(
        public dialogRef: MatDialogRef<AddProductToCartModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder,
        private _orderProductService: OrderProductsService,
        public settingsService: SettingsService
    ) {
        super(dialogRef, _orderProductService);
    }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            quantity: this.quantity,
            observations: '',
            product_id: this.data.id,
        });
    }

    addProductToCart() {
        this.addProduct.emit(this.form.value);
        this.dialogRef.close();
    }
    modifyQuantity(operation) {
        if (operation === 'add') { this.quantity++ }
        if (operation === 'remove' && this.quantity > 1) { this.quantity-- }
        this.form.controls.quantity.setValue(this.quantity)
    }

}
