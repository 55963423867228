import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-generate-xls',
    templateUrl: './generate-xls.component.html',
    styleUrls: ['./generate-xls.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class GenerateXlsComponent implements OnInit {

  @Output() downloadXLS: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
