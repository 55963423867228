import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';

import { TablesModule } from 'src/app/shared/components/tables/tables.module';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { TypesComponent } from './types.component';
import { TypesModalComponent } from './components/types-modal/types-modal.component';



@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    TypesComponent,
    TypesModalComponent
],
})
export class TypesModule { }
