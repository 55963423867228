import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { UpdateDateRangeValueDirective } from '../../directives/update-date-range-value.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-date-interval-picker',
    templateUrl: './date-interval-picker.component.html',
    styleUrls: ['./date-interval-picker.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, MatFormFieldModule, MatDatepickerModule, ReactiveFormsModule, UpdateDateRangeValueDirective, MatInputModule, FormFieldErrorComponent, TranslateModule]
})
export class DateIntervalPickerComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() label: string;
    @Input() filterControls: string[];
    @Input() defaultRange: any;
    @Input() data_cy: any;

    constructor() { }

    ngOnInit(): void {
        const startDate = this.form.controls[this.filterControls[0]];
        const endDate = this.form.controls[this.filterControls[1]];

        startDate.setValue(startDate.value || (this.defaultRange?.start ? moment(this.defaultRange?.start).format('YYYY-MM-DD') : ''), { emitEvent: false });
        endDate.setValue(endDate.value || (this.defaultRange?.end ? moment(this.defaultRange?.end).format('YYYY-MM-DD') : ''), { emitEvent: false });
    }
}
