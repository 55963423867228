import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-up-to-date-',
    templateUrl: './up-to-date.component.html',
    styleUrls: ['./up-to-date.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class UpToDateComponent {

    constructor(public dialogRef: MatDialogRef<UpToDateComponent>) {
    }

    reloadPage() {
        window.location.reload();
    }
}
