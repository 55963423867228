import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-simple-input-text',
    templateUrl: './simple-input-text.component.html',
    styleUrls: ['./simple-input-text.component.css'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormFieldModule, MatInputModule, ReactiveFormsModule, TranslateModule]
})
export class SimpleInputTextComponent implements OnInit {

    @Input() control: FormControl;
    @Input() placeholder: string;
    @Input() label: string;
    @Input() data_cy: string;
    constructor() { }

    ngOnInit(): void { }
}
