import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { validateAllFormFields, ValidationMessages } from 'src/app/shared/utils/form.utils';
import { getValueFromObject } from 'src/app/shared/utils/object.utils';
import { RegexValidator } from 'src/app/shared/utils/regex-validator.utils';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-input-with-focus',
    templateUrl: './input-with-focus.component.html',
    styleUrls: ['./input-with-focus.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, NgIf, MatInputModule, ReactiveFormsModule, NgFor, TranslateModule]
})
export class InputWithFocusComponent implements OnInit {
    public form: FormGroup;
    public validationMessages = ValidationMessages;
    public focusOnInput = false;
    public savePrice = false;

    @Input() itemsList;
    @Input() index;
    @Input() disabledInput: boolean;
    @Input() categoryPriceName;

    @Output() updateListMethod: any = new EventEmitter<any>();
    @Output() isFormValid = new EventEmitter<boolean>();

    @ViewChild('matFormField') matFormField: MatFormField;
    @ViewChild('priceInput') priceInput: ElementRef;

    constructor(private _formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            price: [getValueFromObject(this.itemsList[this.index], this.categoryPriceName), Validators.compose([
                Validators.required,
                Validators.pattern(RegexValidator.FLOAT.regex)
            ])]
        });

        if (!getValueFromObject(this.itemsList[this.index], this.categoryPriceName)) {
            if(this.itemsList[this.index].price) {
                this.form.controls.price.patchValue(parseFloat(this.itemsList[this.index].price));
                return;
            }
            
            this.form.controls.price.patchValue('0');
        }
    }

    onFocus() {
        this.focusOnInput = true;
    }

    onFocusOut() {
        if (this.form.valid) {
            this.focusOnInput = false;
            this.savePrice = true;
            this.updateListMethod.emit(this.form.controls.price.value);
        } else {
            validateAllFormFields(this.form);
            this.isFormValid.emit(false);
        }
    }

    enterSubmit(event) {
        event.keyCode === 13 && this.onFocusOut();
    }

    validateForm() {
        validateAllFormFields(this.form);
    }

    moveFocus() {
        this.priceInput?.nativeElement.focus();
    }

    resetForm() {
        this.form.reset();
    }
}
