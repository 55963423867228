import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { DropdownActionsComponent } from '../../../../dropdown-actions/dropdown-actions.component';
import { TableCellComponent } from '../table-cell/table-cell.component';
import { StrippedTableDirective } from '../../../../../directives/stripped-table.directive';
import { ContentLanguageDropdownComponent } from '../../../../content-language-dropdown/content-language-dropdown.component';
import { NgIf, NgFor } from '@angular/common';



@Component({
    selector: 'app-mobile-table',
    templateUrl: './mobile-table.component.html',
    styleUrls: ['./mobile-table.component.css'],
    standalone: true,
    imports: [NgIf, ContentLanguageDropdownComponent, NgFor, StrippedTableDirective, TableCellComponent, DropdownActionsComponent, MatExpansionModule, TranslateModule]
})
export class MobileTableComponent implements OnInit {
    public hasHiddenCols: boolean;

    @Input() hideIndex: number;
    @Input() withIndex: number;
    @Input() path: string;
    @Input() headerRow: TableDataHeaders[];
    @Input() rowPossibleActions: RowPossibleActions[];
    @Input() icon: string;
    @Input() service: any;
    @Input() tableData: any;
    @Input() localeLanguageDropdown: boolean;
    @Input() langChanged: any;

    @Output() onPageChanged = new EventEmitter<PageEvent>();
    @Output() actionType = new EventEmitter<any>();
    @Output() changeLang = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
        this.hasHiddenCols = !!this.headerRow.find(col => col.hideOnMobile === true);
    }
}
