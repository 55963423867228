import { Component, Input, OnInit } from '@angular/core';
import { InvoiceModel } from '../../../models/invoice.model';
import { SETTINGS } from 'src/app/shared/constants';
import { calculateTotal } from 'src/app/shared/utils/calculations.utils';
import { NoCommaPipe } from '../../../../../../shared/pipes/no-comma.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { StrippedTableDirective } from '../../../../../../shared/directives/stripped-table.directive';
import { NgFor, DecimalPipe } from '@angular/common';
import { GradientBorderTitleComponent } from '../../../../../../shared/components/gradient-border-title/gradient-border-title.component';

@Component({
    selector: 'app-invoice-general-info-tab',
    templateUrl: './invoice-general-info-tab.component.html',
    styleUrls: ['./invoice-general-info-tab.component.css'],
    standalone: true,
    imports: [GradientBorderTitleComponent, NgFor, StrippedTableDirective, DecimalPipe, TranslateModule, NoCommaPipe]
})
export class InvoiceGeneralInfoTabComponent implements OnInit {

    public totalValue: string;

    @Input() selectedInvoice: InvoiceModel;

    constructor() { }

    ngOnInit(): void {
        this.totalValue = calculateTotal(this.selectedInvoice.invoice_services, null, 'service_value_including_vat');
    }
}
