
<!-- <div class="container">
    <div class="row">
        <div class="dialog-top-header d-flex align-items-center w-100" mat-dialog-title id="mat-mdc-dialog-title-0">
            <div class="col-9 dialog-pre-title">
                <small class="d-flex align-items-center">
                    <h6 class="text-primary mb-0">{{"CustomerAdd" | translate}}</h6>
                </small>
            </div>
            <div class="col-3 text-right">
                <button class="btn btn-secondary-custom"
                        (click)="dialogRef.close()">
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
</div> -->
<mat-dialog-content class="pt-5">
    <div class="d-flex justify-content-center align-items-center mb-4">
        <i class="material-icons-outlined text-success fs-5">account_circle</i>
    </div>
    <form [formGroup]="form" appFormValidator class="py-4">
        <div class="container px-0">
            <div class="form-row d-flex align-items-center">
                <div class="col-12">
                    <mat-form-field class="customized" >
                        <mat-label>{{"CustomerName" | translate}}</mat-label>
                        <input matInput type="text" id="name" class="lh-custom" formControlName="name"
                            (focus)="$event.target.select()">
                    </mat-form-field>
                </div>
                <!-- <div class="col-5">
                    <button type="button" class="btn btn-primary pull-right" (click)="onCreateCustomer.emit(form.controls.name.value)">{{ 'AddCustomer' | translate }}</button>
            
                </div> -->
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <button type="button" class="btn btn-secondary pull-right mx-3" (click)="dialogRef.close()">{{ 'Cancel' | translate }}</button>
                    </div>
                    <div class="">
                        <button type="button" class="btn btn-primary pull-right" (click)="onCreateCustomer.emit(form.controls.name.value)">{{ 'AddCustomer' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>