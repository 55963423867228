import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { InvoiceTabsEnum } from '../../enums/invoice-tabs.enum';
import { InvoiceModel } from '../../models/invoice.model';
import { InvoicesService } from '../../services/invoices.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe, DatePipe } from '@angular/common';
import { InvoiceGeneralInfoTabComponent } from './invoice-general-info-tab/invoice-general-info-tab.component';

@UntilDestroy()
@Component({
    selector: 'app-invoices-modal',
    templateUrl: './invoices-modal.component.html',
    styleUrls: ['./invoices-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, InvoiceGeneralInfoTabComponent, LowerCasePipe, DatePipe, TranslateModule]
})
export class InvoicesModalComponent extends BaseModalComponent implements OnInit {

    public InvoiceTabsEnum = InvoiceTabsEnum;
    public selectedTab = InvoiceTabsEnum.INVOICE_GENERAL_INFO;
    public selectedInvoice: InvoiceModel;
    public PermissionsEnum = PermissionsEnum;

    constructor(
        public dialogRef: MatDialogRef<InvoicesModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public invoicesService: InvoicesService,
        public appSettings: AppSettingsService,
    ) {
        super(dialogRef, invoicesService);
        this.selectedInvoice = _data;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.selectedInvoice?.id && this.invoicesService.getInvoice(this.selectedInvoice.id);
        this.invoicesService.selectedInvoice$.pipe(filter(invoice => !!invoice), untilDestroyed(this)).subscribe(invoice => this.selectedInvoice = invoice);
    }

    ngOnDestroy(): void {
        this.invoicesService.selectedInvoice$.next(undefined);
    }

    selectTab(selected: InvoiceTabsEnum) {
        this.selectedTab = selected;
    }
}
