<section class="roles-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <span>{{ 'Roles' | translate | uppercase }}</span>
                <button id="add-role" mat-raised-button class="btn btn-white text-dark" (click)="addEntry()"
                    [appRestrictPermissions]="PermissionsEnum.ROLES_ADD_AND_EDIT">
                    <i class="material-icons mr-1">add</i> {{ 'Add' | translate }}
                </button>
            </div>
            <div class="col-12">
                <app-regular-table #table [headerRow]="tableHeaders" [service]="rolesService" [dataList]="roles()"
                    [rowPossibleActions]="rowPossibleActions" [tableId]="'roles-records-tbl'"
                    (actionType)="tableActionClicked($event)">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>