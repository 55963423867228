import { Component, Input } from '@angular/core';
import { GoToLinkModel } from 'src/app/shared/models/go-to-link.model';
import { UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PreloaderComponent } from '../../shared/components/preloader/preloader.component';
@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    standalone: true,
    imports: [PreloaderComponent, TranslateModule, UpperCasePipe]
})
export class ReportsComponent {

    @Input() title: string;
    @Input() goToLink: GoToLinkModel;

    constructor() { }

}
