import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { getValueFromObjectDecorator } from 'src/app/shared/decorators/get-value-from-object.decorator';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { isMobile, isTablet } from 'src/app/shared/utils/responsive.utils';
import { TranslateModule } from '@ngx-translate/core';
import { CalculateTotalOrderValuePipe } from '../../../../../pipes/calculate-total-order-value.pipe';
import { AllergensCounterPipe } from '../../../../../pipes/count-with-popover.pipe';
import { DashSepareFieldsPipe } from '../../../../../pipes/dash-separe-fields.pipe';
import { SplitFieldsPipe } from '../../../../../pipes/split-fields.pipe';
import { PriceFormatingPipe } from '../../../../../pipes/price-formating.pipe';
import { LabelPipe } from '../../../../../pipes/label.pipe';
import { ModelWithSubPropertiesPipe } from '../../../../../pipes/model-with-sub-properties.pipe';
import { DisplaySymbolPipe } from '../../../../../pipes/display-symbols.pipe';
import { FormatDatePipe } from '../../../../../pipes/format-date.pipe';
import { UserStatusesPipe } from '../../../../../pipes/user-statuses.pipe';
import { FormatCreatedAtDirective } from '../../../../../directives/format-created-at.directive';
import { TransformToIconsDirective } from '../../../../../directives/transform-to-icons.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreateBadgeDirective } from '../../../../../directives/create-badge.directive';
import { CreateTooltipDirective } from '../../../../../directives/create-tooltip.directive';
import { NgFor, NgSwitch, NgSwitchCase, NgIf, NgSwitchDefault, AsyncPipe, DatePipe } from '@angular/common';

@getValueFromObjectDecorator
@Component({
    selector: 'app-table-cell',
    templateUrl: './table-cell.component.html',
    styleUrls: ['./table-cell.component.scss'],
    standalone: true,
    imports: [NgFor, NgSwitch, NgSwitchCase, CreateTooltipDirective, CreateBadgeDirective, MatTooltipModule, NgIf, NgSwitchDefault, TransformToIconsDirective, FormatCreatedAtDirective, AsyncPipe, DatePipe, UserStatusesPipe, FormatDatePipe, DisplaySymbolPipe, ModelWithSubPropertiesPipe, LabelPipe, PriceFormatingPipe, SplitFieldsPipe, DashSepareFieldsPipe, AllergensCounterPipe, CalculateTotalOrderValuePipe, TranslateModule]
})
export class TableCellComponent implements OnInit {

    public tableCellType = TableCellType;
    public isMobile = isMobile();
    public isTablet = isTablet();
    @Input() cell;
    @Input() row;

    constructor(public settingsService: SettingsService) { }

    ngOnInit(): void { }

    getAlergens(row) {
        return row.map(item => item.allergen.name).join('\n')
    }

}
