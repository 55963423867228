import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { ProductModel } from '../../models/product.model';
import { ProductsService } from '../../services/product.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { validateAllFormFields } from 'src/app/shared/utils/form.utils';
import { filter } from 'rxjs';
import { booleanOptions$ } from 'src/app/shared/constants';
import { TypesService } from 'src/app/modules/types/services/type.service';
import { CategoriesService } from 'src/app/modules/categories/services/category.service';
import { TypeModel } from 'src/app/modules/types/models/type.model';
import { MeasurementUnitsService } from 'src/app/modules/measurement-unit/services/measurement-unit.service';
import { CategoryModel } from 'src/app/modules/categories/models/category.model';
import { AllergenModel } from 'src/app/modules/allergens/models/alergen.model';
import { MeasurementUnitModel } from 'src/app/modules/measurement-unit/models/measurement-unit.model';
import { TranslateModule } from '@ngx-translate/core';
import { ProductImageTabComponent } from '../product-image-tab/product-image-tab.component';
import { ProductAllergensComponent } from '../product-allergens/product-allergens.component';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { DropdownWithSearchComponent } from '../../../../shared/components/dropdown-with-search/dropdown-with-search.component';
import { DropdownWithBooleansComponent } from '../../../../shared/components/dropdown-with-booleans/dropdown-with-booleans.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ContentLanguageDropdownComponent } from '../../../../shared/components/content-language-dropdown/content-language-dropdown.component';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-products-modal',
    templateUrl: './products-modal.component.html',
    styleUrls: ['./products-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, ContentLanguageDropdownComponent, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DropdownWithBooleansComponent, DropdownWithSearchComponent, DialogFooterButtonsComponent, ProductAllergensComponent, ProductImageTabComponent, TranslateModule]
})
export class ProductsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public selectedProduct: ProductModel;
    public categoriesList: CategoryModel[];
    public booleanOptions;
    public typesList: TypeModel[];
    public measurementUnitsList: MeasurementUnitModel[];
    public productAllergens: AllergenModel[];

    constructor(
        public dialogRef: MatDialogRef<ProductsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProductModel,
        private _formBuilder: FormBuilder,
        private _productsService: ProductsService,
        private _typesService: TypesService,
        private _categoriesService: CategoriesService,
        private _measurementUnitsService: MeasurementUnitsService,
    ) {
        super(dialogRef, _productsService);

        this.initModal()
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._measurementUnitsService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.measurementUnitsList = data;
        });
        this._productsService.selectedItem$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.selectedProduct = data;
            this.form?.patchValue(data);
        })
        this._productsService.productAllergens$.pipe(filter(data => !!data)).subscribe(data => this.productAllergens = data)
        this._productsService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
        this.form = this._formBuilder.group({
            id: this.selectedProduct?.id || '',
            name: this.selectedProduct?.name || '',
            ingredients: this.selectedProduct?.ingredients || '',
            is_available: this.selectedProduct ? this.selectedProduct.is_available : true,
            is_active: this.selectedProduct ? this.selectedProduct.is_active : true,
            type_id: this.selectedProduct?.type.id || 0,
            category_id: this.selectedProduct?.category.id || 0,
            amount: this.selectedProduct?.amount || 0,
            measurement_unit_id: this.selectedProduct?.measurement_unit.id || 0,
            price: this.selectedProduct?.price || 0,
            servings: this.selectedProduct?.servings || 0,
            calories: this.selectedProduct?.calories || 0,
            proteins: this.selectedProduct?.proteins || 0,
            carbohydrates: this.selectedProduct?.carbohydrates || 0,
            fats: this.selectedProduct?.fats || 0,
        });
    }

    ngOnDestroy(): void {
    }

    initModal() {
        this.data?.id && this._productsService.getProduct(this.data.id);
        this.data?.id && this._productsService.getProductAllergens(this.data.id);
        this._typesService.getTypes$().pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.typesList = data.data);
        booleanOptions$().pipe(untilDestroyed(this)).subscribe(value => this.booleanOptions = value.data);
        this._categoriesService.getCategories$().pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.categoriesList = data.data);
        this._measurementUnitsService.getMeasurementUnits();
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        if (!this.selectedProduct) {
            this._productsService.createProduct(this.form.value);
            return;
        }
        this._productsService.updateProduct(this.form.value);
    }
    changeLang() {
        this.initModal()
    }
}
