import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router, RouterOutlet } from '@angular/router';
import { isMobile } from 'src/app/shared/utils/responsive.utils';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';

@UntilDestroy()
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    standalone: true,
    imports: [RouterOutlet]
})
export class DashboardComponent implements OnInit {
    private _prevUrl: string;
    public isMobile = isMobile;

    constructor(public userProfileService: UserProfileService, private _router: Router) {
        this._prevUrl = this._router.getCurrentNavigation()?.previousNavigation?.finalUrl.toString();
    }

    public ngOnInit() { }
}
