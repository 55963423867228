<div *ngIf="payments?.length">
    <div class="card border-radius-card">
        <div id="accordion-manual-order" role="tablist">
            <div class="card-collapse mb-0">
                <div role="tab" id="headingOne" class="m-0 pt-2">
                    <div class="position-absolute person-img">
                        <div class="payment-icon icon-wrapper text-center">
                            <i class="material-icons payment-icon opacity-10">credit_score</i>
                        </div>
                    </div>
                    <div class="collapsed d-flex justify-content-end flex-column"> 
                        <div class="text-right pr-2">
                            <div class="d-flex justify-content-end">
                                <div class="px-0">

                                    <small class="user-title mb-0">{{"TotalPaid" | translate}}</small>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end">
                                <p class="font-weight-bold mb-0">
                                    {{getTotal()}}  {{settingsService.currency$ | async}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr class="dark horizontal mt-2 mb-0">
                </div>
                <div>
                    <div class="card-body px-0"> 
                        <ul class="px-2">
                            <li class="product-list-item pl-3 my-3 border-paid" *ngFor="let payment of payments" >
                                <div class="d-flex justify-content-between">
                                    <div class="date text-center">
                                        <span class="">{{ utcConvert(payment.created_at) | date : 'HH:mm'}}</span> <br />
                                        <span class="small font-weight-bold">{{ (utcConvert(payment.created_at) | date : 'dd.MM.yyyy')}}</span>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-end">
                                        <span class="small">{{payment.user.first_name}} {{payment.user.last_name}}</span>
                                    </div>
                                    <div class="payment-type px-2">
                                        <div class="text-right">
                                            <span class="badge badge-warning" *ngIf="payment.type === 'cash'">{{payment.type | translate}}</span>
                                            <span class="badge badge-info" *ngIf="payment.type === 'card'">{{payment.type | translate}}</span>
                                        </div>
                                        <span class="small d-block text-right">{{payment.total}} {{settingsService.currency$ | async}}</span>
                                    </div>
                                </div>
                                <hr class="dark horizontal my-0 mt-2">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>