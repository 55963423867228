
import { RestrictPermissionsGuard } from '../../shared/guards/restrict-permissions.guard';
import { RolesComponent } from './../roles/roles.component';
import { Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { AccountsComponent } from '../../modules/accounts/accounts.component';
import { SettingsComponent } from '../settings/settings.component';
import { BrandInvoiceProfileComponent } from '../brand-invoice-profile/brand-invoice-profile.component';
import { BrandProfileComponent } from '../brand-profile/brand-profile.component';
import { InvoicesComponent } from '../financial/invoices/invoices.component';
import { NotAllowedComponent } from 'src/app/shared/components/not-allowed/not-allowed.component';
import { AllergensComponent } from '../allergens/allergens.component';
import { CategoriesComponent } from '../categories/categories.component';
import { ProductsComponent } from '../products/products.component';
import { TypesComponent } from '../types/types.component';
import { MeasurementUnitsComponent } from '../measurement-unit/measurement-units.component';
import { AssetsComponent } from '../assets/assets.component';
import { OrdersComponent } from '../orders/orders.component';
import { PaymentsComponent } from '../payments/payments.component';


export const DashboardRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: DashboardComponent,
                canActivate: [RestrictPermissionsGuard],
            }, {
                path: 'brand-profile',
                component: BrandProfileComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'allergens',
                component: AllergensComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'assets',
                component: AssetsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'categories',
                component: CategoriesComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'products',
                component: ProductsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'types',
                component: TypesComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'measurement-units',
                component: MeasurementUnitsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'accounts',
                component: AccountsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'roles',
                component: RolesComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'orders',
                component: OrdersComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'brand-invoice-profile',
                component: BrandInvoiceProfileComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'settings',
                component: SettingsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'invoices',
                component: InvoicesComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'payments',
                component: PaymentsComponent,
                canActivate: [RestrictPermissionsGuard]
            }, {
                path: 'not-allowed',
                component: NotAllowedComponent
            },
        ]
    },
];
