import { AppSettingsService } from '../../../shared/services/app-settings.service';
import { FormErrorService } from '../../../shared/services/form-error.service';
import { BaseService } from '../../../shared/services/base.service';
import { BrandProfileCustomisationModel } from '../models/brand-profile-customisation.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BrandProfileModel } from '../models/brand-profile.model';
import { apiEndpoints, ApiRequestService } from '../../../shared/services/api-request.service';

@Injectable({
    providedIn: 'root'
})
export class BrandProfileService extends BaseService {
    currentBrandProfile$: BehaviorSubject<BrandProfileModel> = new BehaviorSubject(undefined);

    get currency(): string {
        return this.currentBrandProfile$.value.currency;
    }

    constructor(
        protected _apiRequestService: ApiRequestService,
        public _formErrorService: FormErrorService,
        public _appSettingsSerice: AppSettingsService) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    getCurrentBrandProfile() {
        this._apiRequestService.submitGetRequest(apiEndpoints.brandProfile).subscribe({
            next: apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data)
            },
            error: error => this.reportError('Error on get current brand', error)
        });
    }

    updateCurrentBrandCustomisation(payload: BrandProfileCustomisationModel) {
        this._apiRequestService.submitPostRequest(apiEndpoints.brandProfileAdditionalDetails, payload).subscribe({
            next: apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on set customisation', error)
        });
    }

    updateCurrentBrandProfile(currentBrandProfile: BrandProfileModel) {
        this._apiRequestService.submitPutRequest(apiEndpoints.brandProfile, currentBrandProfile).subscribe({
            next: apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on get current brand', error)
        });
    }

    updateCurrentBrandSettings(settings) {
        this._apiRequestService.submitPutRequest(apiEndpoints.brandProfileSettings, settings).subscribe({
            next: apiResonse => {
                this.currentBrandProfile$.next(apiResonse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on get current settings brand', error)
        });
    }

    updateCurrentBrandProfileLogoAndCover(imgs) {
        const formData: FormData = new FormData();

        if (typeof imgs.logo !== 'string' && imgs.logo) {
            formData.append('logo', imgs?.logo, imgs?.logo.name);
        }
        if (typeof imgs.cover !== 'string' && imgs.cover) {
            formData.append('cover', imgs?.cover, imgs?.cover.name);
        }

        this._apiRequestService.submitPostRequest(apiEndpoints.brandProfileLogoAndCover, formData).subscribe({
            next: apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on get current Brand logo and cover', error)
        });
    }

    getQuantumDurationOptions(maxDurationHours: number): number[] {
        const timeQuantumMinutes = this.currentBrandProfile$?.value.time_quantum || 10;

        const options = [];
        for (let index = timeQuantumMinutes; index <= maxDurationHours * 60; index += timeQuantumMinutes) {
            options.push(index);
        }

        return options;
    }

    removeLogoImage() {
        this._apiRequestService.submitDeleteRequest(apiEndpoints.brandProfileLogoDelete).subscribe({
            next: apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on delete logo image', error)
        });
    }

    removeCoverImage() {
        this._apiRequestService.submitDeleteRequest(apiEndpoints.brandProfileCoverDelete).subscribe({
            next: apiResponse => {
                this.currentBrandProfile$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on delete cover image', error)
        });
    }
}
