import { RolesService } from './../../services/roles.service';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, effect } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { validateAllFormFields, ValidationMessages } from 'src/app/shared/utils/form.utils';
import { PermissionCategoryModel, PermissionsModel } from 'src/app/shared/models/permissions.model';
import { RolesModel } from '../../models/roles.model';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { RegexValidator } from 'src/app/shared/utils/regex-validator.utils';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { RolesCheckAllComponent } from '../../../../shared/components/roles-check-all/roles-check-all.component';
import { GradientBorderTitleComponent } from '../../../../shared/components/gradient-border-title/gradient-border-title.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf, NgFor } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-roles-modal',
    templateUrl: './roles-modal.component.html',
    styleUrls: ['./roles-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, PreloaderComponent, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgFor, GradientBorderTitleComponent, RolesCheckAllComponent, DialogFooterButtonsComponent, TranslateModule]
})
export class RolesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public validationMessages = ValidationMessages;
    public permissionsList = this._permissionsService.permissionsDetailedList;
    public permissionCols: string[] = ['Vizualizare', 'Creare', 'Editare', 'Stergere'];
    public selectedRole = this.rolesService.selectedRole;


    constructor(public dialogRef: MatDialogRef<RolesModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: RolesModel,
        private _formBuilder: FormBuilder,
        private _permissionsService: PermissionsService,
        public rolesService: RolesService) {
        super(dialogRef, rolesService);
        this._data && this.rolesService.getRole(this._data);

        effect(() => {
            this.form = this._formBuilder.group({
                name: [this.selectedRole()?.name, Validators.compose([
                    Validators.required,
                    Validators.pattern(RegexValidator.ITEM_NAME.regex),
                ])],
            });

            this.permissionsList()?.forEach((category: PermissionCategoryModel) => {
                category.subcategories.forEach(subcategory => {
                    subcategory.permissions.forEach(permission => {
                        const found = this.selectedRole()?.permissions_names.find(rolePermName => rolePermName === permission.name);
                        permission.allowed = !!found;
                    });
                });
            });
        })
    }

    ngOnInit(): void {
        this.rolesService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
        this.dialogRef.beforeClosed().pipe(untilDestroyed(this)).subscribe(() => this.rolesService.getRoles());
        this._permissionsService.getPermissionsDetailed();
    }

    ngOnDestroy(): void {
        this.rolesService.selectedRole.set(undefined);
    }

    save() {
        if (this.form.valid) {
            this.onSubmit();
        } else {
            validateAllFormFields(this.form);
        }
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        if (!this.selectedRole) {
            this.addRole();
            return;
        }
        this.updateRole();
    }

    addRole() {
        this.rolesService.createRole(this.form.value);
    }

    updateRole() {
        const newPermissionsList = [];

        this.permissionsList().forEach((category: PermissionCategoryModel) => {
            category.subcategories.forEach(subcategory => {
                subcategory.permissions.forEach(permission => {
                    if (permission.allowed) {
                        newPermissionsList.push(permission.name);
                    }
                });
            });
        });

        this.rolesService.updateRole({
            id: this.selectedRole().id,
            name: this.form.value.name,
            permissions: newPermissionsList,
        });
    }

    changePermissions(updatedPermissions: PermissionsModel[]) {
        updatedPermissions.forEach(updatedPermission => {
            this.permissionsList().forEach((category: PermissionCategoryModel) => {
                category.subcategories.forEach(subcategory => {
                    subcategory.permissions.forEach(permission => {
                        if (updatedPermission.name === permission.name) {
                            permission.allowed = updatedPermission.allowed;
                        }
                    });
                });
            });
        });
    }
}
