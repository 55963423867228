import { Component, Input, OnInit } from '@angular/core';
import { InvoiceModel } from '../../../models/invoice.model';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceServicesListComponent } from './invoice-services-list/invoice-services-list.component';
import { InvoiceServicesFormComponent } from './invoice-services-form/invoice-services-form.component';
import { GradientBorderTitleComponent } from '../../../../../../shared/components/gradient-border-title/gradient-border-title.component';

@Component({
    selector: 'app-invoice-services-tab',
    templateUrl: './invoice-services-tab.component.html',
    styleUrls: ['./invoice-services-tab.component.css'],
    standalone: true,
    imports: [GradientBorderTitleComponent, InvoiceServicesFormComponent, InvoiceServicesListComponent, TranslateModule]
})
export class InvoiceServicesTabComponent implements OnInit {

    @Input() data: InvoiceModel;

    constructor() { }

    ngOnInit(): void {
    }

}
