import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gradient-border-title',
    templateUrl: './gradient-border-title.component.html',
    styleUrls: ['./gradient-border-title.component.css'],
    standalone: true
})
export class GradientBorderTitleComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
