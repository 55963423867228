import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormErrorService } from '../../services/form-error.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-form-field-error',
    templateUrl: './form-field-error.component.html',
    styleUrls: ['./form-field-error.component.css'],
    host: { 'class': 'mat-error' },
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class FormFieldErrorComponent implements OnInit {

    @Input() control: FormControl;

    constructor(public formErrorService: FormErrorService) { }

    ngOnInit(): void {
    }

}
