import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, take } from 'rxjs';
import { EnumValuesService } from './services/enum-values.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { MatOptionModule } from '@angular/material/core';
import { NgIf, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy()
@Component({
    selector: 'app-dropdown-with-enum-values',
    templateUrl: './dropdown-with-enum-values.component.html',
    styleUrls: ['./dropdown-with-enum-values.component.css'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, NgIf, MatOptionModule, NgFor, FormFieldErrorComponent, TranslateModule]
})
export class DropdownWithEnumValuesComponent implements OnInit {

    public itemsList: string[];
    public itemsList$: Observable<any>;
    @Input() control: FormControl;
    @Input() enumName: string;
    @Input() optionFormParent: string;
    @Input() firstOptionMessage: string = undefined;
    @Input() label: string;
    @Input() data_cy: string;

    constructor(private _enumValuesService: EnumValuesService) { }

    ngOnInit(): void {
        this._enumValuesService.getEnumValues(this.enumName).pipe(take(1), untilDestroyed(this)).subscribe(items => {
            this.itemsList = items;
            this._setFirstValue(items);
        });
    }

    private _setFirstValue(list: any[]) {
        if (!this.firstOptionMessage && !this.control?.value && list?.length) {
            this.control.setValue(this.optionFormParent ? this.optionFormParent : list[0]);
            return;
        }
        this.firstOptionMessage && this.control.setValue(this.control.value || 0, { emitEvent: false });
    }
}
