

import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TablesModule } from 'src/app/shared/components/tables/tables.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { RolesModalComponent } from './components/roles-modal/roles-modal.component';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RolesComponent } from './roles.component';

@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    RolesComponent,
    RolesModalComponent
],
    exports: [
        RolesComponent,
        RolesModalComponent,
    ]
})

export class RolesModule { }