
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '../shared-components.module';
import { NavNotificationsComponent } from './nav-notifications/nav-notifications.component';

@NgModule({
    imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    SharedComponentsModule,
    NavbarComponent, NavNotificationsComponent
],
    exports: [NavbarComponent, NavNotificationsComponent]
})

export class NavbarModule { }
