import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.css'],
    standalone: true,
    imports: [AsyncPipe]
})
export class TimerComponent {
    public secondsTillNow: number;
    public timer$ = timer(0, 1000).pipe(
        map(count => moment.utc((this.secondsTillNow + count) * 1000).format('HH:mm:ss'))
    );

    @Input() set startDate(value: string) {
        this.secondsTillNow = moment().unix() - moment(value).unix();
    }
}
