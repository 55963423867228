import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DashboardAssetsRoutes } from "./dashboard-assets.routing";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/shared/modules/material.module";
import { NavbarModule } from "src/app/shared/components/navbar/navbar.module";
import { SidebarModule } from "src/app/shared/components/sidebar/sidebar.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { httpTranslateLoader } from "src/app/app.module";


import { SharedComponentsModule } from "src/app/shared/components/shared-components.module";
import { TablesModule } from "src/app/shared/components/tables/tables.module";

import { AuthGuard } from "src/app/shared/guards/auth.guard";
import { DashboardAssetsComponent } from "./dashboard-assets.component";
import { TableAssetsComponent } from "./table-assets/table-assets.component";
import { OpenTableAssetModalComponent } from './table-assets/open-table-asset-modal/open-table-asset-modal.component';
import { PaymentConfirmationComponent } from './table-assets/payment-confirmation/payment-confirmation.component';
import { PaymentsListComponent } from './table-assets/payments-list/payments-list.component';
import { AddCustomerComponent } from './table-assets/add-customer/add-customer.component';
import { OrderStatusesComponent } from "./table-assets/order-statuses/order-statuses.component";
import { AddCostumerConfirmationModalComponent } from './table-assets/add-costumer-confirmation-modal/add-costumer-confirmation-modal.component';


@NgModule({
    imports: [
    CommonModule,
    RouterModule.forChild(DashboardAssetsRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    NavbarModule,
    SidebarModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoader,
            deps: [HttpClient]
        }
    }),
    SharedComponentsModule,
    TablesModule,
    DashboardAssetsComponent,
    TableAssetsComponent,
    OpenTableAssetModalComponent,
    PaymentConfirmationComponent,
    PaymentsListComponent,
    AddCustomerComponent,
    OrderStatusesComponent,
    AddCostumerConfirmationModalComponent,
],
    providers: [AuthGuard],
    exports: [
        DashboardAssetsComponent,
        TableAssetsComponent,
        PaymentsListComponent,
        OrderStatusesComponent
    ]
})

export class DashboardAssetsModule { }