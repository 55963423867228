<div class="wrapper wrapper-full-page">
    <div class="page-header header-filter" filter-color="black"
        style="background-image: url('./assets/img/market-background.jpg'); background-size: cover; background-position: top center;">
        <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->

        <div class="container h-100 main-wrapper">
            <div
                class="row h-100 page-content align-items-center flex-column-reverse flex-lg-row align-items-lg-center">
                <div class="col-12 col-sm-9 col-lg-6 ml-auto mr-auto text-center text-lg-left">
                    <h2 class="pb-5">{{ 'RestrictedPage' | translate }}</h2>
                    <p>Eroare: <span class="font-weight-bold">418</span></p>
                    <p>Nu ai acces la această pagină.<br> Asigură-te că ai tastat URL-ul corect.</p>
                </div>
                <div class="col-12 col-sm-9 col-lg-6 ml-auto mr-auto text-center pb-5 pb-lg-0">
                    <img loading="lazy" class="no-access-img" src=" ./assets/img/no-access.png"
                        alt="Eroare418 - Pagina restrictionata">
                </div>
            </div>
        </div>

        <footer class="footer ">
            <div class="container">
                <div class="row align-items-center justily-content-sm-center">
                    <div class="col-6 copyright text-center text-lg-left small">
                        <a href="https://www.codern.ro/">
                            &copy;
                            {{ currDate | date: 'yyyy' }}, CodeRN Venture
                        </a>
                    </div>
                </div>

            </div>
        </footer>
    </div>
</div>