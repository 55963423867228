import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';

import { TablesModule } from 'src/app/shared/components/tables/tables.module';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductsComponent } from './products.component';
import { ProductsModalComponent } from './components/products-modal/products-modal.component';
import { ProductAllergensComponent } from './components/product-allergens/product-allergens.component';
import { ProductImageTabComponent } from './components/product-image-tab/product-image-tab.component';



@NgModule({
    imports: [
    SharedComponentsModule,
    TablesModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    ProductsComponent,
    ProductsModalComponent,
    ProductAllergensComponent,
    ProductImageTabComponent
],
})
export class ProductsModule { }
