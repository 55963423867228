<div class="container mt-3">
    <div class="row pt-3 justify-content-end">
        <div class="col-4 text-center d-flex align-items-stretch pl-0">
            <div class="card my-0">
                <div class="card-body d-flex flex-column justify-content-between">
                    <h3 class="card-title text-info font-weight-bold pb-1">
                        <span>{{unconfirmedProducts}}</span>  
                        <small *ngIf="confirmedProductIds.length">
                            <span class="small"> (-{{toConfirmQuantity}})</span>
                        </small>
                    </h3>
                    <div class="card-text text-custom-height px-3 small">
                        <span>{{"UnconfirmedProducts" | translate | lowercase}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-4 text-center d-flex align-items-stretch px-2">
            <div class="card my-0">
                <div class="card-body d-flex flex-column justify-content-between">
                    <h3 class="card-title text-warning font-weight-bold pb-1">
                        <span>{{unpaidProducts}}</span>
                        <small *ngIf="productsToPayIds.length">
                            <span class="small"> (-{{toPayQuantity}})</span>
                        </small>
                    </h3>
                    <div class="card-text text-custom-height px-3 small">
                        <span>{{"UnpaidProducts" | translate | lowercase}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-4 text-center d-flex align-items-stretch pr-0">
            <div class="card my-0">
                <div class="card-body d-flex flex-column justify-content-between">
                    <h3 class="card-title text-danger font-weight-bold pb-1">
                        <span>{{products?.totals?.to_pay || 0 }}</span>
                    </h3>
                    <div class="card-text text-custom-height px-3 small">
                        <b> {{settingsService.currency$ | async}} </b><span>{{"ToBeCollected" | translate | lowercase }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>