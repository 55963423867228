<section class="table-list-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <span>{{ 'Types' | translate | uppercase }}</span>
                <button mat-raised-button class="btn btn-white text-dark" (click)="addEntry()">
                    <i class="material-icons mr-1">add</i>
                    {{'Add' | translate }}</button>
            </div>
            <div class="col-12 mt-4">
                <app-table-filters *ngIf="table" [filters]="filters" [tableId]="table.tableId"
                    [queryParams]="queryParams" (applyFilters)="applyFilters($event)"></app-table-filters>
            </div>
            <div class="col-12">
                <app-regular-table #table [headerRow]="tableHeaders"
                    [service]="typesService" 
                    [dataList]="itemsList"
                    [lastPage]="itemsData?.last_page" 
                    [rowPossibleActions]="rowPossibleActions" 
                    [pagination]="itemsData"
                    [length]="itemsData?.total" 
                    [tableId]="'types-records-tbl'" 
                    (onPageChanged)="updatePage($event)"
                    (actionType)="tableActionClicked($event)"
                    [localeLanguageDropdown]="true"
                    (changeLang)="changeLang()">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>