import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogState } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, timer } from 'rxjs';
import { UpToDateComponent } from '../components/up-to-date/up-to-date.component';
import { BuildDetails } from '../models/build-details.model';

import { isNullOrUndefined } from '../utils/isNullOrUndefined.utils';
import { ApiRequestService } from './api-request.service';
import { DialogService } from './dialog.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BuildDetailsService {

    public buildDetails: BuildDetails;
    public buildIsUpToDateSubject$ = new BehaviorSubject<boolean>(true);

    private _buildNumberAtStartup: string;
    private _dialogState: MatDialogState;
    private httpOptions = {
        headers: new HttpHeaders({
            'Cache-Control': 'no-cache'
        })
    };

    constructor(private _apiRequestService: ApiRequestService, private _dialogService: DialogService) {
        this.buildDetails = new BuildDetails();
        this.pollForBuildNumber();

    }

    getBuildDetails() {
        this._apiRequestService.submitGetRequest('assets/build-details.json', this.httpOptions).subscribe({
            next: response => {
                this.buildDetails = response;
                this._buildNumberAtStartup = this.buildDetails.buildNumber;
            },
            error: error => console.log(error)
        });
    }

    private pollForBuildNumber() {
        const pollInterval = 60000;
        timer(pollInterval, pollInterval).subscribe(() => {
            this._apiRequestService.submitGetRequest('assets/build-details.json', this.httpOptions).subscribe({
                next: response => {
                    const newBuildNumber = response.buildNumber;
                    this._buildNumberAtStartup !== newBuildNumber && this.notifyUser();
                },
                error: error => console.log(error)
            });
        });
    }

    notifyUser() {
        if (isNullOrUndefined(this._dialogState)) {
            const dialogRef = this._dialogService.openModal(null, UpToDateComponent, '100vh', '100vw');
            this._dialogState = dialogRef.getState();
            dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(() => this._dialogState = null);
        }
    }
}