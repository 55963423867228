import { MatDialogRef } from '@angular/material/dialog';
import { OnInit, OnDestroy, Directive } from '@angular/core';
import { BaseService } from '../../services/base.service';


@Directive()
export class BaseModalComponent implements OnInit, OnDestroy {

    protected _saveAndClose = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        public baseService: BaseService) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.baseService.onSaveSuccess$.next(undefined);
    }
}