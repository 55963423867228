import PerfectScrollbar from 'perfect-scrollbar';
import { PermissionsEnum } from '../../enums/Permissions.enum';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountModel } from '../../../modules/accounts/models/account.model';
import { AccountsModalComponent } from '../../../modules/accounts/components/accounts-modal/accounts-modal.component';
import { BrandProfileModel } from 'src/app/modules/brand-profile/models/brand-profile.model';
import { UserProfileService } from '../../services/user-profile.service';
import { BrandProfileService } from 'src/app/modules/brand-profile/services/brand-profile.service';
import { DialogService } from '../../services/dialog.service';
import { AuthService } from '../../services/auth.service';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { LangService } from '../../services/lang.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RestrictPermissionsDirective } from '../../directives/restrict-permissions.directive';
import { NgIf, NgFor } from '@angular/common';

declare const $: any;
export const SCAN_ROUTES: RouteInfo[] = [
    {
        path: '/menu',
        title: 'Language',
        type: 'sub',
        icontype: 'language',
        collapse: 'language',
        children: [
            { title: 'RO', value: 'ro' },
            { title: 'EN', value: 'en' },
            { title: 'DE', value: 'de' }
        ]
    },
];
// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    permission?: string;
    queryParams?: any;
    data_cy?: string;
}

export interface ChildrenItems {
    path?: string;
    title: string;
    ab?: string;
    type?: string;
    permission?: string;
    value?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/admin',
        title: 'GeneralPanel',
        type: 'link',
        icontype: 'dashboard',
        permission: PermissionsEnum.SELF_PROFILE_READ,
    },
    {
        path: '/admin/orders',
        title: 'Orders',
        type: 'link',
        icontype: 'border_color',
        permission: PermissionsEnum.VIEW_ORDERS_LIST,
    },
    {
        path: '/admin/payments',
        title: 'Payments',
        type: 'link',
        icontype: 'credit_score',
        permission: PermissionsEnum.VIEW_PRODUCTS_LIST,
    },
];
@UntilDestroy()
@Component({
    selector: 'app-sidebar-cmp',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    standalone: true,
    imports: [NgIf, RestrictPermissionsDirective, NgFor, RouterLinkActive, RouterLink, TranslateModule]
})

export class SidebarComponent implements OnInit, AfterViewInit {
    public currentUser: AccountModel;
    public menuItems: any[];
    public PermissionsEnum = PermissionsEnum;
    public currentBrand: BrandProfileModel;
    public isScanView: boolean;
    public scanMenuItems: any[];
    public lang;
    public scannedProductId;
    ps: any;

    @ViewChildren('parentMenuItem') parentMenuItem: QueryList<ElementRef>;

    constructor(
        private _userProfileService: UserProfileService,
        private _brandProfileService: BrandProfileService,
        private _dialogService: DialogService,
        private _authService: AuthService,
        private _router: Router,
        public langService: LangService,
        public translateService: TranslateService,
    ) { }

    ngOnInit() {
        this.isScanView = this._router.url.includes('menu');
        this._userProfileService.checkForPermission(PermissionsEnum.USERS_READ) && this._brandProfileService.getCurrentBrandProfile();

        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.scanMenuItems = SCAN_ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

        this._userProfileService.currentUser$.pipe(filter(currentUser => !!currentUser), untilDestroyed(this))
            .subscribe(currentUser => this.currentUser = currentUser);
        this._brandProfileService.currentBrandProfile$.pipe(filter(currentBrand => !!currentBrand), untilDestroyed(this))
            .subscribe(currentBrand => this.currentBrand = currentBrand);

    }

    ngAfterViewInit(): void {
        this.parentMenuItem.forEach((item: any) => {
            const childrenList = item.nativeElement?.getElementsByTagName('div')[0]?.firstChild.children;
            if (childrenList) {
                for (const child of childrenList) {
                    if (!child.classList.contains('d-none')) {
                        item.nativeElement.classList.remove('d-none');
                    }
                }
            }
        });
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    toggleActive(e) {
        const navItems = document.querySelectorAll('.nav-item');
        navItems.forEach(nav => nav.classList.remove('active'));
        e.currentTarget.classList.add('active');
    }

    editUser(user: AccountModel) {
        const selfUpdateUser = {
            self_update: true,
            ...user
        };
        this._dialogService.openModal(selfUpdateUser, AccountsModalComponent, 'auto', '70vw');
    }

    logout() {
        this._authService.logout();
    }
}
