import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { NgIf, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-dropdown-with-booleans',
    templateUrl: './dropdown-with-booleans.component.html',
    styleUrls: ['./dropdown-with-booleans.component.css'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormFieldModule, MatSelectModule, ReactiveFormsModule, NgIf, MatOptionModule, NgFor, TranslateModule]
})
export class DropdownWithBooleansComponent implements OnInit {

    @Input() control: FormControl;
    @Input() itemsList: any[];
    @Input() firstOptionMessage: string = undefined;
    @Input() label: string;
    @Input() data_cy: string;

    constructor() { }

    ngOnInit(): void {
        this.control.setValue(this.control.value === true || this.control.value === false ? this.control.value : 0);
    }
}
