import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter } from 'rxjs';
import { getLang } from '../../utils/utils';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, UpperCasePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../directives/form-validator.directive';

@UntilDestroy()
@Component({
    selector: 'app-content-language-dropdown',
    templateUrl: './content-language-dropdown.component.html',
    styleUrls: ['./content-language-dropdown.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, UpperCasePipe, TranslateModule]
})
export class ContentLanguageDropdownComponent implements OnInit {

    public langForm: FormGroup;
    public languages;

    @Output() changeLang: EventEmitter<boolean> = new EventEmitter();
    @Input() events: Observable<string>;

    constructor(private _formBuilder: FormBuilder, private _settingsService: SettingsService) { }

    ngOnInit() {
        this._settingsService.getSupportedLocales()
        this._settingsService.suportedLocales$.pipe(untilDestroyed(this)).subscribe(data => this.languages = data)
        this.langForm = this._formBuilder.group({
            locale: getLang(),
        });

        this.events && this.events.pipe(filter(val => !!val && (val !== this.langForm.controls.locale?.value)), untilDestroyed(this)).subscribe(() => {
            this.langForm.controls.locale.setValue(getLang());
        })

        this.langForm.controls.locale.valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
            localStorage.setItem("locale", val);
            this.changeLang.emit(val);
        })
    }

}
