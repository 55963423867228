<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
        <small>{{'Roles' | translate}}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12 pb-3">
        <h1 class="mb-0 dialog-title" *ngIf="selectedRole(); else add_role">
            {{'Edit' | translate}}: {{selectedRole()?.name}}
        </h1>
        <ng-template #add_role>
            <h1 class="mb-0 dialog-title"> {{'AddRole' | translate}} </h1>
        </ng-template>
    </div>

</div>

<mat-dialog-content>

    <div class="position-relative">
        <app-preloader></app-preloader>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row mt-4">
                <div class="col-md-12" [class.disabled-input]="selectedRole()">
                    <mat-form-field class="customized">
                        <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                        <input matInput type="text" id="name" autocomplete="off" formControlName="name">
                        <mat-error *ngFor="let validation of validationMessages.name">
                            <small
                                *ngIf="form.get('name').hasError(validation.type) && (form.get('name').dirty || form.get('name').touched)">
                                {{ validation.message | translate }}
                            </small>
                        </mat-error>
                        <mat-error *ngIf="form.get('name').errors?.serverError">
                            <small *ngFor="let serverError of form.get('name').errors?.serverError">
                                {{ serverError }}
                            </small>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-row mb-2 list-wrapper" *ngIf="selectedRole()?.id">
                <div class="col-12" *ngFor="let category of permissionsList()">

                    <div class="spacer-3"></div>
                    <app-gradient-border-title>{{ category.categoryName }}</app-gradient-border-title>
                    
                    <div class="row category-permissions py-3"
                        *ngFor="let subcategory of category.subcategories; index as subcategoryIndex">
                        <div class="col-md-2 d-flex align-items-center">
                            <h6 class="font-weight-bold mb-0">{{ subcategory.subCategory }}</h6>
                        </div>
                        <div class="col-md-9">
                            <app-roles-check-all [permissions]="subcategory.permissions"
                                (permissionsChange)="changePermissions($event)"></app-roles-check-all>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <app-dialog-footer-buttons class="w-100"
                               (close)="dialogRef.close()"
                               (saveAndClose)="saveAndExit()"
                               (save)="onSubmit()">
    </app-dialog-footer-buttons>
</mat-dialog-content>