import { LocalStorageService, AUTH_TOKEN } from './local-storage.service';
import { AccountModel } from 'src/app/modules/accounts/models/account.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { apiEndpoints, ApiRequestService } from './api-request.service';
import { BaseService } from './base.service';
import { FormErrorService } from './form-error.service';
import { AppSettingsService } from './app-settings.service';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService extends BaseService {

    currentUser$: BehaviorSubject<AccountModel> = new BehaviorSubject(undefined);
    updatedUser$: BehaviorSubject<AccountModel> = new BehaviorSubject(undefined);

    constructor(
        protected _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsService: AppSettingsService,
        private _localStorageService: LocalStorageService) {
        super(_formErrorService, _appSettingsService, _apiRequestService);
    }

    isAuthenticated(): Observable<boolean> {
        return new Observable((observer) => {
            this._apiRequestService.submitGetRequest(apiEndpoints.userProfile).subscribe({
                next: apiResponse => {
                    this.currentUser$.next(apiResponse.data);
                    observer.next(true);
                },
                error: () => {
                    this.currentUser$.next(undefined);
                    observer.next(false);
                }
            });
        });
    }

    getUser() {
        this._apiRequestService.submitGetRequest(apiEndpoints.userProfile).subscribe({
            next: apiResponse => {
                this.updatedUser$.next(apiResponse.data);
                this.currentUser$.next(apiResponse.data);
            },
            error: error => this.reportError('Error on get current user', error)
        });
    }

    updateUser(user: AccountModel) {
        this._apiRequestService.submitPutRequest(apiEndpoints.userProfile, user).subscribe({
            next: apiResponse => {
                this.updatedUser$.next(apiResponse.data);
                this.currentUser$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on update current user', error)
        });
    }

    refreshToken() {
        this._apiRequestService.submitGetRequest(apiEndpoints.refreshToken).subscribe({
            next: apiResponse => {
                this._localStorageService.setValue(AUTH_TOKEN, apiResponse.data.token);
            },
            error: error => this.reportError('Error on refresh token', error)
        });
    }

    checkForPermission(permissionsParam: boolean | string | string[]) {
        if (permissionsParam === '' || permissionsParam === undefined) {
            return true;
        }

        if (typeof (permissionsParam) === 'object') {
            const giveAccess: boolean[] = [];
            permissionsParam.find(permissionParam => {
                const found = this.currentUser$.value?.permissions.find(permission => permission === permissionParam);
                found ? giveAccess.push(true) : giveAccess.push(false);
            });
            if (giveAccess.every(value => !value)) {
                return false;
            }
            return true;
        }

        return this.currentUser$.value?.permissions.find(permission => permissionsParam === permission) ? true : false;
    }
}
