import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class MatPaginatorIntlLangService extends MatPaginatorIntl {

    translate: TranslateService;

    itemsPerPageLabel;
    firstPageLabel;
    nextPageLabel;
    previousPageLabel;
    lastPageLabel;

    getRangeLabel = function (page, pageSize, length) {
        this.itemsPerPageLabel = this.translate.instant('ITEMS_PER_PAGE_LABEL');
        this.firstPageLabel = this.translate.instant('FIRST_PAGE_LABEL');
        this.nextPageLabel = this.translate.instant('NEXT_PAGE_LABEL');
        this.previousPageLabel = this.translate.instant('PREVIOUS_PAGE_LABEL');
        this.lastPageLabel = this.translate.instant('LAST_PAGE_LABEL');
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.translate.instant('of')} ${length}`;
    };

    injectTranslateService(translate: TranslateService) {
        this.translate = translate;
    }
}
