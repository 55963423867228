import { UserStatusesModel } from 'src/app/modules/accounts/models/account-statuses.model';
import { Injectable, signal } from '@angular/core';
import { apiEndpoints, ApiRequestService } from './api-request.service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    userStatuses = signal(<UserStatusesModel[]>([]));
    isLoading: BehaviorSubject<any> = new BehaviorSubject(false);
    apiVersion$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

    constructor(private _apiRequestService: ApiRequestService, private _ls: LocalStorageService) { }

    getUserStatuses() {
        this._apiRequestService.submitGetRequest(apiEndpoints.userStatuses).subscribe({
            next: apiResponse => this.userStatuses.set(apiResponse.data),
            error: error => this.reportError('Error on get user statuses', error)
        })
    }

    getAppVersion() {
        this._apiRequestService.submitGetRequest(apiEndpoints.apiVersion).subscribe({
            next: apiResponse => {
                this.apiVersion$.next(apiResponse.api_version);
                this._ls.deleteValue('maintenance')
            },
            error: (err) => {
                this.apiVersion$.next(undefined);
                err.status === 503 && !this._ls.getValue('maintenance') && this._ls.setValue('maintenance', 'true')
            }
        })
    }

    reportError(message: string, response: object) {
        console.error(message);
        console.log(response);
    }
}
