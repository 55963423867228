import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-not-allowed',
    templateUrl: './not-allowed.component.html',
    styleUrls: ['./not-allowed.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class NotAllowedComponent implements OnInit {
    public errorMessages: string[];

    constructor() { }

    ngOnInit(): void {
    }
}
