<div class="container-fluid mb-4">
    <form [formGroup]="form" appFormValidator>
        <div class="form-row mt-4">
            <div class="col-md-3">
                <mat-form-field class="customized">
                    <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                    <input type="text" matInput id="name" autocomplete="off" formControlName="name">
                    <mat-error>
                        <app-form-field-error [control]="form.controls.name"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <app-dropdown-with-enum-values [enumName]="'invoice_service_measurement_units'" [label]="'MeasureUnit'"
                    [control]="form.controls.measurement_unit">
                </app-dropdown-with-enum-values>
                <mat-error>
                    <app-form-field-error [control]="form.controls.measurement_unit"></app-form-field-error>
                </mat-error>
            </div>
            <div class="col-md-2">
                <mat-form-field class="customized">
                    <mat-label for="quantity">{{ 'Quantity' | translate }}:</mat-label>
                    <input type="number" matInput id="quantity" autocomplete="off" formControlName="quantity">
                    <mat-error>
                        <app-form-field-error [control]="form.controls.quantity"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="customized">
                    <mat-label for="vat">{{ 'VAT' | translate }}:</mat-label>
                    <input type="number" matInput id="vat" autocomplete="off" formControlName="vat">
                    <mat-error>
                        <app-form-field-error [control]="form.controls.vat"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field class="customized">
                    <mat-label for="unitary_price_including_vat">{{ 'UnitaryPrice' | translate }}:</mat-label>
                    <input type="number" matInput id="unitary_price_including_vat" autocomplete="off"
                        formControlName="unitary_price_including_vat">
                    <mat-error>
                        <app-form-field-error
                            [control]="form.controls.unitary_price_including_vat"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-1 d-flex align-items-center justify-content-center">
                <button class="btn btn-primary small-btn" (click)="addService()">
                    <i class="material-icons">add</i>
                </button>
            </div>
        </div>
    </form>
</div>