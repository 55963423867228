import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { AllergenModel } from '../../models/alergen.model';
import { AllergensService } from '../../services/allergen.service';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { validateAllFormFields } from 'src/app/shared/utils/form.utils';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { DialogFooterButtonsComponent } from '../../../../shared/components/dialog-footer-buttons/dialog-footer-buttons.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ContentLanguageDropdownComponent } from '../../../../shared/components/content-language-dropdown/content-language-dropdown.component';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { NgIf } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-allergens-modal',
    templateUrl: './allergens-modal.component.html',
    styleUrls: ['./allergens-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, PreloaderComponent, ReactiveFormsModule, FormValidatorDirective, ContentLanguageDropdownComponent, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DialogFooterButtonsComponent, TranslateModule]
})
export class AllergensModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public selectedAllergen: AllergenModel;
    public form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<AllergensModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AllergenModel,
        private _formBuilder: FormBuilder,
        private _allergensService: AllergensService) {
        super(dialogRef, _allergensService);
        this.data?.id && this._allergensService.getAllergen(this.data.id);
        this._allergensService.selectedItem$.pipe(filter(data => !!data)).subscribe(data => {
            this.selectedAllergen = data;
            this.form?.patchValue(data)
        })
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.form = this._formBuilder.group({
            id: this.selectedAllergen?.id || '',
            name: this.selectedAllergen?.name || '',
        });

        this._allergensService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
    }

    changeLang() {
        this.data.id && this._allergensService.getAllergen(this.data.id);
    }

    ngOnDestroy(): void { }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            validateAllFormFields(this.form);
        }
    }

    onSubmit() {
        if (!this.selectedAllergen) {
            this._allergensService.createAllergen(this.form.value);
            return;
        }
        this._allergensService.updateAllergen(this.form.value);
    }

}
