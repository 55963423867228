<section class="table-list-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <span>{{ 'Settings' | translate | uppercase }}</span>
            </div>
            <div class="col-12">
                <app-regular-table #table
                    [headerRow]="tableHeaders"
                    [service]="settingsService"
                    [dataList]="settings"
                    [rowPossibleActions]="rowPossibleActions"
                    [tableId]="'settings-records-tbl'"
                    (actionType)="tableActionClicked($event)">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>