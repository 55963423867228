import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})


export class SweetAlertService {

    public inputValue$: BehaviorSubject<string> = new BehaviorSubject(undefined);

    constructor(public translateService: TranslateService) { }

    showSwal(type, callbackFunction, item?) {
        if (type === 'basic') {
            swal.fire({
                title: 'Here\'s a message!',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        } else if (type === 'title-and-text') {
            swal.fire({
                title: 'Here\'s a message!',
                text: 'It\'s pretty, isn\'t it?',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-info'
                }
            });

        } else if (type == 'success-message') {
            swal.fire({
                title: 'Good job!',
                text: 'You clicked the button!',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                icon: 'success'
            });

        } else if (type === 'warning-message') {
            swal.fire({
                title: `${this.translateService.instant('DeleteItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'acknowledgeOrder') {
            swal.fire({
                html: `
                    <div class="d-flex justify-content-center align-items-center mb-4 mt-5 w-100">
                        <i class="material-icons text-danger fs-5">notifications_active</i>
                </div>
                <h2 class="lh-normal">${this.translateService.instant(item.asset_type.name)} ${item.name}</h2>
                 <span class="font-weight-bold">${this.translateService.instant('RequestType')}: ${this.translateService.instant(item.customer_request.type.charAt(0).toUpperCase() + item.customer_request.type.slice(1)).toUpperCase()}</span> \n
                    ${item.customer_request.details !== null ? '(' + item.customer_request.details + ')' : ''}`,
                showCancelButton: true,
                customClass: {
                    container: "text-wrap",
                    confirmButton: 'btn btn-primary text-right',
                    cancelButton: 'btn btn-secondary text-right mr-3',
                    actions: 'w-100 justify-content-end mt-4',
                },
                confirmButtonText: this.translateService.instant('AcknowledgeRequest'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'confirmOrderProducts') {
            swal.fire({
                title: ``,
                html: `
                    <div class="d-flex justify-content-center align-items-center mb-4 mt-5 w-100">
                        <i class="material-icons-outlined text-success fs-5">mark_chat_read</i>
                    </div>
                    <p class="lh-normal"> ${this.translateService.instant('ConfirmSelectedProducts')}?</p>
                    `,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary text-right',
                    cancelButton: 'btn btn-secondary text-right mr-3',
                    actions: 'w-100 justify-content-end mt-4',
                },
                confirmButtonText: this.translateService.instant('Confirm'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'cashOrderProducts') {

            swal.fire({
                title: `${this.translateService.instant('CashOrder')}?`,
                html: '<div></div>',
                icon: 'success',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary',
                },
                confirmButtonText: this.translateService.instant('Confirm'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'closeOrder') {
            let tableName = item.asset_name ? item.asset_name : item.asset_type.name + ' ' + item.name
            swal.fire({
                html: `<div>${this.translateService.instant('CloseOrder')} ${this.translateService.instant('For').toLowerCase()} <b>${tableName}</b>?</div>`,
                icon: 'success',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary text-right',
                    cancelButton: 'btn btn-secondary text-right mr-3',
                    actions: 'w-100 justify-content-end mt-4',
                },
                confirmButtonText: this.translateService.instant('Confirm'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-ir') {
            swal.fire({
                title: `${this.translateService.instant('DeleteProduct')}?`,
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-remove-product') {
            swal.fire({
                html: `
                <div>${this.translateService.instant('Delete')} <b>${item.product.name}</b> ${this.translateService.instant('For').toLowerCase()} <b>${item.customer_name}</b>?</div>`,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary text-right',
                    cancelButton: 'btn btn-secondary text-right mr-3',
                    actions: 'w-100 justify-content-end mt-4',
                },
                icon: 'error',
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'deleteOrder') {
            swal.fire({
                html: `
                <div class="d-flex justify-content-center align-items-center mb-4 mt-5 w-100">
                    <i class="material-icons text-danger fs-5">delete</i>
                </div>
                <div>${this.translateService.instant('DeleteOrder')} ${this.translateService.instant('For').toLowerCase()} <b>${item.asset_type.name}</b> <b>${item.name}</b>?</div>`,

                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary text-right',
                    cancelButton: 'btn btn-secondary text-right mr-3',
                    actions: 'w-100 justify-content-end mt-4',
                },
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'deleteCustomer') {
            swal.fire({
                html: `
                <div class="d-flex justify-content-center align-items-center mb-4 mt-5 w-100">
                    <i class="material-icons text-danger fs-5">delete</i>
                </div>
                <div>${this.translateService.instant('DeleteCustomer')}?</div>
                <p></p>`,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary text-right',
                    cancelButton: 'btn btn-secondary text-right mr-3',
                    actions: 'w-100 justify-content-end mt-4',
                },
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'stop-working-stage') {
            swal.fire({
                title: `${this.translateService.instant('StopStage')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmStopStage'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-send-invoice') {
            swal.fire({
                title: this.translateService.instant('SendInvoice?'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmSend'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'cloning-message') {
            swal.fire({
                title: `${this.translateService.instant('CloneItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                // text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmCloning'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-cancel') {
            swal.fire({
                title: `${this.translateService.instant('CancelItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmCancel'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'warning-message-remake') {
            swal.fire({
                title: `${this.translateService.instant('RemakeItem')} ${this.translateService.instant(item).toLowerCase()}?`,
                text: this.translateService.instant('Irreversible'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmRemake'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        } else if (type === 'confirmation-delete') {
            swal.fire({
                title: this.translateService.instant('Deleted'),
                text: `${this.translateService.instant(item)} ${this.translateService.instant('HasBeenDeleted')}`,
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                buttonsStyling: false
            });
        } else if (type === 'remove-picture') {
            swal.fire({
                title: `${this.translateService.instant('DeleteItem')} ${this.translateService.instant(item)}?`,
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                confirmButtonText: this.translateService.instant('ConfirmDelete'),
                cancelButtonText: this.translateService.instant('Cancel'),
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    callbackFunction();
                }
            });
        }
        else if (type === 'warning-message-and-cancel') {
            swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this imaginary file!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    swal.fire({
                        title: 'Deleted!',
                        text: 'Your imaginary file has been deleted.',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false
                    });
                } else {
                    swal.fire({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: false
                    });
                }
            })

        } else if (type == 'custom-html') {
            swal.fire({
                title: 'HTML example',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                html: 'You can use <b>bold text</b>, ' +
                    '<a href="http://github.com">links</a> ' +
                    'and other HTML tags'
            });

        } else if (type === 'auto-close') {
            swal.fire({
                title: 'Auto close alert!',
                text: 'I will close in 2 seconds.',
                timer: 2000,
                showConfirmButton: false
            });
        } else if (type === 'input-field') {
            swal.fire({
                title: 'Input something',
                html: '<div class="form-group">' +
                    '<input id="input-field" type="text" class="form-control" />' +
                    '</div>',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function () {
                // swal.fire({
                //     icon: 'success',
                //     html: 'You entered: <strong>' +
                //         $('#input-field').val() +
                //         '</strong>',
                //     customClass: {
                //         confirmButton: 'btn btn-success',
                //     },
                //     buttonsStyling: false
                // })
                this.inputValue$.next($('#input-field').val());
            });
        }
    }

    lockPaymentDocument(callbackFunction, item) {
        const message = {
            title: 'LockItem',
            text: 'Irreversible',
            icon: 'warning',
            confirmText: 'ConfirmLock',
            cancel: 'Cancel'
        }
        this.triggerSwal(callbackFunction, item, message)
    }

    generateInvoice(callbackFunction, item) {
        const message = {
            title: 'GenerateItem',
            icon: 'warning',
            confirmText: 'ConfirmGenerate',
            cancel: 'Cancel'
        }
        this.triggerSwal(callbackFunction, item, message)
    }

    triggerSwal(callbackFunction, item, message) {
        swal.fire({
            title: `${this.translateService.instant(message.title)} ${this.translateService.instant(item).toLowerCase()}?`,
            text: message.text ? this.translateService.instant(message.text) : null,
            icon: message.icon,
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            confirmButtonText: this.translateService.instant(message.confirmText),
            cancelButtonText: this.translateService.instant(message.cancel),
            buttonsStyling: false
        }).then((result) => {
            if (result.value) {
                callbackFunction();
            }
        });
    }

}


