import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PermissionsEnum } from '../../enums/Permissions.enum';
import { RolesModel } from 'src/app/modules/roles/models/roles.model';
import { AccountModel } from 'src/app/modules/accounts/models/account.model';
import { AppSettingsService } from '../../services/app-settings.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, TitleCasePipe } from '@angular/common';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../directives/form-validator.directive';
@UntilDestroy()
@Component({
    selector: 'app-user-account-fields',
    templateUrl: './user-account-fields.component.html',
    styleUrls: ['./user-account-fields.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, NgIf, MatSelectModule, NgFor, MatOptionModule, TitleCasePipe, TranslateModule]
})
export class UserAccountFieldsComponent implements OnInit {

    public form: FormGroup;
    public userStatusesList = this._appSettingsService.userStatuses;
    public rolesList: RolesModel[];
    public selectedUser: AccountModel;
    @Input() hideRoleFields: boolean;
    @Input() set data(val: AccountModel) {
        this.selectedUser = val;
        this.form && this.form.patchValue({ ...val, roles_ids: val?.roles_ids ? val.roles_ids[0] : null });
    };

    @Output() updateUserForm: EventEmitter<FormGroup> = new EventEmitter();

    public PermissionsEnum: PermissionsEnum;
    constructor(private _formBuilder: FormBuilder,
        private _appSettingsService: AppSettingsService,
        private _rolesService: RolesService) { }

    ngOnInit(): void {
        this._appSettingsService.getUserStatuses();
        this._rolesService.getRoles$().pipe(untilDestroyed(this)).subscribe(roles => this.rolesList = roles.data);

        this.form = this._formBuilder.group({
            id: this.selectedUser?.id || '',
            first_name: this.selectedUser?.first_name || '',
            last_name: this.selectedUser?.last_name || '',
            username: this.selectedUser?.username || '',
            password: this.selectedUser?.password || '',
            password_confirmation: this.selectedUser?.password_confirmation || '',
            roles: this.selectedUser?.roles ? this.selectedUser?.roles[0] : null,
            roles_ids: this.selectedUser?.roles_ids[0] || this.rolesList && this.rolesList[0]?.id,
            status: this.selectedUser?.status || (this.userStatusesList() && this.userStatusesList()[0]?.value),
            mobile: this.selectedUser?.mobile || '',
            email: this.selectedUser?.email || '',
        });
        this.updateUserForm.emit(this.form);
        this.form.valueChanges.subscribe(() => this.updateUserForm.emit(this.form));
    }
}
