<section class="table-list-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <span>{{ 'Accounts' | translate | uppercase }}</span>
                <button mat-raised-button class="btn btn-white text-dark" (click)="addEntry()"
                    [appRestrictPermissions]="PermissionsEnum.USERS_ADD_AND_EDIT"><i class="material-icons mr-1">add</i>
                    {{'Add' | translate }}</button>
            </div>
            <div class="col-12 mt-4">
                <app-table-filters *ngIf="table" [filters]="filters" [tableId]="table.tableId"
                    [queryParams]="queryParams" (applyFilters)="applyFilters($event)"></app-table-filters>
            </div>
            <div class="col-12">
                <app-regular-table #table [headerRow]="tableHeaders"
                    [service]="accountsService" [dataList]="accountsList()"
                    [rowPossibleActions]="rowPossibleActions" [pagination]="accountsData()"
                    [length]="accountsData()?.total"
                    [tableId]="'accounts-records-tbl'" (onPageChanged)="updatePage($event)"
                    [pageIndex]="accountsData()?.current_page" (actionType)="tableActionClicked($event)">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>