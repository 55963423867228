import { Component, ViewChildren } from '@angular/core';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { ProductsService } from './services/product.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableDataHeaders } from 'src/app/shared/models/table-data-headers.model';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { ProductModel } from './models/product.model';
import { PageEvent } from '@angular/material/paginator';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { booleanOptions$ } from 'src/app/shared/constants';
import { ProductsModalComponent } from './components/products-modal/products-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { CategoriesService } from '../categories/services/category.service';
import { TypesService } from '../types/services/type.service';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { TableFiltersComponent as TableFiltersComponent_1 } from '../../shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { NgIf, UpperCasePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
@UntilDestroy()
@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css'],
    standalone: true,
    imports: [MatButtonModule, NgIf, TableFiltersComponent_1, RegularTableComponent, UpperCasePipe, TranslateModule]
})
export class ProductsComponent {
    public PermissionsEnum = PermissionsEnum;
    public itemsList: ProductModel[];
    public itemsData: PaginationModel;
    public parsedFilters = '';

    public queryParams = {
        page: 1,
        records_number: 10
    };


    public filters: TableFilter[] = [
        {
            resourceName: 'name',
            label: 'Name',
            type: TableFilterTypesEnum.TEXT,
            controls: ['name'],
            style: 'col-12 col-sm-6 col-md-4',
            data_cy: 'name',
            placeholders: [''],
        },
        {
            resourceName: 'type.id',
            label: 'Type',
            type: TableFilterTypesEnum.SELECT,
            controls: ['type_id'],
            style: 'col-12 col-sm-6 col-md-4',
            firstOptionMessage: 'All',
            optionsList$: this._typesService.getTypes$()
        },
        {
            resourceName: 'category.id',
            label: 'Category',
            type: TableFilterTypesEnum.SELECT,
            controls: ['category_id'],
            style: 'col-12 col-sm-6 col-md-4',
            firstOptionMessage: 'All',
            optionsList$: this._categoriesService.getCategories$()
        },
        {
            resourceName: 'is_active',
            label: 'IsActive',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['is_active'],
            style: 'col-6 col-sm-3 col-md-3',
            firstOptionMessage: 'All',
            dropdownId: 'is_active',
            optionsList$: booleanOptions$(),
            data_cy: 'is-active'
        },
        {
            resourceName: 'is_available',
            label: 'IsAvailable',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['is_available'],
            style: 'col-6 col-sm-3 col-md-3',
            firstOptionMessage: 'All',
            dropdownId: 'is_available',
            optionsList$: booleanOptions$(),
            data_cy: 'is-available'
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Name',
            fieldNames: ['name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        },
        {
            name: 'Alergens',
            fieldNames: ['product_allergens'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            cellType: TableCellType.COUNT_WITH_POPOVER,
        },
        {
            name: 'Type',
            fieldNames: ['type.name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        },
        {
            name: 'Category',
            fieldNames: ['category.name'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
        },
        {
            name: 'Active',
            fieldNames: ['is_active'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.BOOLEAN_LABEL,
        },
        {
            name: 'Available',
            fieldNames: ['is_available'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.BOOLEAN_LABEL,
        },
        {
            name: 'Price',
            fieldNames: ['price'],
            cssClasses: 'all text-right',
            searchable: true,
            sorting: true,
            cellType: TableCellType.PRICE,
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'Edit',
            type: TableActionTypeEnum.Edit,
            permission: PermissionsEnum.ALLERGENS_ADD_AND_EDIT
        },
        {
            name: 'Delete',
            type: TableActionTypeEnum.Delete,
            permission: PermissionsEnum.ALLERGENS_DELETE
        }
    ];

    @ViewChildren('table') table: TableFiltersComponent;


    constructor(public productsService: ProductsService,
        private _dialogService: DialogService,
        private _sweetAlertService: SweetAlertService,
        private _categoriesService: CategoriesService,
        private _typesService: TypesService
    ) { }

    ngOnInit(): void {
        this._categoriesService.getCategories();
        this._typesService.getTypes();

        this.productsService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsList = data)
        this.productsService.itemsData$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsData = data)
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Edit) {
            this.openEditModal(e.entry);
        }
        if (e.type === TableActionTypeEnum.Delete) {
            this._sweetAlertService.showSwal('warning-message', () => this.productsService.deleteProduct(e.entry), "Product");
        }
    }

    addEntry() {
        this.openEditModal(null);
    }

    changeLang() {
        this.productsService.getProducts(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    private openEditModal(entry: ProductModel | null) {
        this._dialogService.openModal(entry, ProductsModalComponent, DimensionsEnum.AUTO)
            .afterClosed().subscribe(() => this.productsService.getProducts(this.parsedFilters, parseQueryParams(this.queryParams)));
    }

    updatePage(event: PageEvent) {
        this.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this.productsService.getProducts(this.parsedFilters, parseQueryParams(this.queryParams));
    }

    applyFilters(filters: ApplyFiltersModel) {
        this.parsedFilters = filters.parsedFilters;
        this.queryParams = filters.queryParams;
        this.productsService.getProducts(this.parsedFilters, parseQueryParams(filters.queryParams));
    }
}
