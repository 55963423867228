import { Routes } from '@angular/router';
import { QrScanComponent } from './qr-scan.component';

export const QRScanRoutes: Routes = [

    {
        path: '',
        children: [
            {
                path: 'menu',
                component: QrScanComponent,
            },
            {
                path: 'menu/assets/:id',
                component: QrScanComponent,
            },
        ]
    },
];
